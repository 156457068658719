import React, {Component} from 'react';
import Typography from "@mui/material/Typography";
import {
    req_get_membership_list,
    req_reset_password,
    req_user_account_get,
    req_membership_update_post
} from "../../helpers/requests";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import {
    GENERIC_LOAD_ERROR
} from "../../helpers/strConstants";
import eventBus from "../../helpers/eventBus";
import AccountShareDialog from "../dialogs/accountShareDialog";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {Chart} from "react-google-charts";
import AccountChangeDialog from "../dialogs/accountChangeDialog";
import {Chip, IconButton, Select} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import AccountShareDeleteDialog from "../dialogs/accountShareDeleteDialog";
import EditIcon from '@mui/icons-material/Edit';
import AccountChangeName from "../dialogs/accountChangeName";

export default class PageAccount extends Component {

    state = {
        user: {},
        alert: { open: false, message: "", type: "" },
        accountShareOpen: false,
        accountChangeOpen: false,
        accountShareEditOpen: false,
        accountShareDeleteOpen: false,
        accountNameChangeOpen: false,
        approvedUsers: [],
        columns: [],
        totalTokens: 0,
        totalDatasets: 0,
        totalProjects: 0,
        membership: [],
        deleteUser: {}

    }

    updateUserRole = (e, userId) => {
        let self = this;
        let body = {
            userId: userId,
            role: e.target.value
        }

        req_membership_update_post(body).then(function (result) {
            if (result.status === 200) {
                self.loadUser()
            } else {

            }
        })

    }

    loadColumns = () => {

        const columns = [
            { field: 'displayName', headerName: 'Display Name', minWidth: 160, editable: false },
            { field: 'status', headerName: 'Status', minWidth: 80, editable: false },
            {
                field: 'role',
                type: 'actions',
                headerName: 'Role',
                width: 156,
                cellClassName: 'actions',
                getActions: ( params ) => {
                    return [
                        <Select
                            labelId={"field_access_type_label_"}
                            id={"field_access_type_label_"}
                            label="Access Type"
                            style={{width: "140px"}}
                            disabled={!params.row.personId}
                            value={params.row.role}
                            onChange={(e) => {this.updateUserRole(e, params.row.personId)} }
                        >
                            <MenuItem value="standard">Standard User</MenuItem>
                            <MenuItem value="admin">Admin User</MenuItem>
                        </Select>
                    ];
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 80,
                cellClassName: 'actions',
                getActions: ( params ) => {
                    return [

                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => { this.handleAccountShareDeleteClick(params.row) }}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];
        this.setState({ columns: columns });
    }

    handleAccountShareClose = () => {
        this.setState({accountShareOpen: false})
        this.loadUser()
    }
    handleAccountChangeClose = () => {
        this.setState({accountChangeOpen: false})
        this.loadUser()
    }
    handleAccountEditClose = () => { this.setState({accountShareEditOpen: false}) }
    handleAccountDeleteClose = () => {
        this.setState({accountShareDeleteOpen: false})
        this.loadUser()
    }
    handleAccountShareDeleteClick = (user) => { this.setState({accountShareDeleteOpen: true, deleteUser: user}) }
    handleAccountNameChangeClose = () => {
        this.setState({accountNameChangeOpen: false})
        this.loadUser()
    }
    loadUser = () => {
        let self = this;
        req_user_account_get("person", localStorage.getItem("uid")).then(function (result) {
            if (result.status === 200) {
                const d = result.data.data;
                self.setState({user: {...d}, totalTokens: d.planLimits.tokens, totalDatasets: d.planLimits.datasets, totalProjects: d.planLimits.projects})
                localStorage.setItem("accounts", JSON.stringify(d.accounts));
                eventBus.dispatch("updateUserMenu", {  });
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })

        req_get_membership_list().then(function (result) {
            if (result.status === 200) {
                self.setState({membership: [...result.data.data]})
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })

        this.loadColumns();

    }

    resetPassword = () => {
        let self = this;
        req_reset_password(this.state.user.email).then(function (result) {
            if (result.status === 200) {
                //self.setState({user: {...result.data.data}})
                self.setState({loading: false, alert:{...{ "open": true, "message": "Password reset email has been sent.", "type": "success", "timeout": 5000 }}});
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem submitting the password change request.", "type": "error", "timeout": 5000 }}});
            }
        })
    }

    componentDidMount() {
        this.loadUser();

    }

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }

    render() {
        return (
        <div className="account" style={{height: '100%'}}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.loading || false}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
            <div style={{marginTop: "10px", display: "flex", flexDirection: "row"}}>
                <div style={{width: "50%", paddingRight: "5px"}}>
                    <div style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", textAlign: "left"}}>
                        <Typography variant="h5" align={"left"} gutterBottom>
                            My Information
                        </Typography>
                        <div><b>ID</b>: {this.state.user.id || ""}</div>
                        <div><b>Email Address</b>: {this.state.user.email || ""}</div>
                        <div><b>Display Name</b>: {this.state.user.displayName || ""}</div>
                        <div><b>Created</b>: {this.state.user.isoDate || ""}</div>
                        {this.state.user.accounts ?
                            <div style={{marginBottom: "5px"}}><b>Member Of:</b> {
                                this.state.user.accounts.map((account) =>
                                    <Chip size="small" key={account.id} label={account.accountName + (this.state.user.ownedAccount === account.id ? " (My Account)" : "" )} style={{marginRight: "5px"}} />
                                )
                            }</div>
                        : null}
                        <div><b>Password</b>: <Button variant="contained" size="small" onClick={this.resetPassword}
                                                      style={{paddingTop: "1px", paddingBottom: "1px"}}>Reset Password (email will be sent)</Button></div>
                    </div>
                </div>
                <div style={{width: "50%", paddingRight: "5px"}}>
                    <div style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", textAlign: "left"}}>
                        <Typography variant="h5" align={"left"} gutterBottom>
                            Account Information <Button
                            variant="contained" size="small"
                            style={{paddingTop: "1px", paddingBottom: "1px"}}
                            onClick={() => {this.setState({accountChangeOpen: true})}}
                        >Upgrade Account</Button>
                        </Typography>
                        <div><b>Account Plan</b>: {this.state.user.plan || ""}</div>
                        <div><b>Account Name</b>: {this.state.user.accountName || ""} <IconButton
                            onClick={() => { this.setState({accountNameChangeOpen: true}) }}
                        ><EditIcon style={{fontSize: "18px"}} /></IconButton></div>
                        <div><b>Display Name</b>: {this.state.user.displayName || ""}</div>
                        <div><b>Handle</b>: {this.state.user.handle || ""}</div>

                    </div>
                    <div style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", textAlign: "left", marginTop: "10px"}}>

                        <div style={{marginBottom: "5px"}}>
                            <b align={"left"}>
                                Projects ({this.state.user.projects || 0}/ {this.state.totalProjects || 0})
                            </b>
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="80px"
                            data={[ ["Object", "Used", "Remaining"], ["Projects", this.state.user.projects || 0, (this.state.totalProjects - this.state.user.projects) || 0] ]}
                            options={{
                                colors: ['#dd0000','#dddddd'],
                                legend: { position: "none" }, isStacked: true,
                                hAxis: {
                                    viewWindow: {
                                        min: 0,
                                        max: this.state.totalProjects || 0
                                    }
                                },
                                chart: {
                                    hAxis: { textPosition: "none" },
                                    title: "Projects Created",
                                    subtitle: "Currently created projects of account",
                                }
                            }}
                        />
                        </div>
                        { this.state.user ?
                        <div style={{marginBottom: "5px"}}>
                            <b align={"left"}>
                                Datasets ({this.state.user.datasets || 0}/ {this.state.totalDatasets || 0})
                            </b>
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="80px"
                            data={[ ["Object", "Used", "Remaining"], ["Datasets", this.state.user.datasets || 0, (this.state.totalDatasets - this.state.user.datasets) || 0] ]}
                            options={{
                                colors: ['#dd0000','#dddddd'],
                                legend: { position: "none" }, isStacked: true,
                                hAxis: {
                                    viewWindow: {
                                        min: 0,
                                        max: this.state.totalDatasets || 0
                                    }
                                },
                                chart: {
                                    title: "Datasets Created",
                                    subtitle: "Currently created datasets of account",
                                }
                            }}
                        />
                        </div>: null
                        }
                        <div style={{marginBottom: "5px"}}>
                            <b align={"left"}>
                                Tokens ({this.state.user.tokens || 0}/{this.state.totalTokens || 0})
                            </b>
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="80px"
                            data={[ ["Object", "Used", "Remaining"], ["Tokens", this.state.user.tokens || 0, (this.state.totalTokens - this.state.user.tokens) || 0] ]}
                            options={{
                                colors: ['#dd0000','#dddddd'],
                                legend: { position: "none" }, isStacked: true,
                                hAxis: {
                                    viewWindow: {
                                        min: 0,
                                        max: this.state.totalTokens || 0
                                    }
                                },
                                chart: {
                                    title: "Tokens Created",
                                    subtitle: "Currently created tokens of account",
                                }
                            }}
                        />
                        </div>

                        <div style={{marginBottom: "5px", display: "none"}}>
                            <b align={"left"}>
                                Disk Usage
                            </b>
                        </div>


                        <div style={{marginBottom: "5px", display: "none"}}>
                            <b align={"left"}>
                                Api Requests This Month
                            </b>
                        </div>



                    </div>
                    <div style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", textAlign: "left", marginTop: "10px"}}>
                        <Typography variant="h5" align={"left"} gutterBottom>
                            Authorized Account Access <Button
                                variant="contained" size="small"
                                style={{paddingTop: "1px", paddingBottom: "1px"}}
                                onClick={() => {this.setState({accountShareOpen: true})}}
                            >Add User</Button>
                        </Typography>

                        <div style={{textAlign: "center"}}>
                            <DataGrid
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                    },
                                }}
                                style={{maxWidth: "600px"}}

                                disableSelectionOnClick = {true}
                                autoHeight
                                rows={this.state.membership}
                                columns={this.state.columns}
                                pageSize={5}
                            />
                        </div>


                    </div>
                </div>
            </div>
            <AccountShareDialog open={this.state.accountShareOpen} user={this.state.user} close={this.handleAccountShareClose} />
            <AccountChangeDialog open={this.state.accountChangeOpen} user={this.state.user} close={this.handleAccountChangeClose} />
            <AccountShareDeleteDialog open={this.state.accountShareDeleteOpen} user={this.state.deleteUser} close={this.handleAccountDeleteClose} />
            <AccountChangeName open={this.state.accountNameChangeOpen} name={this.state.user.accountName} close={this.handleAccountNameChangeClose} />


        </div>
        )
    }
}