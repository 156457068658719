import React, {Component} from "react";
import {ListItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {req_project_hierarchy_get} from "../../helpers/requests";
import TableViewIcon from "@mui/icons-material/TableView";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from '@mui/icons-material/Info';
import ApiIcon from '@mui/icons-material/Api';
import ReportIcon from "@mui/icons-material/Assessment";
import List from "@mui/material/List";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./portalChildNav.css";
import IconButton from "@mui/material/IconButton";
import PeopleIcon from '@mui/icons-material/People';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


export default class PortalChildNav extends Component {

    state = {
        project: {},
        dashboard: {},
        showFilters: false,
        visibleFilters: [],
        visibleApis: [],
        expandedNodes: {
            datasets: [],
            filters: [],
            reports: [],
        },
        contextMenu: null,
        datasetMenu: null,
        filterMenu: null,
        apiMenu: null,
        reportMenu: null,
        datasetContextData: {},
        filterContextData: {},
        apiContextData: {},
        reportContextData: {}
    }

    openDatasetMenu = (e, id, name, role, item) => {
        e.preventDefault();
        this.setState({
            datasetMenu: this.state.datasetMenu === null
                ? {
                    mouseX: e.clientX + 2,
                    mouseY: e.clientY - 6,
                }
                : null
        })
        this.setState({datasetContextData: {id: id, name: name, role: role, item: item}});
    }

    openFilterMenu = (e, id, name) => {
        e.preventDefault();
        this.setState({
            filterMenu: this.state.filterMenu === null
                ? {
                    mouseX: e.clientX + 2,
                    mouseY: e.clientY - 6,
                }
                : null
        })
        this.setState({filterContextData: {id: id, name: name}});
    }

    openApiMenu = (e, id, name) => {
        e.preventDefault();
        this.setState({
            apiMenu: this.state.apiMenu === null
                ? {
                    mouseX: e.clientX + 2,
                    mouseY: e.clientY - 6,
                }
                : null
        })
        this.setState({apiContextData: {id: id, name: name}});
    }

    openReportMenu = (e, id, name) => {
        e.preventDefault();
        this.setState({
            reportMenu: this.state.reportMenu === null
                ? {
                    mouseX: e.clientX + 2,
                    mouseY: e.clientY - 6,
                }
                : null
        })
        this.setState({reportContextData: {id: id, name: name}});
    }

    handleDatasetMenuClose = () => {
        this.setState({datasetMenu: null});
        this.setState({datasetContextData: {...{}}});
    }

    handleFilterMenuClose = () => {
        this.setState({filterMenu: null});
        this.setState({filterContextData: {...{}}});
    }

    handleApiMenuClose = () => {
        this.setState({apiMenu: null});
        this.setState({apiContextData: {...{}}});
    }

    handleReportMenuClose = () => {
        this.setState({reportMenu: null});
        this.setState({reportContextData: {...{}}});
    }

    toggleShowFilters = (datasetId) => {
        let visFilters = this.state.visibleFilters;
        if (visFilters.includes(datasetId)) {
            let ind = visFilters.indexOf(datasetId);
            visFilters.splice(ind, 1);
        } else {
            visFilters.push(datasetId);
        }
        this.setState({visibleFilters: [...visFilters]});
    }

    toggleShowApis = (filterId) => {
        let visApis = this.state.visibleApis;
        if (visApis.includes(filterId)) {
            let ind = visApis.indexOf(filterId);
            visApis.splice(ind, 1);
        } else {
            visApis.push(filterId);
        }
        this.setState({visibleApis: [...visApis]});
    }

    loadProject() {

        let self = this;
        self.setState({loading: true});
        req_project_hierarchy_get("project", this.props.parent.id).then(function (result) {
            if (result.status === 200) {
                let project = result;
                self.setState({project: project.data, dataLoaded: true, loading: false});
            } else {
                self.setState({loading: false});
            }
        })
    }

    componentDidMount() {
        if (this.props.parent.type === "project") {
            //this.loadProject();
        }
    }


    render() {

        return (
            <div>
                {this.props.parent.type === "project" && this.props.parent.datasets && this.props.parent.datasets.length > 0 ?
                    <List className="childList">
                        {
                            this.props.parent.datasets.map((dataset) =>
                                <div key={dataset.id}>
                                    <ListItem
                                        style={{ cursor: 'context-menu', display: "flex", flexDirection: "row" }}
                                        sx={{display: "block", fontSize: ".9rem", paddingLeft: "5px", paddingRight: "10px", backgroundColor: "#445566", color: "#eee", borderTop: "1px solid #567"}}
                                        key={dataset.id ? dataset.id : "empty_dataset"}
                                        className="childListItem"
                                        onContextMenu={(e) => {

                                            this.openDatasetMenu(e, dataset.id, dataset.name, dataset.role || "", dataset)
                                        }}
                                    >
                                        <div style={{width: "100%", display: "inline-flex", cursor: "pointer"}}>
                                            <ListItemButton
                                                className="childListItemButton"
                                                sx={{
                                                    justifyContent: this.props.open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}

                                                onClick={(e) => {
                                                    this.toggleShowFilters(dataset.id)
                                                }}
                                            >
                                                {this.state.visibleFilters.includes(dataset.id) ?
                                                    <ExpandMore sx={{fontSize: 18}}/> :
                                                    <ChevronRightIcon sx={{fontSize: 18}}/>}
                                            </ListItemButton>
                                            <div style={{display: "flex"}} onClick={
                                                () => {
                                                    this.props.openItem("dataset", {
                                                        "name": dataset.name,
                                                        "id": dataset.id
                                                    })
                                                }
                                            }>
                                                <ListItemIcon
                                                    className="childListItemIcon"
                                                    sx={{
                                                        mr: this.props.open ? 3 : 'auto',
                                                    }}
                                                ><TableViewIcon style={{fontSize: "16px", color: "#97b3e7"}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={<React.Fragment>{dataset.name} <PeopleIcon sx={{fontSize: 12, display: dataset["multiuser"] ? "inline-block" : "none" }} /></React.Fragment>} primaryTypographyProps={{
                                                    fontSize: '.7rem',
                                                    display: "inline-block",
                                                    paddingLeft: "5px",
                                                    color: "#eee"
                                                }}/>
                                            </div>

                                        </div>
                                        <div onClick={(e) => {
                                            this.openDatasetMenu(e, dataset.id, dataset.name, dataset.role || "", dataset)
                                        }}><IconButton>
                                            <MoreVertIcon sx={{ fontSize: 20 }} />
                                        </IconButton></div>
                                    </ListItem>
                                    <div>
                                        {this.state.visibleFilters.includes(dataset.id) && dataset.filters.length > 0 ?

                                            <List className="childList">
                                                {dataset.filters.map((filter) =>
                                                    <div key={filter.id}>
                                                        <ListItem
                                                            key={filter.id ? filter.id : "empty_filter"}
                                                            onContextMenu={(e) => {
                                                                this.openFilterMenu(e, filter.id, filter.title)
                                                            }}
                                                            className="childListItem"
                                                        >
                                                            <div style={{width: "100%", display: "inline-flex", cursor: "pointer"}}>
                                                                <ListItemButton
                                                                    className="childListItemButton"
                                                                    sx={{
                                                                        justifyContent: this.props.open ? 'initial' : 'center',
                                                                        px: 2.5,
                                                                    }}

                                                                    onClick={(e) => {
                                                                        this.toggleShowApis(filter.id)
                                                                    }}
                                                                >
                                                                    {this.state.visibleApis.includes(filter.id) ?
                                                                        <ExpandMore sx={{fontSize: 18}}/> :
                                                                        <ChevronRightIcon sx={{fontSize: 18}}/>}
                                                                </ListItemButton>
                                                                <ListItemIcon
                                                                    onClick={
                                                                        () => {
                                                                            this.props.openItem("filter", {
                                                                                "name": filter.title,
                                                                                "id": filter.id
                                                                            })
                                                                        }
                                                                    }
                                                                    className="childListItemIcon"
                                                                    sx={{
                                                                        mr: this.props.open ? 3 : 'auto',
                                                                    }}
                                                                ><FilterAltIcon
                                                                    style={{fontSize: "16px", color: "#66a173"}}/>
                                                                </ListItemIcon>
                                                                < ListItemText primary={filter.title}
                                                                               onClick={
                                                                                   () => {
                                                                                       this.props.openItem("filter", {
                                                                                           "name": filter.title,
                                                                                           "id": filter.id
                                                                                       })
                                                                                   }
                                                                               }
                                                                               primaryTypographyProps={{
                                                                                   paddingLeft: "10px",
                                                                                   fontSize: '.7rem',
                                                                                   whiteSpace: "normal",
                                                                                   cursor: "pointer"
                                                                               }}
                                                                />
                                                            </div>
                                                            <div onClick={(e) => {
                                                                this.openFilterMenu(e, filter.id, filter.title)
                                                            }}><IconButton>
                                                                <MoreVertIcon sx={{ fontSize: 20 }} />
                                                            </IconButton></div>

                                                        </ListItem>
                                                        <div style={{paddingRight: "5px"}}>
                                                            {this.state.visibleApis.includes(filter.id) && filter.apis ?
                                                                <div style={{width: "100%"}}>
                                                                    <List className="childList">
                                                                        {filter.apis.map((api) =>
                                                                            <ListItem
                                                                                key={api.id ? api.id : "empty api"}
                                                                                className="childListItem"
                                                                                style={{flexDirection: "row"}}
                                                                                onContextMenu={(e) => {
                                                                                    this.openApiMenu(e, api.id, api.name)
                                                                                }}

                                                                            >
                                                                                <ListItemIcon
                                                                                    className="childListItemIcon"
                                                                                    sx={{
                                                                                        mr: this.props.open ? 3 : 'auto',
                                                                                        marginLeft: "15px"
                                                                                    }}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.openItem("apiwebhook", {
                                                                                                "name": api.name,
                                                                                                "id": api.id
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                ><ApiIcon style={{
                                                                                    fontSize: "16px",
                                                                                    color: "#a68fbb"
                                                                                }}/>
                                                                                </ListItemIcon>
                                                                                < ListItemText primary={api.name}
                                                                                               primaryTypographyProps={{
                                                                                                   paddingLeft: "10px",
                                                                                                   fontSize: '.7rem',
                                                                                                   whiteSpace: "normal",
                                                                                                   cursor: "pointer",
                                                                                                   marginTop: "4px",
                                                                                                   marginBottom: "4px"
                                                                                               }}
                                                                                               style={{verticalAlign: "super"}}
                                                                                               onClick={
                                                                                                   () => {
                                                                                                       this.props.openItem("apiwebhook", {
                                                                                                           "name": api.name,
                                                                                                           "id": api.id
                                                                                                       })
                                                                                                   }
                                                                                               }
                                                                                />
                                                                                <div onClick={(e) => {
                                                                                    this.openApiMenu(e, api.id, api.name)
                                                                                }}><IconButton>
                                                                                    <MoreVertIcon sx={{ fontSize: 20 }} />
                                                                                </IconButton></div>
                                                                            </ListItem>
                                                                        )}
                                                                    </List>
                                                                </div> : null
                                                            }

                                                            {this.state.visibleApis.includes(filter.id) && filter.hasOwnProperty("apis") && filter.apis.length === 0 ?
                                                                <div style={{width: "100%", paddingLeft: "15px"}}>
                                                                    <List className="childList">

                                                                        {dataset.role !== "viewer" ?
                                                                        <ListItem
                                                                            key={"no_filter_for_dataset"}
                                                                            className="childListItem"
                                                                            style={{
                                                                                display: "flex",
                                                                                textDecoration: "underline",
                                                                                cursor: "pointer",
                                                                                flexDirection: "row"
                                                                            }}
                                                                        >
                                                                            <ListItemIcon
                                                                                className="childListItemIcon"
                                                                                sx={{
                                                                                    mr: this.props.open ? 3 : 'auto',
                                                                                }}
                                                                                style={{flexDirection: "row"}}
                                                                            ><ApiIcon style={{fontSize: "16px"}}/>
                                                                            </ListItemIcon>

                                                                            < ListItemText
                                                                                onClick={() => {
                                                                                    this.props.addItem("apiwebhook", {filter: filter.id})
                                                                                }}
                                                                                primary={"Create an API?"}
                                                                                primaryTypographyProps={{
                                                                                    paddingLeft: "10px",
                                                                                    fontSize: '.7rem',
                                                                                    whiteSpace: "normal",
                                                                                    cursor: "pointer",
                                                                                    color: "#eee"
                                                                                }}
                                                                            />

                                                                        </ListItem>:
                                                                            <ListItem
                                                                                className="childListItem"
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    flexDirection: "row",
                                                                                    paddingLeft: "15px !important",
                                                                                    display: "flex"
                                                                                }}
                                                                            >
                                                                                <ListItemIcon
                                                                                    className="childListItemIcon"
                                                                                ><InfoIcon style={{fontSize: "16px"}}/>
                                                                                </ListItemIcon>
                                                                                <ListItemText primary={"No visible APIs"}
                                                                                              primaryTypographyProps={{
                                                                                                  paddingLeft: "10px",
                                                                                                  color: "#eee",
                                                                                                  fontSize: '.7rem',
                                                                                                  whiteSpace: "normal",
                                                                                                  cursor: "pointer"
                                                                                              }}/>
                                                                            </ListItem>
                                                                        }
                                                                    </List></div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </List> : null

                                        }

                                        {this.state.visibleFilters.includes(dataset.id) && dataset.filters.length === 0 ?
                                            <List className="childList">
                                                {dataset.role !== "viewer" ?
                                                    <ListItem
                                                        key={"no_filter_for_dataset"}
                                                        className="childListItem"
                                                        style={{
                                                            display: "flex",
                                                            textDecoration: "underline",
                                                            cursor: "pointer",
                                                            flexDirection: "row"
                                                        }}
                                                    >
                                                        <ListItemIcon
                                                            className="childListItemIcon"
                                                            sx={{
                                                                mr: this.props.open ? 3 : 'auto',
                                                            }}
                                                            style={{flexDirection: "row"}}
                                                        ><FilterAltIcon style={{fontSize: "16px"}}/>
                                                        </ListItemIcon>

                                                        < ListItemText
                                                            onClick={() => {
                                                                this.props.addItem("filter", {dataset: dataset.id})
                                                            }}
                                                            primary={"Create a filter?"}
                                                            primaryTypographyProps={{
                                                                paddingLeft: "10px",
                                                                fontSize: '.7rem',
                                                                whiteSpace: "normal",
                                                                cursor: "pointer",
                                                                color: "#eee"
                                                            }}
                                                        />

                                                    </ListItem> :
                                                    <ListItem
                                                        className="childListItem"
                                                        style={{
                                                            cursor: "pointer",
                                                            flexDirection: "row",
                                                            paddingLeft: "15px !important",
                                                            display: "flex"
                                                        }}
                                                    >
                                                        <ListItemIcon
                                                            className="childListItemIcon"
                                                        ><InfoIcon style={{fontSize: "16px"}}/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={"No visible filters"}
                                                                      primaryTypographyProps={{
                                                                          paddingLeft: "10px",
                                                                          color: "#eee",
                                                                          fontSize: '.7rem',
                                                                          whiteSpace: "normal",
                                                                          cursor: "pointer"
                                                                      }}/>
                                                    </ListItem>
                                                }
                                            </List> : null
                                        }

                                    </div>
                                </div>
                            )
                        }
                    </List> : null

                }


                {this.props.parent.type === "project" && this.props.parent.datasets && this.props.parent.datasets.length === 0 ?
                    <List className="childList">
                        {this.props.parent.role !== "viewer" ?
                            <ListItem
                                key={"no_dataset_for_project"}
                                className="childListItem"
                                style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    flexDirection: "row",
                                    paddingLeft: "15px !important",
                                    display: "flex"
                                }}
                                onClick={() => {
                                    this.props.addItem("dataset", {project: this.props.parent.id});
                                }}>
                                <ListItemIcon
                                    className="childListItemIcon"
                                    sx={{
                                        mr: this.props.open ? 3 : 'auto',
                                    }}
                                ><InfoIcon style={{fontSize: "16px"}}/>
                                </ListItemIcon>
                                <ListItemText primary={"Create new dataset"}
                                              primaryTypographyProps={{
                                                  paddingLeft: "10px",
                                                  color: "#eee",
                                                  fontSize: '.7rem',
                                                  whiteSpace: "normal",
                                                  cursor: "pointer"
                                              }}/>
                            </ListItem> :
                            <ListItem
                                className="childListItem"
                                style={{
                                    cursor: "pointer",
                                    flexDirection: "row",
                                    paddingLeft: "15px !important",
                                    display: "flex"
                                }}
                                >
                                <ListItemIcon
                                    className="childListItemIcon"
                                ><InfoIcon style={{fontSize: "16px"}}/>
                                </ListItemIcon>
                                <ListItemText primary={"No visible datasets"}
                                              primaryTypographyProps={{
                                                  paddingLeft: "10px",
                                                  color: "#eee",
                                                  fontSize: '.7rem',
                                                  whiteSpace: "normal",
                                                  cursor: "pointer"
                                              }}/>
                            </ListItem>
                        }
                    </List> : null
                }


                {this.props.parent.type === "dashboard" && this.state.dashboard.data && this.state.dashboard.data.length > 0 ?
                    <List className="childList">
                        {
                            this.state.dashboard.data.map((report) =>
                                <div key={report.id}>
                                    <ListItem
                                        key={report.id ? report.id : "empty_report"}
                                        className="childListItem"
                                        onContextMenu={(e) => {
                                            this.openReportMenu(e, report.id, report.name)
                                        }}
                                    >
                                        <div style={{width: "100%", display: "flex", cursor: "pointer"}}>

                                            <div style={{display: "flex", paddingLeft: "20px"}} onClick={
                                                () => {
                                                    this.props.openItem("report", {
                                                        "name": report.name,
                                                        "id": report.id
                                                    })
                                                }
                                            }>
                                                <ListItemIcon
                                                    className="childListItemIcon"
                                                    sx={{
                                                        mr: this.props.open ? 3 : 'auto',
                                                    }}
                                                ><ReportIcon style={{fontSize: "16px", color: "#e9cece"}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={report.name} primaryTypographyProps={{
                                                    fontSize: '.7rem',
                                                    display: "inline-block",
                                                    paddingLeft: "5px",
                                                    color: "#eee"
                                                }}/>
                                            </div>


                                        </div>

                                    </ListItem>
                                    <div>


                                    </div>
                                </div>
                            )
                        }
                    </List> : null
                }

                {this.props.parent.type === "dashboard" && this.state.dashboard.data && this.state.dashboard.data.length === 0 ?
                    <List className="childList">
                        {this.state.datasetContextData.role !== "viewer" ?
                        <ListItem
                            key={"no_report_for_project"}
                            className="childListItem"
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                flexDirection: "row",
                                paddingLeft: "15px !important",
                                display: "flex"
                            }}
                            onClick={() => {
                                this.props.addItem("report", {dashboard: this.props.parent.id});
                            }}>
                            <ListItemIcon
                                className="childListItemIcon"
                                sx={{
                                    mr: this.props.open ? 3 : 'auto',
                                }}
                            ><InfoIcon style={{fontSize: "16px"}}/>
                            </ListItemIcon>
                            <ListItemText primary={"Create new report"}
                                          primaryTypographyProps={{
                                              paddingLeft: "10px",
                                              color: "#eee",
                                              fontSize: '.7rem',
                                              whiteSpace: "normal",
                                              cursor: "pointer"
                                          }}/>
                        </ListItem>: null }
                    </List>: null


                }



                <Menu
                    open={this.state.datasetMenu !== null}
                    onClose={this.handleDatasetMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        this.state.datasetMenu !== null
                            ? {top: this.state.datasetMenu.mouseY, left: this.state.datasetMenu.mouseX}
                            : undefined
                    }
                >
                    {this.state.datasetContextData.role !== "viewer" ?
                        <MenuItem key={"new_filter"} onClick={(e) => {
                            this.props.addItem("filter", {dataset: this.state.datasetContextData.id});
                            this.handleDatasetMenuClose();
                        }
                        }>New Filter</MenuItem> : null
                    }
                    <MenuItem key={"expand_filters"} onClick={(e) => {
                        this.toggleShowFilters(this.state.datasetContextData.id);
                        this.handleDatasetMenuClose();
                    }}>Expand/Collapse</MenuItem>
                    <MenuItem key={"edit_dataset"} onClick={(e) => {
                        this.props.openItem("dataset", {
                            "name": this.state.datasetContextData.name,
                            "id": this.state.datasetContextData.id
                        });
                        this.handleDatasetMenuClose();
                    }}>Open Dataset</MenuItem>

                    {this.state.datasetContextData.role !== "viewer" ?
                        <MenuItem key={"delete_dataset"} onClick={(e) => {
                            this.props.deleteItem("dataset", {
                                "name": this.state.datasetContextData.name,
                                "id": this.state.datasetContextData.id
                            });
                            this.handleDatasetMenuClose()
                        }}
                        >Delete Dataset</MenuItem> : null
                    }

                    {(JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount")))?
                        <MenuItem key={"share_dataset"} onClick={() => {
                            this.props.shareItem("dataset", {id: this.state.datasetContextData.id, item: this.state.datasetContextData.item});
                            this.handleDatasetMenuClose();
                        }}>Share</MenuItem> : null
                    }

                </Menu>



                <Menu
                    open={this.state.filterMenu !== null}
                    onClose={this.handleFilterMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        this.state.filterMenu !== null
                            ? {top: this.state.filterMenu.mouseY, left: this.state.filterMenu.mouseX}
                            : undefined
                    }
                >
                    <MenuItem key={"new_api"} onClick={(e) => {
                        this.props.addItem("apiwebhook", {filter: this.state.filterContextData.id})
                        this.handleFilterMenuClose();
                    }
                    }>New API</MenuItem>
                    <MenuItem key={"expand_apis"} onClick={(e) => {
                        this.toggleShowApis(this.state.filterContextData.id);
                        this.handleFilterMenuClose();
                    }}>Expand/Collapse</MenuItem>
                    <MenuItem key={"edit_filter"} onClick={(e) => {
                        this.props.openItem("filter", {
                            "name": this.state.filterContextData.name,
                            "id": this.state.filterContextData.id
                        });
                        this.handleFilterMenuClose();
                    }}>Open Filter</MenuItem>
                    <MenuItem key={"delete_filter"}
                              onClick={(e) => {
                                  this.props.deleteItem("filter", {
                                      "name": this.state.filterContextData.name,
                                      "id": this.state.filterContextData.id
                                  });
                                  this.handleFilterMenuClose()
                              }}
                    >Delete Filter</MenuItem>
                </Menu>

                <Menu
                    open={this.state.apiMenu !== null}
                    onClose={this.handleApiMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        this.state.apiMenu !== null
                            ? {top: this.state.apiMenu.mouseY, left: this.state.apiMenu.mouseX}
                            : undefined
                    }
                >
                    <MenuItem key={"edit_api"} onClick={(e) => {
                        this.props.openItem("apiwebhook", {
                            "name": this.state.apiContextData.name,
                            "id": this.state.apiContextData.id
                        });
                        this.handleApiMenuClose();
                    }}>Open API</MenuItem>
                    <MenuItem key={"delete_api"} onClick={(e) => {
                        this.props.deleteItem("apiwebhook", {
                            "name": this.state.apiContextData.name,
                            "id": this.state.apiContextData.id
                        });
                        this.handleApiMenuClose();
                    }}
                    >Delete API</MenuItem>
                </Menu>


                <Menu
                    open={this.state.reportMenu !== null}
                    onClose={this.handleReportMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        this.state.reportMenu !== null
                            ? {top: this.state.reportMenu.mouseY, left: this.state.reportMenu.mouseX}
                            : undefined
                    }
                >
                    <MenuItem key={"edit_report"} onClick={(e) => {

                        this.props.openItem("report", {
                            "name": this.state.reportContextData.name,
                            "id": this.state.reportContextData.id
                        });
                        this.handleReportMenuClose();
                    }}>Edit Report</MenuItem>
                    <MenuItem key={"delete_report"} onClick={(e) => {
                        this.props.deleteItem("report", {
                            "name": this.state.reportContextData.name,
                            "id": this.state.reportContextData.id
                        });
                        this.handleReportMenuClose();
                    }}
                    >Delete Report</MenuItem>
                </Menu>

            </div>

        )
    }

}