import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import DialogAppBar from "./dialogAppBar";
import {ACCOUNT_SELECT_ACCOUNT_HEAD} from "../../helpers/strConstants";
import {req_user_invite, req_user_select_community_account} from "../../helpers/requests";

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

export default class AccountChangeDialog extends React.Component {

    state = {
        newColumnName: this.props.default,
        columnError: false,
        email: "",
        userType: "standard",
        accountId: this.props.accountId,
        alert: { open: false, message: "", type: "" },
        loading: false
    }

    handleChange = (field, e) => {
        this.setState({ [field]: e.target.value });
    };

    setAccountCommunity = () => {
        let self = this;
        localStorage.setItem("plan", "Community")
        req_user_select_community_account().then(function (result) {
            if (result.status === 200) {} else {}
            self.props.close()
        })
    }

    inviteUser = (e) => {
        e.preventDefault();
        let self = this;

        let body = {
            email: this.state.email,
            account: this.props.user.accountId,
        }
        req_user_invite(this.state.user.id, body).then(function (result) {
            if (result.status === 200) {
                this.setState({ email: "", userType: "standard"})
                self.setState({loading: false, alert:{...{ "open": true, "message": "The account invitation has been sent.", "type": "success", "timeout": 5000 }}});
                self.props.close()
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem sending the account invitation.", "type": "error", "timeout": 5000 }}});
            }
        })
    }

    render() {
        return (
            <Dialog

                maxWidth={"sm"}
                fullWidth
                open={this.props.open || false}
                onClose={this.props.close}
            >
                <DialogAppBar title={ACCOUNT_SELECT_ACCOUNT_HEAD} close={this.handleDeleteClose} />
                <Box sx={{padding: 2 }}>

                    <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>


                    <div sx={{ width: "300px", marginRight: "10px" }}>
                        <div style={{ height: "66px"}}>
                        &nbsp;
                        </div>
                        <div style={{marginBottom: "24px", padding: "34px 36px 28px", fontFamily: "apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif"

                        }}>
                            <div style={{marginTop: "2px", marginBottom: "4px"}}>
                                <span style={{fontSize: "20px", fontWeight: "600",
                                    color: "#1a1a1a",
                                    fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif"}}>Community Account</span>
                            </div>
                            <div style={{fontSize: "14px", opacity: ".5", marginTop: "4px", marginBottom: "20px"
                            }}>
                                Check things out!
                            </div>
                            <div style={{fontSize: "36px", color:"#1a1a1a", fontWeight: 700}}>
                                Free!
                            </div>
                            <div>
                                <Button variant={"contained"} style={{width: "100%", marginTop: "8px",  fontWeight: 500,
                                    padding: "9px 24px", fontFamily: "apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif"}}
                                onClick={this.setAccountCommunity}>
                                    <span style={{fontSize: "16px"}}>Continue</span>
                                </Button>
                            </div>

                            <div style={{marginBottom: "12px", color: "#1a1a1a", fontSize: "14px",
                                fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif"}}>
                                <span style={{margin: "12px 0", fontSize: "14px", display: "block"}}>This includes:</span>


                                <div style={{display: "flex", flexWrap: "nowrap", marginBottom: "4px"}}>
                                    <div style={{marginTop: "2px", minWidth: "28px"}}>
                                        <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false"
                                             fill="#1a1a1a" color="#1a1a1a" fill-opacity="0.5" height="12"
                                             viewBox="0 0 16 16" width="12">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fill-rule="evenodd"></path>
                                        </svg></div><div>6 Projects</div>
                                </div>
                                <div style={{display: "flex", flexWrap: "nowrap", marginBottom: "4px"}}>
                                    <div style={{marginTop: "2px", minWidth: "28px"}}>
                                        <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false"
                                             fill="#1a1a1a" color="#1a1a1a" fill-opacity="0.5" height="12"
                                             viewBox="0 0 16 16" width="12">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fill-rule="evenodd"></path>
                                        </svg>
                                    </div><div>20 Datasets</div>
                                </div>
                                <div style={{display: "flex", flexWrap: "nowrap", marginBottom: "4px"}}>
                                    <div style={{marginTop: "2px", minWidth: "28px"}}>
                                        <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false"
                                             fill="#1a1a1a" color="#1a1a1a" fill-opacity="0.5" height="12"
                                             viewBox="0 0 16 16" width="12">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fill-rule="evenodd"></path>
                                        </svg>
                                    </div><div>4 API Request Tokens</div>
                                </div>
                                <div style={{display: "flex", flexWrap: "nowrap", marginBottom: "4px"}}>
                                    <div style={{marginTop: "2px", minWidth: "28px"}}>
                                        <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false"
                                             fill="#1a1a1a" color="#1a1a1a" fill-opacity="0.5" height="12"
                                             viewBox="0 0 16 16" width="12">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fill-rule="evenodd"></path>
                                        </svg>
                                    </div><div>Dataset Limit of 3,000 records</div>
                                </div>
                                <div style={{display: "flex", flexWrap: "nowrap", marginBottom: "4px"}}>
                                    <div style={{marginTop: "2px", minWidth: "28px"}}>
                                        <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false"
                                             fill="#1a1a1a" color="#1a1a1a" fill-opacity="0.5" height="12"
                                             viewBox="0 0 16 16" width="12">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fill-rule="evenodd"></path>
                                        </svg>
                                    </div><div>10GB Total Storage</div>
                                </div>
                                <div style={{display: "flex", flexWrap: "nowrap", marginBottom: "4px"}}>
                                    <div style={{marginTop: "2px", minWidth: "28px"}}>
                                        <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false"
                                             fill="#1a1a1a" color="#1a1a1a" fill-opacity="0.5" height="12"
                                             viewBox="0 0 16 16" width="12">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fill-rule="evenodd"></path>
                                        </svg>
                                    </div><div>2 Additional Contributors</div>
                                </div>
                            </div>
                        </div>
                    </div>






                    <div style={{width: "300px"}}>
                    <stripe-pricing-table pricing-table-id={process.env.REACT_APP_STR_TABLE_ID}
                                          publishable-key={process.env.REACT_APP_STR_PUB_KEY}
                                          client-reference-id={JSON.parse(localStorage.getItem("ownedAccount"))}
                                          customer-email={localStorage.getItem("ownerEmail")}>
                    </stripe-pricing-table>
                    </div>
                    </div>


                    <div style={{textAlign: "center"}}>
                        Interested in something bigger? Enterprise, Private-label, Stand-alone? Contact us at info@simpledataset.com.
                    </div>

                </Box>


            </Dialog>)
    }
}