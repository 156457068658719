import * as React from 'react';
import './home.css';
import LoginConsole from "../login/loginConsole";
import QuickAccessConsole from "../quickAccessConsole/quickAccessConsole";
import logo from "../../assets/SimpleDatasetBeta.png";

const Home = () => (
    <div className="home">
        <div  className="homeLogoContainer"><img src={logo} alt="Simple:Dataset Logo" className="homeLogo" /></div>
        <div className="homeContentContainer">
            {(!localStorage.getItem("rtoken") || localStorage.getItem("rtoken") === "undefined") ?
                    <LoginConsole selectLogin={true} /> :
                    <QuickAccessConsole/>
                }
        </div>
    </div>
)

export default Home;
