import React, {Component} from 'react';
import TermsOfService from "../content/termsOfService";


export default class PageTerms extends Component {
    render() {
        return (

            <div className="terms" style={{height: '100%', textAlign: "left"}}>
                <div style={{marginTop: "10px", display: "flex", flexDirection: "row"}}>
                    <div style={{width: "100%", paddingRight: "5px"}}>
                        <div style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px"}}>
                            <TermsOfService />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}