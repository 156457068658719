import React, {Component} from 'react';
import Typography from "@mui/material/Typography";
import {Checkbox, FormControl, FormControlLabel, FormGroup} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {req_user_settings_put} from "../../helpers/requests";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import BackdropAppear from "../staticDisplays/backdropAppear";
import {GENERIC_LOAD_ERROR} from "../../helpers/strConstants";

export default class PageSettings extends Component {

    state = {
        content: JSON.parse(localStorage.getItem("settings")),
        alert: { open: false, message: "", type: "" },
        loading: false,
    }

    saveSettings = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const settingsObj = {};
        settingsObj.retainTabs = !!data.get("retainTabs", false);
        settingsObj.openTabsDatasets = !!data.get("openTabsDatasets", false);
        settingsObj.openTabsFilters = !!data.get("openTabsFilters", false);
        settingsObj.openTabsApis = !!data.get("openTabsApis", false);
        let self = this;
        self.setState({loading: true});
        req_user_settings_put(localStorage.getItem("uid"), settingsObj).then(function (result) {
            if (result.status === 200) {
                self.setState({loading: false, alert:{...{ "open": true, "message": "Settings were updated successfully.", "type": "success", "timeout": 5000 }}});
                localStorage.setItem("settings", JSON.stringify(settingsObj));
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    handleChange = (field, e) => {
        let content = this.state.content;
        content[field] = !!e.target.value;
        this.setState({ content: {...content}});
    };

    purgeAlert = () => { this.setState({alert: {...{ open: false, message: "", type: "" }}}) }

    render() {
        return (
            <div className="settings" style={{height: '100%'}}>
                <BackdropAppear loading={this.state.loading || false} />
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                <div style={{marginTop: "10px", display: "flex", flexDirection: "row", textAlign: "left"}}>
                    <div style={{width: "100%", paddingRight: "5px"}}>
                        <div style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", height: "auto"}}>
                            <Typography variant="h5" align={"left"} gutterBottom>
                                Edit Settings
                            </Typography>
                            <Box component="form" onSubmit={this.saveSettings}>
                                <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="retainTabs" id="retainTabs"
                                                          defaultChecked={this.state.content.retainTabs}
                                                          onChange={(e) => this.handleChange("retainTabs", e)} />
                                            }
                                            label="Retain my open tabs info if I log out."
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="openTabsDatasets" id="openTabsDatasets"
                                                          defaultChecked={this.state.content.openTabsDatasets}
                                                          onChange={(e) => this.handleChange("openTabsDatasets", e)}/>
                                            }
                                            label="When I create a new dataset, open in in a new tab."
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="openTabsFilters" id="openTabsFilters"
                                                          defaultChecked={this.state.content.openTabsFilters}
                                                          onChange={(e) => this.handleChange("openTabsFilters", e)}/>
                                            }
                                            label="When I create a new filter, open in in a new tab."
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="openTabsApis" id="openTabsApis"
                                                          defaultChecked={this.state.content.openTabsApis}
                                                          onChange={(e) => this.handleChange("openTabsApis", e)}/>
                                            }
                                            label="When I create a new API, open in in a new tab."
                                        />
                                    </FormGroup>

                                    <div style={{textAlign: 'center', marginTop: '10px'}}>
                                        <Button type="submit" variant="contained" size="small"
                                        >Save Settings</Button>
                                    </div>
                                </FormControl>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
