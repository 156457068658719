import React, {Component} from "react";
import {FormControl, FormHelperText, InputLabel, ListItemText, OutlinedInput, Select, TextField} from "@mui/material";
import RemoteAddressInput from "./remoteAddressInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import {HLP_TOKEN_PERMITTED_ORIGINS} from "../../helpers/helpConstants";
import HelperInvoke from "../messaging/helperInvoke";


export default class CreateTokenPanel extends Component {

    render() {
        return (
            <div>
                <div><TextField fullWidth label={"Token Name"} style={{marginBottom: "10px"}} value={this.props.token["tokenName"]}
                                onChange={(e) => { this.props.handleNewTokenChange("tokenName", e) }}
                                helperText={this.props.token["tokenNameError"] ? "Token Name is required" : ""}
                                error={this.props.token["tokenNameError"]}
                />
                </div>
                <div style={{display: "flex", flexDirection: "row"}} >
                <div style={{width: "100%", maxWidth: "360px"}}>
                    <RemoteAddressInput
                        selectedTags={(e) => {this.props.handleNewTokenChange("tokenRemoteAddresses", e)}}
                        fullWidth
                        variant="outlined"
                        id="permittedAddresses"
                        name="permittedAddresses"
                        placeholder="Add Permitted Addresses"
                        label="Permitted Remote IPs"
                        required={this.props.token &&
                        this.props.token["tokenRemoteAddresses"] &&
                        this.props.token["tokenRemoteAddresses"].length === 0}
                        tags={this.props.token["tokenRemoteAddresses"] || []}
                        helperText={this.props.token["tokenRemoteAddressesError"] ? "At least one remote address it required." : ""}
                        error={this.props.token["tokenRemoteAddressesError"]}
                    />
                </div>
                <div><HelperInvoke content={HLP_TOKEN_PERMITTED_ORIGINS} /></div>
            </div>
                <div><TextField fullWidth type={"date"} label={"Expiration Date"} style={{marginBottom: "10px"}} value={this.props.token["tokenExpirationDate"]}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => { this.props.handleNewTokenChange("tokenExpirationDate", e) }}
                                helperText={this.props.token["tokenExpirationDateError"] ? "Expiration Date is required" : ""}
                                error={this.props.token["tokenExpirationDateError"]}
                />

                </div>
                <div>
                    <FormControl fullWidth style={{paddingBottom: "10px"}}>
                        <InputLabel id="field_verb_label">Select HTTP Access Verbs</InputLabel>
                        <Select
                            fullWidth
                            labelId={"field_verb_label"}
                            defaultValue={"Select HTTP Access Verbs"}
                            multiple
                            value={this.props.token.tokenVerbs || []}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            error={this.props.token["tokenVerbsError"]}
                            onChange={(e) => {
                                this.props.handleNewTokenChange("tokenVerbs", e);
                            }}>
                            <MenuItem value={"Select HTTP Verb Access"}>Select HTTP Verb Access</MenuItem>
                            {["GET", "POST", "PUT", "DELETE"].map(record => {
                                return <MenuItem key={record}
                                                 value={record} name={record}>
                                    <Checkbox checked={(this.props.token.tokenVerbs ? this.props.token.tokenVerbs: []).indexOf(record) > -1} />
                                    <ListItemText key={record} primary={record} />
                                </MenuItem>
                            })
                            }
                        </Select>
                        <FormHelperText style={{color: '#D14343'}}>{this.props.token["tokenVerbsError"] ? "At least one Verb selection is required" : ""}</FormHelperText>
                    </FormControl>

                </div>
                <div style={{"textAlign": "right"}}>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            this.props.createToken();
                        }}
                    >Create Token</Button>
                </div>
            </div>
        )
    }
}