import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import DialogAppBar from "./dialogAppBar";
import {
    DATASET_ADD_COLUMN_MISSING, DATASET_ADD_COLUMN_NAME_MISSING,
    DATASET_ADD_NEW_COLUMN_HEAD, DATASET_COLUMN_EXISTS,

} from "../../helpers/strConstants";
import ColumnTypesPanel from "../formControls/columnTypesPanel";
import FormFieldCreator from "../forms/formFieldCreator";
import {FormObject} from "../../helpers/formObjects";
import SnackbarInvoke from "../messaging/snackbarInvoke";

export default class NewColumnDialog extends React.Component {

    state = {
        collectionObjects: [],
        alert: { open: false, message: "", type: "" },
    }

    addColumn = (e, type) => {
        let collectionObjects = this.state.collectionObjects;
        let max = 0;
        this.props.ids.forEach(id => { max = (parseInt(id) > max) ? parseInt(id) : max; })
        const newId = max + 1;
        let collectionObject = new FormObject(newId);
        collectionObject.resetObjBase(type);
        collectionObjects.push(collectionObject);
        this.setState({collectionObjects: [...collectionObjects]});
    }
    saveColumn  = (e) => {
        e.preventDefault();
        let self = this;

        if (this.state.collectionObjects.length > 0){
            if (!this.state.collectionObjects[0].dObj.fieldName){
                this.state.collectionObjects[0].dObj.error = true;
                this.state.collectionObjects[0].dObj.errorMessage = DATASET_ADD_COLUMN_NAME_MISSING;
                this.setState(this.state);
            } else {
                this.state.collectionObjects[0].dObj.error = false;
                this.state.collectionObjects[0].dObj.errorMessage = "";
                this.props.save(this.state.collectionObjects[0])
                this.setState({collectionObjects: [...[]]})
                this.props.close()
            }

        } else {
            self.setState({alert:{...{ "open": true, "message": DATASET_ADD_COLUMN_MISSING, "type": "error", "timeout": 5000 }}});
        }
    }

    handleCreatorDelete = (id) => {
        let newCollection = this.state.collectionObjects.filter(item => item.fieldId !== parseInt(id));
        this.setState({collectionObjects: [...newCollection]})
    }

    handleCreatorChange = () => {
        let currentIndex = 0;
        if (this.props.existingColumns.includes(this.state.collectionObjects[currentIndex].dObj.fieldName.toLowerCase())){
            let cObs = this.state.collectionObjects;
            cObs[currentIndex].dObj.error = true;
            cObs[currentIndex].dObj.errorMessage = DATASET_COLUMN_EXISTS;
            this.setState({collectionObjects: {...cObs}});
        } else {
            if (this.state.collectionObjects[currentIndex]) {
                this.state.collectionObjects[currentIndex].clearError();
            }
        }
        this.setState(this.state);
    }

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={this.props.open}
                onClose={this.props.close}
            >
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                <DialogAppBar title={DATASET_ADD_NEW_COLUMN_HEAD} close={this.handleDeleteClose}/>
                <Box component="form" onSubmit={this.saveColumn} sx={{padding: 2}}>
                    <Typography variant="body" gutterBottom component="div">
                        { this.state.collectionObjects.map((fObj, index) => {

                            return <FormFieldCreator key={fObj.fieldId}
                                                     fieldObj={fObj}
                                                     updateState={this.updateState}
                                                     onChange={this.handleCreatorChange}
                                                     handleCreatorDelete={this.handleCreatorDelete}
                                                     deactivateRequired={true}
                            />
                        })
                        }
                        {
                            this.state.collectionObjects.length === 0 ?
                                <ColumnTypesPanel addColumn={this.addColumn}/> : null
                        }
                    </Typography>

                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button
                            variant="contained"
                            size="small"
                            type="submit"
                        >Add Column</Button>
                        <Button variant="contained" size="small" style={{marginLeft: "10px"}}
                                onClick={() => { this.props.close() }}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>)
    }
}

