import React, {Component} from 'react';
import PersonIcon from '@mui/icons-material/Person';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import {ListItemIcon, ListItemText, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {clearLogout} from "../../helpers/settings";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ApprovalIcon from '@mui/icons-material/Approval';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Button from "@mui/material/Button";
import {
    req_get_notifications_list,
    req_post_notifications_response,
    req_user_settings_put
} from "../../helpers/requests";
import {GENERIC_LOAD_ERROR} from "../../helpers/strConstants";
import eventBus from "../../helpers/eventBus";

export default class MenuUser extends Component {
    state = {
        open: false,
        anchorEl: null,
        redirect: false,
        notificationsOpen: false,
        accounts: [],
        notifications: []
    };

    handleMenuClick = (event) => {
        event.preventDefault();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleNotificationsClick = (event) => {
        event.preventDefault();
        this.setState({ notificationsOpen: event.currentTarget });
    };

    loadNotifications = () => {
        let self = this;
        req_get_notifications_list().then(function (result) {
            if (result.status === 200) {
                let rowRecs = [];

                result.data.data.forEach(function (record) {
                    rowRecs.push(record);
                });
                self.setState({notifications: [...rowRecs]});

            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    handleNotificationResponse = (notificationId, response) => {
        let self = this;
        let body = {
            approve: response
        }
        req_post_notifications_response(notificationId, body).then(function (result) {
            if (result.status === 200) {
                self.loadNotifications()
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    setAccount = (e) => {

        let self = this;
        let settings = JSON.parse(localStorage.getItem("settings"))

        if (settings && settings.retainTabs){
            const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
            settings.account[selectedAccount] = settings.account[selectedAccount] || {};
            if (JSON.parse(localStorage.getItem("portalTabs"))){
                settings.account[selectedAccount]["tabs"] = JSON.parse(localStorage.getItem("portalTabs"));
            } else {
                settings.account[selectedAccount]["tabs"] = [];
            }

            req_user_settings_put(localStorage.getItem("uid"), settings).then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("selectedAccount", JSON.stringify(e.target.value));
                    self.setState({selectedAccount: e.target.value});
                    localStorage.setItem("settings", JSON.stringify(settings));
                    if (settings.account.hasOwnProperty(e.target.value) && settings.account[e.target.value].hasOwnProperty("tabs")) {
                        localStorage.setItem("portalTabs", JSON.stringify(settings.account[e.target.value]["tabs"]));
                    } else {
                        localStorage.setItem("portalTabs", JSON.stringify([]));
                    }
                    window.location.reload(false);
                } else {
                    console.log("there was a problem writing settings")
                }
            })

        } else {
            localStorage.removeItem("portalTabs");
            window.location.reload(false);
        }
    }

    handleLogout = () => {
        clearLogout()
        window.location = '/';
        this.handleMenuClose();
        this.setState({ redirect: true });
    };

    handleMenuClose = () => { this.setState({ anchorEl: null }); };

    handleNotificationsClose = () => { this.setState({ notificationsOpen: null }); };

    componentDidMount() {
        this.setState({accounts: JSON.parse(localStorage.getItem("accounts"))});
        this.setState({selectedAccount: JSON.parse(localStorage.getItem("selectedAccount"))});
        this.loadNotifications();

        eventBus.on("updateUserMenu", () => {
            this.setState({accounts: JSON.parse(localStorage.getItem("accounts"))});
            this.loadNotifications();
        });
    }

    componentWillUnmount() {
        eventBus.remove("updateUserMenu");
    }

    render() {
        if (this.state.redirect) {
            window.location = '/home';
        }

        return(
            <div style={{marginLeft: "auto", padding: 2, fontSize: ".8rem", marginTop: "3px", marginBottom: "-3px"}}>
                <span style={{fontWeight: "500"}}>Account: </span>
                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "0px"}} size="small">
                <Select
                    value={this.state.selectedAccount || ""}
                    style={{padding: 0, paddingLeft: "10px"}}
                    sx={{ backgroundColor:'#fff', padding: 1,
                        '& .MuiOutlinedInput-notchedOutline': {
                            top: "0",
                            left: "0",
                            right: "0",
                            marginTop: "-6px"
                        }
                    }}
                    onChange={(e) => {this.setAccount(e)}}
                    inputProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: {
                                    backgroundColor: '#fff'
                                }
                            }
                        }
                    }}
                >
                    {
                        this.state.accounts.map((account) => {
                            return <MenuItem key={account.accountName + "_" + account.handle} value={account.id}>{ (account.id === JSON.parse(localStorage.getItem("ownedAccount")) ? "(Home)" : "") + " " + account.accountName}</MenuItem>
                        })
                    }

                </Select>
                </FormControl>
                <IconButton

                    sx={{ ml: "5px", color: this.props.color && this.props.color === "white" ? "#FFF" : "#406cbf", margin: 0 }}
                    aria-controls={this.state.open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                    onClick={this.handleNotificationsClick}
                >
                    <NotificationsIcon style={{fontSize: "20px"}}  />
                </IconButton>
                <IconButton

                    sx={{ ml: "5px", color: this.props.color && this.props.color === "white" ? "#FFF" : "#406cbf", margin: 0 }}
                    aria-controls={this.state.open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                    onClick={this.handleMenuClick}
                >
                    <PersonIcon style={{fontSize: "20px"}}  />
                </IconButton>
                <IconButton
                    component={Link} to='/settings'
                    sx={{ ml: "5px", color: this.props.color && this.props.color === "white" ? "#FFF" : "#406cbf", margin: 0}}
                    aria-controls={this.state.open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                >
                    <SettingsIcon style={{fontSize: "20px"}} />
                </IconButton>

                <Menu
                    anchorEl={this.state.anchorEl}
                    id="account-menu"
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                    onClick={this.handleMenuClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem component={Link} to='/settings/account' ><ListItemIcon><ManageAccountsIcon /></ListItemIcon>My account</MenuItem>
                    <MenuItem onClick={this.handleLogout}><ListItemIcon><LogoutIcon /></ListItemIcon>Logout</MenuItem>
                </Menu>

                <Menu
                    anchorEl={this.state.notificationsOpen}
                    id="account-menu"
                    open={Boolean(this.state.notificationsOpen)}
                    onClose={this.handleNotificationsClose}
                    onClick={this.handleNotificationsClose}

                    PaperProps={{
                        elevation: 0,
                        sx: {
                            width: "350px",
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {
                        this.state.notifications.map(notification =>{
                            return <MenuItem key={notification.id}>
                                {notification.notificationType === "approve" ?
                                    <ListItemIcon><ApprovalIcon/></ListItemIcon> : null
                                }
                                {notification.notificationType === "inform" ?
                                    <ListItemIcon><ChatBubbleIcon/></ListItemIcon> : null
                                }
                                <ListItemText style={{whiteSpace: "normal"}} primaryTypographyProps={{fontSize: '.8rem'}}>
                                    <div>
                                        <div>{notification.content}</div>
                                        { notification.notificationType === "approve" && notification.completed === false ?
                                            <div> <Button onClick={() =>{this.handleNotificationResponse(notification.id, "approve")}}>Approve</Button> <Button onClick={() => {this.handleNotificationResponse(notification.id, "decline")}}>Decline</Button> </div>
                                            : null
                                        }
                                    </div>
                                </ListItemText>
                            </MenuItem>
                        })
                    }

                </Menu>

            </div>
        )
    }
}