import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import DialogAppBar from "./dialogAppBar";
import {REMOVE_INVITE_USER_HEAD} from "../../helpers/strConstants";
import {req_member_delete} from "../../helpers/requests";

export default class AccountShareDeleteDialog extends React.Component {

    state = {

        alert: { open: false, message: "", type: "" },
        loading: false
    }

    handleChange = (field, e) => {
        this.setState({ [field]: e.target.value });
    }

    removeAccountMember = (e) => {
        e.preventDefault();

        this.setState({ loading: true });
        let self = this;
        req_member_delete(this.props.user.personId || this.props.user.ghostId).then(function (result) {
            if (result.status === 200) {
                self.props.close()
            } else {
                // self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.props.open || false}
                onClose={this.props.close}
            >
                <DialogAppBar title={REMOVE_INVITE_USER_HEAD} close={this.props.close} />
                <Box component="form" onSubmit={this.removeAccountMember} sx={{padding: 2}}>
                    <Typography variant="body" gutterBottom component="div">
                        Deleting an authorized user from your account will remove them from general account access as well as
                        removing them for any specific content shares such as projects and datasets. This action is permanent.<br /><br />
                        Clicking "Remove Them!" will remove <b>{this.props.user.displayName}</b> from accessing this account.<br />
                    </Typography>

                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button
                            size="small"
                            type="submit"
                            color="error"
                            variant="outlined"
                        >Remove Them!</Button>
                        <Button variant="contained" size="small" style={{marginLeft: "10px"}}
                                onClick={() => { this.props.close() }}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>)
    }
}

