import React, {Component} from "react";
import {Autocomplete} from "@mui/lab";
import {TextField} from "@mui/material";


export default class AutocompleteSelect extends Component {

    render() {
        return (
            <Autocomplete
                options={this.props.rows}
                autoHighlight
                required
                value={this.props.selectedRecord || null}
                onChange={(e, newValue) => {
                    this.props.selectValue(e, newValue);
                }}
                getOptionLabel={(option) => option.name}

                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={this.props.selectLabel}
                        inputProps={{
                            ...params.inputProps,
                        }}
                        required
                    />
                )}
            />
        )
    }


}