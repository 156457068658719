import React, {useState} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Link from '@mui/material/Link';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Contact from "./contact";
import projectStructure from "../../assets/ProjectInheritance.png";
import DialogAppBar from "../dialogs/dialogAppBar";


const Instructions = () => {

    const [contactOpen, setContactOpen] = useState(false);

    const openContact = () => {
        setContactOpen(true);
    }

    const closeContact = () => {
        setContactOpen(false);
    }

    return (
        <div className="Instructions">
            <Box component="form" style={{width: '100%', padding: '10px', margin: "0 auto"}}>

                <div style={{margin: "10px", display: "flex", flexDirection: "row"}}>
                    <div style={{
                        width: "33%",
                        padding: "10px",
                        border: "1px solid #ddd",
                        color: "#5f6368",
                        borderRadius: "5px",
                        minHeight: "250px",
                        margin: "5px"
                    }}>
                        <Typography variant="h5">
                            What Now?
                        </Typography>
                        <div style={{marginTop: "10px", textAlign: "left"}}>
                            <Typography variant="paragraph" style={{textAlign: "left"}}>
                                <b>First time?</b> Open <b>My First Project</b> in the left menu. It's a <b>project.</b><br /><br />
                                Next, open the <b>My Todo List</b> dataset. That's an active <b>dataset</b>.<br /><br />
                                Still good? Open the <b>Default Filter: My Todo List</b> filter. You can make an API from that.<br />
                                <br/><br/>
                                Looking for ideas? Check the <Link href={"https://simpledataset.notion.site/Use-Cases-e288fa2d5d9b4f81b3c765ef5a1056fd"}
                                      target="_blank">use cases</Link>.

                            </Typography>
                        </div>
                    </div>
                    <div style={{
                        width: "33%",
                        padding: "10px",
                        border: "1px solid #ddd",
                        color: "#5f6368",
                        borderRadius: "5px",
                        minHeight: "250px",
                        margin: "5px"
                    }}>
                        <Typography variant="h5">
                            How it's Organized
                        </Typography>
                        <div style={{marginTop: "10px", textAlign: "left"}}>
                            <div style={{textAlign: "center"}}>
                            <img src={projectStructure} alt="Project Structure" style={{width: "100%", maxWidth: "350px", align: "center", height: "auto"}} />
                            </div>
                            <Typography variant="paragraph" style={{textAlign: "left"}}>
                                In short; datasets go inside of a project, filters filter dataset data, and specialized API endpoints are created from the filters.
                            </Typography>
                        </div>

                    </div>
                    <div style={{
                        width: "33%",
                        padding: "10px",
                        border: "1px solid #ddd",
                        color: "#5f6368",
                        borderRadius: "5px",
                        minHeight: "250px",
                        margin: "5px"
                    }}>
                        <Typography variant="h5">
                            Documentation
                        </Typography>
                        <div style={{marginTop: "10px", textAlign: "left"}}>
                            <Typography variant="paragraph" style={{textAlign: "left"}}>
                                We have a documentation portal on <Link href={"https://simpledataset.notion.site/"}
                                                                           target="_blank"
                                                                           variant="body2">Notion</Link>.
                                It's a work in progress. If there's something you want to see, let us know. If you're
                                pulling your hair out, contact us by one of the below contact methods.
                            </Typography>
                        </div>

                    </div>
                </div>
                <div style={{margin: "10px", display: "flex", flexDirection: "row"}}>
                    <div style={{
                        width: "33%",
                        padding: "10px",
                        border: "1px solid #ddd",
                        color: "#5f6368",
                        borderRadius: "5px",
                        minHeight: "250px",
                        margin: "5px"
                    }}>
                        <Typography variant="h5">
                            Automate
                        </Typography>
                        <div style={{marginTop: "10px", textAlign: "left"}}>
                            <Typography variant="paragraph" style={{textAlign: "left"}}>
                                Want to script your data? We have APIs beyond this Portal App. If you want to create
                                projects and datasets through an API, we got you.
                                <br/><br/>
                                You can find out more on the API here: <Link href={"https://simpledataset.notion.site/"}
                                                                             target="_blank">https://simpledataset.notion.site/</Link>
                                <br/>
                                <br/>
                                As we release apps to github, you can find them here: <Link href={"https://github.com/SimpleDataset"}
                                                                                            target="_blank">https://github.com/SimpleDataset</Link>
                                <br/>
                                <br/>
                                Requests? &nbsp; <Button variant="contained" onClick={openContact} size="small"
                                                         style={{paddingTop: "1px", paddingBottom: "1px"}}>Contact
                                Us</Button>
                            </Typography>
                        </div>
                    </div>
                    <div style={{
                        width: "33%",
                        padding: "10px",
                        border: "1px solid #ddd",
                        color: "#5f6368",
                        borderRadius: "5px",
                        minHeight: "250px",
                        margin: "5px"
                    }}>
                        <Typography variant="h5">
                            Integrate
                        </Typography>
                        <div style={{marginTop: "10px", textAlign: "left"}}>
                            <Typography variant="paragraph" style={{textAlign: "left"}}>
                                We've basically just kicked this bad boy off but would love to hear your integration
                                ideas. Got something good? Requests? &nbsp; <Button variant="contained" onClick={openContact} size="small"
                                                                                    style={{paddingTop: "1px", paddingBottom: "1px"}}>Contact
                                Us</Button>
                                <br/><br/>
                                <div style={{display: "none"}}>
                                Importing API data in to Power BI from Simple:Dataset<br/>
                                Importing API data in to Power Tableau from Simple:Dataset<br/>
                                </div>
                            </Typography>
                        </div>
                    </div>
                    <div style={{
                        width: "33%",
                        padding: "10px",
                        border: "1px solid #ddd",
                        color: "#5f6368",
                        borderRadius: "5px",
                        minHeight: "250px",
                        margin: "5px"
                    }}>
                        <Typography variant="h5">
                            Questions, Requests, Something's Broken?
                        </Typography>
                        <Table style={{marginTop: "10px"}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{fontWeight: "bold", textAlign: "right"}}>Contact
                                        Form:</TableCell>
                                    <TableCell style={{fontSize: ".75rem"}}> <Button variant="contained" size="small"
                                                                                     onClick={openContact} style={{
                                        paddingTop: "1px",
                                        paddingBottom: "1px"
                                    }}>Contact Us</Button></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontWeight: "bold", textAlign: "right"}}>Discord:</TableCell>
                                    <TableCell style={{fontSize: ".75rem"}}><Link href={"https://discord.gg/xmAAY5867U"}
                                                                                  target="_blank">https://discord.gg/xmAAY5867U</Link></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontWeight: "bold", textAlign: "right"}}>GitHub:</TableCell>
                                    <TableCell style={{fontSize: ".75rem"}}><Link href={"https://github.com/SimpleDataset"}
                                                                                  target="_blank">https://github.com/SimpleDataset</Link></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontWeight: "bold", textAlign: "right"}}>Email:</TableCell>
                                    <TableCell style={{fontSize: ".75rem"}}>info@simpledataset.com</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontWeight: "bold", textAlign: "right"}}>
                                        Documentation:</TableCell>
                                    <TableCell style={{fontSize: ".75rem"}}><Link
                                        href={"https://simpledataset.notion.site/"}
                                        target="_blank">Notion help site.</Link></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Box>

            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={contactOpen}
                onClose={closeContact}

            >
                <DialogAppBar title="Contact Submission" close={closeContact} />

                <Box sx={{padding: 2}}>
                    <Contact closeContact={closeContact}/>
                </Box>
            </Dialog>
        </div>
    )
}

export default Instructions;
