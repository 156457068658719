import React, {Component} from "react";
import Button from "@mui/material/Button";
import integerImage from "../../assets/typeImages/integer.png";
import decimalImage from "../../assets/typeImages/decimal.png";
import booleanImage from "../../assets/typeImages/boolean.png";
import textImage from "../../assets/typeImages/text.png";
import dateImage from "../../assets/typeImages/date.png";
import datetimeImage from "../../assets/typeImages/datetime.png";
import timeImage from "../../assets/typeImages/time.png";
import optionsImage from "../../assets/typeImages/options.png";
import objectImage from "../../assets/typeImages/object.png";

export default class ColumnTypesPanel extends Component {
    fieldProps = {
        variant: "contained",
        size: "small",
        className:"columnTypeButton"
    }

    fieldTypes = [
        {type: "int", img: integerImage, alt: "Create an integer column"},
        {type: "decimal", img: decimalImage, alt: "Create a decimal column"},
        {type: "boolean", img: booleanImage, alt: "Create a true|false column"},
        {type: "string", img: textImage, alt: "Create a text/string column"},
        {type: "date", img: dateImage, alt: "Create a date column"},
        {type: "datetime", img: datetimeImage, alt: "Create a datetime column"},
        {type: "time", img: timeImage, alt: "Create a time column"},
        {type: "enum", img: optionsImage, alt: "Create an options column"},
        {type: "object", img: objectImage, alt: "Create an object column"}
    ]

    render() {
        return (
            <div style={{marginTop: "10px"}}>
                Add column type:
                {
                    this.fieldTypes.map((fieldType) => {
                        return (
                            <Button {...this.fieldProps} key={fieldType.type}
                                onClick={(e) => {this.props.addColumn(e, fieldType.type) }}>
                                <img src={fieldType.img} alt={fieldType.alt} />
                            </Button>)
                        }
                    )
                }
            </div>
        )
    }
}