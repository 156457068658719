import {Navigate, Route, Routes} from "react-router-dom";
import LogIn from "../components/login/login";
import Resetpass from "../components/login/resetpass";
import Home from "../components/home/home";
import Settings from "../components/settings/settings";
import React, {Component} from "react";
import Register from "../components/login/register";
import Contact from "../components/pages/contact";
import Pricing from "../components/pages/pricing";
import Product from "../components/pages/product";
import Portal from "../components/portal/portal";
import Verify from "../components/login/verify";
import AcctVerify from "../components/login/acctVerify";


/*
const PrivateRoute = ({ children }) => {
    let auth = !!localStorage.getItem("rtoken");
    return auth ? children : <Navigate to="/home" />;
}
*/

const PrivateRoute = (path = "", { children }) => {
    let auth = !!localStorage.getItem("rtoken");
    let plan = !!localStorage.getItem("plan");
    if (!auth){ return <Navigate to="/home" />; }
    else if (auth && !plan && ["acctverify", "pricing"].includes(path.path)) {
        return path.children;
    }
    else if (auth && !plan) { return <Navigate to="/pricing" />; }
    else { return path.children; }
}

export default class DynamicRoutes extends Component {
    render() {
        return (
        <Routes>

            <Route path="/home" element={<Home />}/>
            <Route path="/login" element={<LogIn />}/>
            <Route path="/regverify" element={<Verify />}/>
            <Route path="/resetpass" element={<Resetpass />}/>
            <Route path="/register" element={<Register />}/>
            <Route path="/" element={<PrivateRoute><Portal /></PrivateRoute>}/>
            <Route path="/acctverify" element={<PrivateRoute path={"acctverify"}><AcctVerify /></PrivateRoute>}/>
            <Route path="/product" element={<Product />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/pricing" element={<PrivateRoute path={"pricing"}><Pricing /></PrivateRoute>}/>
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>}/>
            <Route path="/settings/:subpage" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/portal" element={<PrivateRoute><Portal /></PrivateRoute>}/>
        </Routes>
        )}
}