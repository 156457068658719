import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, {Component} from "react";
import PortalChildNav from "./portalChildNav";
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import "./menuLeftNavListItem.css"
import IconButton from "@mui/material/IconButton";
import PeopleIcon from '@mui/icons-material/People';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default class MenuLeftNavListItem extends Component {

    state = {
        expanded: this.props.expanded,
        contextMenu: null
    }

    openContextMenu = (e) => {
        e.preventDefault();
        this.setState({
            contextMenu: this.state.contextMenu === null
                ? {
                    mouseX: e.clientX + 2,
                    mouseY: e.clientY - 6,
                } : null
        })
    }

    handleContextMenuClose = () => {
        this.setState({contextMenu: null});
    }

    toggleChildMenu = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    render() {
        let IconComponent = this.props.linkObj.icon;
        //console.log(this.props.linkObj)
        return (
            <div>
                <ListItem key={this.props.linkObj.id}
                          onContextMenu={this.openContextMenu}
                          style={{ cursor: 'context-menu', display: "flex" }}
                          disablePadding
                          sx={{display: "block", fontSize: ".9rem", paddingLeft: "5px", backgroundColor: "#445566", color: "#eee", borderTop: "1px solid #567"}}
                >
                    <div style={{width: "100%", display: "inline-flex", cursor: "pointer"}}>
                    {this.props.linkObj.showExpand ?
                        <ListItemButton
                            className={"menuLeftNavListItemButton"}
                            aria-label="Toggle menu children open"
                            sx={{
                                justifyContent: this.props.open ? 'initial' : 'center',
                                px: 2.5
                            }}
                            onClick={() => { this.toggleChildMenu(); }}
                        >
                            {this.state.expanded ? <ExpandMore sx={{ fontSize: 18 }}/> : <ChevronRightIcon sx={{ fontSize: 18 }}/> }
                        </ListItemButton> : null
                    }

                        {(this.props.linkObj.type === 'project') ?
                            <ListItemButton
                                className={"menuLeftNavListItemButton"}
                                sx={{
                                    justifyContent: this.props.open ? 'initial' : 'center',
                                    px: 2.5
                                }}
                                onClick={() => { this.toggleChildMenu(); }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: this.props.open ? 1 : 'auto',
                                        justifyContent: 'center',
                                        color: "#ccc",
                                    }}
                                ><IconComponent sx={{fontSize: 16}}/>
                                </ListItemIcon>

                                    <ListItemText primary={<React.Fragment>{this.props.linkObj.name}&nbsp;<PeopleIcon sx={{fontSize: 12, display: this.props.linkObj.multiuser ? "inline-block" : "none"}} /></React.Fragment>}
                                                  sx={{opacity: this.props.open ? 1 : 0}}
                                                  primaryTypographyProps={{fontSize: '.8rem', fontWeight: '500'}}/>

                            </ListItemButton> :
                            <ListItemButton
                                className={"menuLeftNavListItemButton"}
                                sx={{
                                    justifyContent: this.props.open ? 'initial' : 'center',
                                    px: 2.5
                                }}
                                onClick={() => {
                                    this.props.openItem("dashboard", {
                                        "name": this.props.linkObj.name,
                                        "id": this.props.linkObj.id
                                    });
                                    this.toggleChildMenu();
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: this.props.open ? 1 : 'auto',
                                        justifyContent: 'center',
                                        color: "#cf7e3a",
                                    }}
                                ><IconComponent sx={{fontSize: 16}}/>
                                </ListItemIcon>

                                    <ListItemText
                                        primary={this.props.linkObj.name}
                                        onClick={() => {
                                            this.props.openItem("dashboard", {
                                                "name": this.props.linkObj.name,
                                                "id": this.props.linkObj.id
                                            })
                                        }}
                                        sx={{opacity: this.props.open ? 1 : 0}}
                                        primaryTypographyProps={{fontSize: '.8rem', fontWeight: '500'}}/>

                            </ListItemButton>
                        }

                    </div>
                    <div onClick={this.openContextMenu}><IconButton>
                        <MoreVertIcon sx={{ fontSize: 20 }} />
                    </IconButton></div>
                </ListItem>
                {this.props.linkObj.showExpand && this.state.expanded ? <PortalChildNav parent={this.props.linkObj} openItem={this.props.openItem} addItem={this.props.addItem} deleteItem={this.props.deleteItem} shareItem={this.props.shareItem} /> : null}

                {(this.props.linkObj.type === 'project') ?
                    <Menu
                        open={this.state.contextMenu !== null}
                        onClose={this.handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            this.state.contextMenu !== null
                                ? { top: this.state.contextMenu.mouseY, left: this.state.contextMenu.mouseX }
                                : undefined
                        }
                    >
                        {this.props.linkObj.role !== "viewer" ?
                            <MenuItem key={"new_dataset"} onClick={() => {
                                this.props.addItem("dataset", {project: this.props.linkObj.id});
                                this.handleContextMenuClose();
                            }}>New Dataset</MenuItem> : null
                        }

                        <MenuItem key={"expand_datasets"} onClick={() =>{
                            this.toggleChildMenu();
                            this.handleContextMenuClose();
                        }}>Expand/Collapse</MenuItem>

                        {this.props.linkObj.role !== "viewer" ?
                            <MenuItem key={"rename_project"} onClick={() => {
                                this.props.editItem("project", {id: this.props.linkObj.id});
                                this.handleContextMenuClose();
                            }}>Rename Project</MenuItem> : null
                        }

                        {this.props.linkObj.role !== "viewer" ?
                            <MenuItem key={"delete_project"} onClick={() => {
                                this.props.deleteItem(this.props.linkObj.type, {"name": this.props.linkObj.name, "id": this.props.linkObj.id });
                                this.handleContextMenuClose()
                            }}>Delete Project</MenuItem> : null
                        }

                        { (JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))) ?
                            <MenuItem key={"share_project"} onClick={() => {
                                this.props.shareItem("project", {id: this.props.linkObj.id, item: this.props.linkObj});
                                this.handleContextMenuClose();
                            }}>Share</MenuItem>: null
                        }
                    </Menu> : null
                }

                {(this.props.linkObj.type === 'dashboard') ?
                    <Menu
                        open={this.state.contextMenu !== null}
                        onClose={this.handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            this.state.contextMenu !== null
                                ? { top: this.state.contextMenu.mouseY, left: this.state.contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem key={"new_report"} onClick={() => {
                            this.props.addItem("report");
                            this.handleContextMenuClose();
                        }}>New Report Page</MenuItem>
                        <MenuItem key={"expand_reports"} onClick={() =>{
                            this.toggleChildMenu();
                            this.handleContextMenuClose();
                        }}>Expand/Collapse</MenuItem>
                        <MenuItem key={"delete_dashboard"} onClick={() => {
                            this.props.deleteItem(this.props.linkObj.type, {"name": this.props.linkObj.name, "id": this.props.linkObj.id });
                            this.handleContextMenuClose()
                        }}>Delete Dashboard</MenuItem>
                    </Menu> : null
                }
            </div>
        )
    }
}