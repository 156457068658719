import React, {Component} from 'react';
import Typography from "@mui/material/Typography";
import {Dialog} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
    req_generic_delete_dataset,
    req_project_delete,
    req_api_delete,
    req_filter_delete,
} from "../../helpers/requests";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import DialogAppBar from "../dialogs/dialogAppBar";
import eventBus from "../../helpers/eventBus";



export default class PortalItemDelete extends Component {
    state = {
        open: this.props.open,
        allowDelete: false,
        loading: false,
        alert: { open: false, message: "", type: "" }
    }

    setOpen = (visible) => { this.setState({ open: visible }); }

    handleClose = () => {
        this.setOpen(false);
        this.props.close();
    };

    validateDeleteString = (e) => {
        if (e.target.value.toLowerCase() === "delete"){
            this.setState({allowDelete:true})
        } else {
            this.setState({allowDelete:false})
        }
    }

    deleteObject = () => {
        let self = this;
        if (this.props.itemType === "project") {
            req_project_delete(this.props.itemDetail.id).then(function (result) {
                if (result.status === 200) {
                    self.setState({ loading: false,
                        alert: {...{ "open": true, "message": "The project was deleted successfully.",
                                "type": "success", "timeout": 5000 }
                        }, open: false
                    });
                    self.props.confirmDelete("project", self.props.itemDetail.id);
                } else {
                    self.setState({ loading: false,
                        alert: { ...{ "open": true, "message": "There was an error deleting the project.",
                                "type": "error", "timeout": 5000 }
                        }, open: false
                    });
                }
                self.handleClose();
            })
        } else if (this.props.itemType === "dataset"){
            req_generic_delete_dataset(this.props.itemDetail.id).then(function(result) {
                if (result.status === 200){
                    self.setState({loading: false, alert: {...{ "open": true, "message": "The dataset was deleted successfully.", "type": "success", "timeout": 5000 }}, open: false});
                    self.props.confirmDelete("dataset", self.props.itemDetail.id);
                } else {
                    eventBus.dispatch("updateAssetMenu", {  });
                    self.setState({loading: false, alert: {...{ "open": true, "message": "There was an error deleting the dataset.", "type": "error", "timeout": 5000 }}, open: false});
                }
                self.handleClose();
            })
        } else if (this.props.itemType === "filter") {
            req_filter_delete(this.props.itemDetail.id).then(function(result) {
                if (result.status === 200){
                    self.setState({loading: false, alert: {...{ "open": true, "message": "The filter was deleted successfully.", "type": "success", "timeout": 5000 }}, open: false});
                    self.props.confirmDelete("filter", self.props.itemDetail.id);
                } else {
                    eventBus.dispatch("updateAssetMenu", {  });
                    self.setState({loading: false, alert: {...{ "open": true, "message": "There was an error deleting the filter.", "type": "error", "timeout": 5000 }}, open: false});
                }
                self.handleClose();
            })
        } else if (this.props.itemType === "apiwebhook") {
            req_api_delete(this.props.itemDetail.id).then(function(result) {
                if (result.status === 200){
                    self.setState({loading: false, alert: {...{ "open": true, "message": "The API was deleted successfully.", "type": "success", "timeout": 5000 }}, open: false});
                    self.props.confirmDelete("apiwebhook", self.props.itemDetail.id);
                } else {
                    eventBus.dispatch("updateAssetMenu", {  });
                    self.setState({loading: false, alert: {...{ "open": true, "message": "There was an error deleting the API.", "type": "error", "timeout": 5000 }}, open: false});
                }
                self.handleClose();
            })
        }
    }

    purgeAlert = () => { this.setState({alert:{...{ open: false, message: "", type: "" }} }) }

    render() {
        return (
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={ this.props && this.props.open ? this.props.open : this.state.open }
                    onClose={this.handleClose}
                    TransitionComponent={this.Transition}
                >
                    <DialogAppBar title="Delete Confirmation" close={this.handleClose} />

                    <Box sx={{padding: 2}}>
                        <Typography variant="body" gutterBottom component="div">
                            Are you sure you want to <b>delete the {this.props.itemType} "{this.props.itemDetail.name}"</b>?<br />
                        </Typography>

                        {this.props.itemType === 'dataset' ?
                            <Typography variant="body" gutterBottom component="div" color="error">
                                Deleting a dataset will result in the delete of the dataset's Filters and APIs.
                            </Typography> : null
                        }

                        {this.props.itemType === 'filter' ?
                            <Typography variant="body" gutterBottom component="div" color="error">
                                Deleting a filter will result in the delete of the filter's APIs.
                            </Typography> : null
                        }

                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button
                                color="error"
                                variant="outlined"
                                size="small"
                                type="submit"
                                onClick={(e) => {
                                    this.deleteObject()
                                }}
                            >Delete it!</Button>
                            <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={this.handleClose}>Cancel</Button>
                        </div>
                    </Box>
                </Dialog>
            </div>
        )
    }
}