import React, {useEffect} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import TokenIcon from '@mui/icons-material/Token';
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ShieldIcon from '@mui/icons-material/Shield';
import GavelIcon from '@mui/icons-material/Gavel';
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom";
import logo from "../../assets/SimpleDatasetBeta.png";
import PageSettings from "./pageSettings";
import PageTokens from "./pageTokens";
import PageAccount from "./pageAccount";
import PagePrivacy from "./pagePrivacy";
import PageTerms from "./pageTerms";
import {clearLogout} from "../../helpers/settings";
import MenuUser from "../menus/menuUser";

const drawerWidth = 240;

const Settings = (props) => {

    const navigate = useNavigate();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [page, setPage] = React.useState("Settings");
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const { subpage } = useParams();

    const handleLogout = () => {
        clearLogout()
        navigate("/");
    };

    useEffect(() => {
        if (subpage) {
            if (subpage === "account")
                setPage("Account");
            if (subpage === "tokens")
                setPage("Tokens");
            if (subpage === "privacy")
                setPage("Privacy Policy");
            if (subpage === "terms")
                setPage("Terms of Service");
        } else {
            setPage("Settings")
        }
    }, [subpage])

    const drawer = (
        <div>
            <Toolbar><Link style={{ my: 2, color: '#333', display: 'inline-block', textDecoration: "none", "fontWeight":"bold", paddingTop: "7px", paddingLeft: "10px" }} component={Link} to={'/' }><img src={logo} alt="Simple:Dataset Logo" height={22} /></Link></Toolbar>
            <Divider />
            <List>
                <ListItem key={"account"} disablePadding component={Link} to='/settings/account'>
                    <ListItemButton>
                        <ListItemIcon>
                            <ManageAccountsIcon sx={{fontSize: 20}}/>
                        </ListItemIcon>
                        <ListItemText style={{color: "#445566"}} primary={"Account"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={"tokens"} disablePadding component={Link} to='/settings/tokens'>
                    <ListItemButton>
                        <ListItemIcon>
                            <TokenIcon sx={{fontSize: 20}}/>
                        </ListItemIcon>
                        <ListItemText style={{color: "#445566"}} primary={"Tokens"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem key={"settings"} disablePadding component={Link} to='/settings'>
                    <ListItemButton>
                        <ListItemIcon>
                            <SettingsIcon sx={{fontSize: 20}}/>
                        </ListItemIcon>
                        <ListItemText style={{color: "#445566"}} primary={"Settings"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={"privacy"} disablePadding component={Link} to='/settings/privacy'>
                    <ListItemButton>
                        <ListItemIcon>
                            <ShieldIcon sx={{fontSize: 20}}/>
                        </ListItemIcon>
                        <ListItemText style={{color: "#445566"}} primary={"Privacy Policy"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={"terms"} disablePadding component={Link} to='/settings/terms'>
                    <ListItemButton>
                        <ListItemIcon>
                            <GavelIcon sx={{fontSize: 20}}/>
                        </ListItemIcon>
                        <ListItemText style={{color: "#445566"}} primary={"Terms of Service"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={"logout"} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <LogoutIcon sx={{fontSize: 20}}/>
                        </ListItemIcon>
                        <ListItemText onClick={handleLogout} style={{color: "#445566"}} primary={"Log Out"} />
                    </ListItemButton>
                </ListItem>

                <ListItem component={Link} to='/' key={"backtoportal"} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <ArrowBackIosIcon sx={{fontSize: 20}}/>
                        </ListItemIcon>
                        <ListItemText style={{color: "#445566"}} primary={"Back to Portal"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>

                            <Typography variant="h6" noWrap component="div" style={{paddingTop: "13px"}}>
                                {page}
                            </Typography>

                            <div style={{paddingTop: "2px"}}><MenuUser color={"white"} /></div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="Settings Pages"
                >

                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Box >
                        { page === "Settings" ? <PageSettings /> : null }
                        { page === "Tokens" ? <PageTokens /> : null }
                        { page === "Account" ? <PageAccount /> : null }
                        { page === "Privacy Policy" ? <PagePrivacy /> : null }
                        { page === "Terms of Service" ? <PageTerms /> : null }
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default Settings;



































