import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import BigTextPlain from "../editors/bigTextPlain";
import BigTextXML from "../editors/bigTextXML";
import BigTextMarkdown from "../editors/bigTextMarkdown";
import BigTextJSON from "../editors/bigTextJSON";

export default class EditorBaseDialog extends React.Component {
    state = {
        open: true,
        currentValue: this.props.value || "",
        saveDisabled: false
    }

    onChange = newState => {
        this.setState({editorState: newState})
    }

    save = (e) => {
        e.preventDefault();
        this.props.save(this.state.currentValue)
        this.setState({open: false})
    }
    close = () => {
        this.props.close()
        this.setState({open: false});
    }

    handleChange = (e) => {
        if ( this.props.column.fieldType === "object"){
            try{
                JSON.parse(e.target.value);
                this.setState({saveDisabled: false});
            }catch (e){
                this.setState({saveDisabled: true});
            }
        }

        this.setState({ currentValue: e.target.value});

    };

    render () {
        return (
            <Dialog open={this.state.open}
                    onClose={this.close}
                    style={{zIndex: 9999}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={this.props.column.format === "markdown" ? "md":"sm"}
                    onBackdropClick={(e) => {e.preventDefault();}}

            >
                <DialogTitle id="alert-dialog-title">
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    { this.props.column.format === "multiline" ?
                        <BigTextPlain value={this.props.value || ""} onChange={this.handleChange} /> : null
                    }
                    { this.props.column.format === "markdown" ?
                        <BigTextMarkdown value={this.state.currentValue || ""} onChange={(e) => {this.handleChange(e)}}/> : null
                    }
                    { this.props.column.format === "xml" ?
                        <BigTextXML onChange={this.handleChange} value={this.props.value || ""} /> : null
                    }
                    { this.props.column.fieldType === "object" ?
                        <div>
                        <BigTextJSON onChange={this.handleChange} value={this.props.value || "{}"} />
                        <div style={{color: "#dd0000", display: this.state.saveDisabled? "block":"none"}}>JSON object is not properly formatted.</div>
                        </div>
                        : null
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" size="small" disabled={this.state.saveDisabled} onClick={this.save}>Save</Button>
                    <Button variant="contained" size="small" onClick={() => { this.close()}}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

