import React, {} from 'react';
import Typography from "@mui/material/Typography";
import {ListItem} from "@mui/material";
import List from "@mui/material/List";
export const HLP_TEST = <React.Fragment>
                        <Typography color="inherit">Tooltip with HTML</Typography>
                        <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                        {"It's very engaging. Right?"}</React.Fragment>;

export const HLP_TOKEN_PERMITTED_ORIGINS = <React.Fragment>
    <Typography color="inherit">Permitted Origin Values</Typography>
    Origin values should be pattered as follows:
    <List dense style={{fontSize: ".8rem"}} >
        <ListItem style={{margin: "0px", padding: "2px", fontSize: ".8rem"}}>0.0.0.0: permits all incoming IPs</ListItem>
        <ListItem style={{margin: "0px", padding: "2px", fontSize: ".8rem"}}>98.123.180.12: a specific required origin IP</ListItem>
        <ListItem style={{margin: "0px", padding: "2px", fontSize: ".8rem"}}>*.adomain.com: requests from a specific TLD</ListItem>
        <ListItem style={{margin: "0px", padding: "2px", fontSize: ".8rem"}}>api.adomain.com: requests from a specific hostname</ListItem>
    </List>
    Origins not observing the above patterns will be ignored.

    </React.Fragment>;