import React, {Component} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import SnackbarInvoke from "../messaging/snackbarInvoke";
import {TextField} from "@mui/material";
import {req_projects_post, req_projects_get, req_projects_put} from "../../helpers/requests";
import BackdropAppear from "../staticDisplays/backdropAppear";
import DialogAppBar from "../dialogs/dialogAppBar";
import eventBus from "../../helpers/eventBus";

export default class ProjectDialog extends Component {

    state = {
        open: this.props.open || false,
        content: this.props.objectContent,
        loading: false,
        alert: { open: false, message: "", type: "" }
    };

    setOpen = (visible) => { this.setState({ open: visible }); }

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }

    handleClickOpen = () => { this.setOpen(true); };

    handleClose = () => {
        this.setOpen(false);
        this.props.close();
    };

    handleChange = (field, e) => {
        let content = this.state.content;
        content[field] = e.target.value;
        this.setState({ content: {...content} });
    };

    loadRec = (id) => {
        let self = this;
        self.setState({ loading: true });
        req_projects_get("project", id).then(function (result) {
            if (result.status === 200) {
                let rowrec = result;
                self.setState({content: rowrec.data.data, loading: false });
            } else {
                self.setState({ loading: false });
            }
        })
    }

    saveProjectObject = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const projectObject = {
            "name": this.state.content.name
        }
        let self = this;

        if (this.props.itemDetail && this.props.itemDetail.id){
            req_projects_put(this.props.itemDetail.id, projectObject).then(function (result) {
                if (result.status === 200) {
                    eventBus.dispatch("updateData", { itemType: "project"});
                    self.handleClose();
                    self.setState({
                        loading: false,
                        alert: {
                            ...{
                                "open": true, "message": "The project was updated successfully.",
                                "type": "success", "timeout": 5000
                            }
                        }
                    });
                } else {
                    self.handleClose();
                    self.setState({
                        loading: false,
                        alert: {
                            ...{
                                "open": true, "message": "There was a problem saving the project.",
                                "type": "error", "timeout": 5000
                            }
                        }
                    });
                }
            })
        } else {
            req_projects_post(projectObject).then(function (result) {

                if (result.status === 200 && !(result.data && result.data.status === "fail")) {
                    self.setState({
                        loading: false,
                        alert: {
                            ...{
                                "open": true, "message": "The project was created successfully.",
                                "type": "success", "timeout": 5000
                            }
                        }
                    });
                    eventBus.dispatch("updateData", { itemType: "project"});
                    self.handleClose();

                } else if (result && result.data && result.data.msg) {
                    self.setState({
                        loading: false,
                        alert: { ...{ "open": true, "message": result.data.msg, "type": "error", "timeout": 5000 }}
                    });
                } else {
                    self.setState({
                        loading: false,
                        alert: {
                            ...{
                                "open": true, "message": "There was a problem saving the project.",
                                "type": "error", "timeout": 5000
                            }
                        }
                    });
                }
            })
        }
    }

    componentDidMount() {
        if (this.props.expAdd){
            this.handleClickOpen();
        }

        if (this.props.type === "edit"){
            this.loadRec(this.props.itemDetail.id)
        }
    }

    Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        return(<div style={{"display":"inline-block"}}>
            <BackdropAppear loading={this.state.loading || false} />
            <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={this.Transition}
            >
                <DialogAppBar title={this.props.type === "edit" ? "Edit " + this.props.objectType: "New " + this.props.objectType} close={this.handleClose} />

                <Box sx={{padding: 2}} component="form" onSubmit={this.saveProjectObject}>
                    <TextField key={"project_name"}
                               sx={{marginBottom: 2}} fullWidth
                               label={"Project Name"}
                               value={this.state.content["name"] || ''}
                               required={true}
                               onChange={(e) => this.handleChange("name", e)}
                    />

                    <div align={"right"}>
                        <Button variant="contained" size="small" type="submit">Save</Button>
                        <Button variant="contained" size="small"  style={{marginLeft: "5px"}} onClick={this.handleClose}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>
        </div>)
    }
}
