import * as React from 'react';
import { useSearchParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import logo from "../../assets/SimpleDatasetBeta.png";
import {useEffect} from "react";
import ReactGA from "react-ga4";
import {req_get_verify_email} from "../../helpers/requests";

export default function Verify() {

    const [loading, setLoading] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [searchParams] = useSearchParams();
    const [registrationComplete, setRegistrationComplete] = React.useState(false);

    const verifyRegistration = (pid, verify) => {
        setShowError(false);
        setLoading(true);

        req_get_verify_email(pid, verify)
            .then(function (result) {
                if (result.status === 200) {
                    ReactGA.event({
                        category: "login events",
                        action: "registration_confirmation_success",
                        label: "in app"
                    });
                    setRegistrationComplete(true);
                    setShowError(false);
                    setLoading(false)
                } else {
                    setShowError(true);
                    setLoading(false)
                }
            })
    };

    useEffect(() => {

        const pid = searchParams.get("pid")
        const verify = searchParams.get("verify")
        if (pid && verify) {
            verifyRegistration(pid, verify)
        } else {
            setShowError(true);
        }
    },[])

    return (

        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        <div style={{marginTop: "40px", width: "100%"}}><img src={logo} alt="Simple:Dataset Logo" style={{ width: "95%", maxWidth: "500px"}} /></div>
        <div style={{ "display":"flex", "justifyContent":"space-around", "flexDirection":"row", marginTop: "10px", width: "95%", maxWidth: "500px", margin: "0 auto"}}>

            <div className="LoginConsole" style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", width: "100%"}}>

            <Container component="main" maxWidth="xs">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'main' }}>
                        <CheckOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Registration Verification
                    </Typography>

                    <Box sx={{ mt: 1 }}>
                        {(showError) ?
                            <Alert severity="error" style={{"width":"100%", "marginBottom": "10px"}}>Verification failed. Please click the link in your email again or copy and paste the link into the browser's address bar.</Alert> : null
                        }
                        <div style={{margin: "20px"}}>
                            {
                                registrationComplete ?
                                    <Typography variant="h6" gutterBottom>
                                        Registration verified! Go <Link href={"/home"}>here</Link> to log in!
                                    </Typography> : null
                            }
                        </div>
                        <Grid container>
                            <Grid item xs>
                                <Link href={"/home"} variant="body2">
                                    Login | Register
                                </Link>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Container>
            </div>
        </div>
        </div>
    );
}
