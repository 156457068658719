import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogAppBar from "./dialogAppBar";
import {TEMPLATE_DELETE_HEAD} from "../../helpers/strConstants";

export default class TemplateDeleteDialog extends React.Component {

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.props.open}
                onClose={this.props.close}
            >
                <DialogAppBar title={TEMPLATE_DELETE_HEAD} close={this.handleDeleteClose} />

                <Box sx={{padding: 2}}>
                    <Typography variant="body" gutterBottom component="div">
                        Are you sure you want to delete the template "{this.props.template.name}"?<br/>
                    </Typography>

                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button
                            color="error"
                            variant="outlined"
                            size="small"
                            type="submit"
                            onClick={() => {
                                this.props.delete(this.props.template.id)
                                this.props.close()
                            }}
                        >Delete it!</Button>
                        <Button variant="contained" size="small" style={{marginLeft: "10px"}}
                                onClick={() => { this.props.close() }}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>)
    }
}