import {TextField} from "@mui/material";
import TagInput from "./tagInput";
import React from "react";


export default class TemplateFieldsPanel extends React.Component {

    render() {

        return (
            <div>
                {
                    ["private_template", "community_template"].includes(this.props.content.createTemplate) && this.props.dataTab === 1 ?
                        <div style={{
                            marginTop: "5px",

                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <div style={{width: "50%", paddingRight: "5px", marginTop: "5px"}}>
                                <TextField key={"template_name"}
                                           sx={{marginBottom: 1}} fullWidth
                                           label={"Template Name"}
                                           value={this.props.content["templateName"]}
                                           required={true}
                                           onChange={(e) => this.props.handleChange("templateName", e)}
                                />
                            </div>
                            <div style={{width: "50%", paddingLeft: "5px"}}>

                                <TagInput
                                    selectedTags={this.props.handleTagSelect}
                                    fullWidth
                                    variant="outlined"
                                    id="tags"
                                    name="tags"
                                    placeholder="Add Tags"
                                    label="Template Tags"
                                />

                            </div>

                        </div>
                        : null
                }
                {
                    ["private_template", "community_template"].includes(this.props.content.createTemplate) && this.props.dataTab === 1 ?
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{width: "100%"}}>
                                <TextField
                                    multiline
                                    key={"template_notes"}
                                    sx={{marginBottom: 2, width: "100%"}}
                                    label={"Template Notes"}
                                    rows={2}
                                    value={this.props.content["templateNotes"]}
                                    required={true}
                                    onChange={(e) => this.props.handleChange("templateNotes", e)}
                                />
                            </div>
                        </div>
                        : null
                }
            </div>)
    }
}