import React, { useEffect } from 'react';
import PortalLeftNav from "../menus/portaLeftNav";
import PortalItemCreator from "./portalItemCreator";
import PortalItemDelete from "./PortalItemDelete";
import "./portal.css";
import PortalItemEditor from "./portalItemEditor";
import PortalTabs from "./portalTabs";
import {
    req_generic_get_dataset_list,
    req_get_api_list,
    req_get_filter_list,
    req_projects_get_list
} from "../../helpers/requests";
import MenuUser from "../menus/menuUser";
import {clearLogout} from "../../helpers/settings";
import PortalItemShare from "./portalItemShare";
import Listener from "../listener/listener";
import eventBus from "../../helpers/eventBus";

const handleDrawerClose = () => {}

const Portal = () => {
    let [tabCloser, setTabCloser] = React.useState(false);
    const [addItemOpen, setAddItemOpen] = React.useState(false);
    const [editItemOpen, setEditItemOpen] = React.useState(false);
    const [shareItemOpen, setShareItemOpen] = React.useState(false);
    const [addItemType, setAddItemType] = React.useState("");
    const [editItemType, setEditItemType] = React.useState("");
    const [shareItemType, setShareItemType] = React.useState("");
    const [addItemDetail, setAddItemDetail] = React.useState({});
    const [editItemDetail, setEditItemDetail] = React.useState({});
    const [shareItemDetail, setShareItemDetail] = React.useState({});
    const [deleteItemOpen, setDeleteItemOpen] = React.useState(false);
    const [deleteItemType, setDeleteItemType] = React.useState("");
    const [deleteItemDetail, setDeleteItemDetail] = React.useState({});
    const [rerenderMenu, setRerenderMenu] = React.useState(false);
    const [tabs, setTabs] = React.useState([]);
    const [tabIndex, setTabIndex] = React.useState("0");
    const [firstLoad, setFirstLoad] = React.useState(true);

    const handleChangeIndex = (e, newValue) => {
        if (newValue >= tabs.length) {
            if (parseInt(tabIndex) < tabs.length) {
                setTabIndex(tabIndex.toString());
            } else {
                setTabIndex((parseInt(newValue) - 1).toString());
            }
        } else {
            if (tabIndex > 0 && parseInt(newValue) >= 0 && tabCloser === true){
                setTabIndex((parseInt(tabIndex)-1).toString());
            } else {
                setTabIndex(newValue);
            }
        }
        setTabCloser(false);
    };

    useEffect(() => {
        let portalTabs = JSON.parse(localStorage.getItem("portalTabs")) || [];
        if (portalTabs.length === 0 && firstLoad) {
            portalTabs.push({
                name: "instructo!",
                itemType: "infopage",
                icon: "",
                detail: {id: 0, type: "infopage"}
            })
        }
        setFirstLoad(false);
        setTabs([...portalTabs])
        reloadObjectDataset("project")

        eventBus.on("updateAssetMenu", (uObj) => {

            reloadObjectDataset(uObj.itemType || "project")
        });
    }, [tabCloser])

    const addItem = (item, detail = null) => {
            setAddItemType(item);
            setAddItemDetail(detail);
            setAddItemOpen(true);
    }

    const editItem = (item, detail = null) => {
        setEditItemType(item);
        setEditItemDetail(detail);
        setEditItemOpen(true);
    }

    const shareItem = (item, detail = null) => {
        setShareItemType(item);
        setShareItemDetail(detail);
        setShareItemOpen(true);
    }

    const deleteItem = (item, detail = null) => {
        setDeleteItemType(item);
        setDeleteItemDetail(detail);
        setDeleteItemOpen(true);
    }

    const close = () => {
        setAddItemOpen(false);
        setEditItemOpen(false);
        setShareItemOpen(false);
    }

    const handleLogout = () => {
        clearLogout()
        window.location = '/';

    };

    const closeDelete = () => { setDeleteItemOpen(false); }

    const resetTabs = (objectType, objectItems) => {
        let newTabs = [];
        const openTabs = JSON.parse(localStorage.getItem("portalTabs"))
        if (openTabs.length > 1) {
            openTabs.forEach((openTab) => {
                if (openTab.detail.type !== objectType) {
                    newTabs.push(openTab)
                } else {
                    objectItems.data.data.forEach((objectItem) => {
                        if (objectItem.id === openTab.detail.id) {
                            if (objectType === "filter"){
                                openTab.name = objectItem.title;
                            } else {
                                openTab.name = objectItem.name;
                            }
                            newTabs.push(openTab)
                        }
                    })
                }
            })
            setTabs([...newTabs])
            localStorage.setItem("portalTabs", JSON.stringify(newTabs));
        }
    }

    const syncTabs = (objectType) => {
        const ds_pairs = {dataset: "ds_datasets", filter: "ds_filters", apiwebhook: "ds_apis"}
        const objectItems = JSON.parse(localStorage.getItem(ds_pairs[objectType]))
        resetTabs(objectType, objectItems)
    }

    const closeTab = (closeIndex) => {
        setTabCloser(true);
        tabCloser = true;
        let tArr = tabs;
        tArr.splice(closeIndex, 1);
        setTabs([...tArr]);
        localStorage.setItem("portalTabs", JSON.stringify(tArr));

        if (parseInt(closeIndex) === 0 && parseInt(tabIndex) === 0 && tArr.length > 0) {
            setTabIndex("0");
        } else if (parseInt(closeIndex) < parseInt(tabIndex)) {
            if (parseInt(tabIndex) <= tabs.length - 1)
                setTabIndex((parseInt(tabIndex)).toString());
            else
                setTabIndex("0");
        } else if (parseInt(closeIndex) === parseInt(tabIndex)) {
            if (parseInt(tabIndex-1) <= tabs.length - 1)
                setTabIndex((parseInt(tabIndex)-1).toString());
            else
                setTabIndex("0");
        } else if (parseInt(tabIndex) > tArr.length) {
            setTabIndex((tArr.length -1).toString());
        } else if (parseInt(tabIndex) < tArr.length) {
            setTabIndex((closeIndex).toString());
        }
    }

    const closeChildren = (itemType, itemId) => {
        let projects = itemType === 'project' ? [itemId] :[];
        let datasets = itemType === 'dataset' ? [itemId] :[];
        let filters = itemType === 'filter' ? [itemId] :[];
        let apis = itemType === 'apiwebhook' ? [itemId] :[];

        if (itemType === "project"){
            let datasets_ds = localStorage.getItem("ds_datasets") ? JSON.parse(localStorage.getItem("ds_datasets")) : {};
            if (datasets_ds.data){
                datasets_ds.data.data.forEach((dataset) => {
                    if (projects.includes(dataset.projectId)){ datasets.push(dataset.id) }
                })
                if (datasets.length > 0){
                    datasets.forEach((dataset) => { findCloseTab("dataset", dataset) })
                }
            }
        }

        if (["project", "dataset"].includes(itemType)){
            let filters_ds = localStorage.getItem("ds_filters") ? JSON.parse(localStorage.getItem("ds_filters")) : {};
            if (filters_ds.data){
                filters_ds.data.data.forEach((filter) => {
                    if (datasets.includes(filter.datasetId)){ filters.push(filter.id) }
                })
                if (datasets.length > 0){
                    filters.forEach((filter) => { findCloseTab("filter", filter) })
                }
            }
        }

        if (["project", "dataset", "filter"].includes(itemType)){
            let apis_ds = localStorage.getItem("ds_apis") ? JSON.parse(localStorage.getItem("ds_apis")) : {};
            if (apis_ds.data){
                apis_ds.data.data.forEach((api) => {
                    if (filters.includes(api.filterId)){ apis.push(api.id) }
                })
                if (apis.length > 0){
                    apis.forEach((api) => { findCloseTab("apiwebhook", api) })
                }
            }
        }
    }

    const confirmDelete = (itemType, itemId) =>{
        closeChildren(itemType, itemId)
        findCloseTab(itemType, itemId)
    }

    const findCloseTab = (itemType, itemId) => {
        let tArr = tabs;
        let existingIndex = null;
        tArr.forEach((tab, ind) => {
            if (tab.itemType === itemType && tab.detail.id === itemId) {
                existingIndex = ind;
            }
        });
        if (existingIndex){
            closeTab(existingIndex);
        }
    }

    const reloadObjectDataset = (itemType) => {

        if (["project"].includes(itemType)) {
            req_projects_get_list("project").then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("ds_projects", JSON.stringify(result));
                    syncTabs("project")
                }
            })
        }
        if (["project", "dataset"].includes(itemType)) {
            req_generic_get_dataset_list().then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("ds_datasets", JSON.stringify(result));
                    syncTabs("dataset")
                } else {
                    // force portal reload
                }
            })
        }
        if (["project", "dataset", "filter"].includes(itemType)) {
            req_get_filter_list().then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("ds_filters", JSON.stringify(result));
                    syncTabs("filter")
                } else {
                    // force portal reload
                }
            })
        }
        if (["project", "dataset", "filter", "apiwebhook"].includes(itemType)) {
            req_get_api_list("api").then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("ds_apis", JSON.stringify(result));
                    syncTabs("apiwebhook")
                } else {
                    // force portal reload
                }
            })
        }
    }

    const openItem = (itemType, itemDetails) => {
        let existingIndex = null;
        let tArr = tabs;
        tArr.forEach((tab, ind) => {
            if (tab.detail.type === itemType && tab.detail.id === itemDetails.id) {
                existingIndex = ind;
            }
        })

        if (existingIndex !== null){
            setTabIndex((existingIndex).toString());
        } else {
            tArr.push({
                name: itemDetails.name,
                itemType: itemType,
                icon: "",
                detail: {id: itemDetails.id, type: itemType}
            });
            setTabs([...tArr]);
            setTabIndex((tArr.length -1).toString());
        }
        localStorage.setItem("portalTabs", JSON.stringify(tArr));
    }

    const refreshMenu = (rerenderState, detailObj) => {
        setRerenderMenu(rerenderState);
        if (detailObj){
            openItem(detailObj.type, {"name":detailObj.name, "id": detailObj.id });
            reloadObjectDataset(detailObj.type);
        }
    }

    return (
    <div className="portal" style={{border: 0}}>
        <div style={{height: "42px", textAlign: "right", paddingRight: "15px"}}>
            <MenuUser />
        </div>
        <div style={{"display":"flex", "justifyContent":"space-around", "flexDirection":"row"}}>
            <div>
                <PortalLeftNav handleDrawerClose={handleDrawerClose} addItem={addItem} deleteItem={deleteItem} openItem={openItem} editItem={editItem} shareItem={shareItem} theme={{}} open={true} refresh={refreshMenu} rerenderState={rerenderMenu} handleLogout={handleLogout} />
            </div>
            <PortalTabs tabIndex={tabIndex}
                        handleChangeIndex={handleChangeIndex}
                        tabs={tabs}
                        openItem={openItem}
                        addItem={addItem}
                        closeTab={closeTab}
                        refreshMenu={refreshMenu}/>
        </div>
        <PortalItemCreator itemType={addItemType} itemDetail={addItemDetail} open={addItemOpen} openItem={openItem} close={close} refresh={refreshMenu} />
        <PortalItemEditor itemType={editItemType} itemDetail={editItemDetail} open={editItemOpen} openItem={editItem} close={close} refresh={refreshMenu} />
        <PortalItemShare itemType={shareItemType} itemDetail={shareItemDetail} open={shareItemOpen} openItem={shareItem} close={close} refresh={refreshMenu} />
        <PortalItemDelete itemType={deleteItemType} itemDetail={deleteItemDetail} open={deleteItemOpen} confirmDelete={confirmDelete} close={closeDelete} />
        <Listener />
    </div>
)}

export default Portal;
