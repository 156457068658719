import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import DialogAppBar from "./dialogAppBar";
import {DATASET_DELETE_COLUMN_HEAD} from "../../helpers/strConstants";

export default class ColumnDeleteDialog extends React.Component {

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.props.open}
                onClose={this.props.close}
            >
                <DialogAppBar title={DATASET_DELETE_COLUMN_HEAD} close={this.handleDeleteClose} />

                <Box sx={{padding: 2}}>
                    <Typography variant="body" gutterBottom component="div">
                        Are you sure you want to delete the column "{this.props.column}" from this dataset? Deleting a column will also remove the
                        column from filters and APIs dependent on this dataset. Once deleted, this action cannot be reverted.<br/>
                    </Typography>

                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button
                            color="error"
                            variant="outlined"
                            size="small"
                            type="submit"
                            onClick={() => {
                                this.props.delete(this.props.column.id)
                                this.props.close()
                            }}
                        >Delete it!</Button>
                        <Button variant="contained" size="small" style={{marginLeft: "10px"}}
                                onClick={() => { this.props.close() }}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>)
    }
}

