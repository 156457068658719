import React, {Component} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import SnackbarInvoke from "../messaging/snackbarInvoke";
import {TextField} from "@mui/material";
import {req_membership_update_post, req_update_account_name} from "../../helpers/requests";
import BackdropAppear from "../staticDisplays/backdropAppear";
import DialogAppBar from "../dialogs/dialogAppBar";

export default class AccountChangeName extends Component {

    state = {
        open: this.props.open,
        loading: false,
        alert: { open: false, message: "", type: "" },
        accountName: ""
    };

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }
    handleClose = () => {
        this.setState({accountName: this.props.name })
        this.props.close();
    };
    handleChange = (e) => { this.setState({ accountName: e.target.value }) };

    saveAccountName = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const body = { "name": this.state.accountName }
        let self = this;
        req_update_account_name(body).then(function (result) {
            if (result.status === 200) {
                self.props.close();
                self.setState({ loading: false });
            } else {
                console.log("there was an error")
                self.setState({ loading: false });
            }
        })
    }

    componentDidMount() { }

    Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        return(<div style={{"display":"inline-block"}}>
            <BackdropAppear loading={this.state.loading || false} />
            <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.props.open}
                onClose={this.handleClose}
                TransitionComponent={this.Transition}
            >
                <DialogAppBar title="Edit Account Name" close={this.handleClose} />
                <Box sx={{padding: 2}} component="form" onSubmit={this.saveAccountName}>
                    <TextField key={"account_name"}
                               sx={{marginBottom: 2}} fullWidth
                               label={"Account Name"}
                               value={this.state.accountName || this.props.name}
                               required={true}
                               onChange={(e) => this.handleChange(e)}
                    />

                    <div align={"right"}>
                        <Button variant="contained" size="small" type="submit">Save</Button>
                        <Button variant="contained" size="small"  style={{marginLeft: "5px"}} onClick={this.handleClose}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>
        </div>)
    }
}
