import React from 'react';
import ReactGA from 'react-ga4';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter} from "react-router-dom";
import DynamicRoutes from "./routes/dynamicRoutes";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "./components/theme/theme"
import CookieConsent from "react-cookie-consent";
import './App.css';
import Box from "@mui/material/Box";
import PrivacyPolicy from "./components/content/privacyPolicy";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Link from "@mui/material/Link";
import DialogAppBar from "./components/dialogs/dialogAppBar";
const TRACKING_ID = process.env.REACT_APP_GA_ID1; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false);
    const handlePPClose = () => { setPrivacyPolicyOpen(false) }
    return (
      <BrowserRouter>
          <ThemeProvider theme={theme}>

        <div className="App" style={{display: "flex", height: "100%", flexDirection: 'column'}}>
            <CssBaseline />
            <div style={{ paddingLeft: "0", height: "100%", border: "0" }}>
                <DynamicRoutes />
                <CookieConsent
                    location="bottom"
                    buttonText="Acknowledge"
                    cookieName="simpleDatasetConfirm"
                    style={{ background: "#234", fontSize: "1.7rem", height: "200px" }}
                    buttonStyle={{ backgroundColor: "#678", color: "#fff", fontSize: ".8rem", padding: "10px", borderRadius: "3px" }}
                    expires={150}
                >
                    This website uses cookies to enhance the user experience.{" "}
                    <br />
                    <span style={{ fontSize: ".9rem" }}>Further information on how cookies are used can be found here: <Link
                        variant="body2"
                        style={{color: "#fff"}}
                        onClick={() => {
                            setPrivacyPolicyOpen(true);
                        }}
                    >Privacy Policy</Link>
                    </span>
                </CookieConsent>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={ privacyPolicyOpen }
                onClose={handlePPClose}

            >
                <DialogAppBar title="Privacy Policy" close={handlePPClose} />

                <Box sx={{padding: 2}}>
                    <PrivacyPolicy />
                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={handlePPClose}>Close</Button>
                    </div>
                </Box>
            </Dialog>
        </div>
          </ThemeProvider>
      </BrowserRouter>
    );
}

export default App;
