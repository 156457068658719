import React, {Component} from "react";
import {TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";


export default class ApiFieldPanel extends Component {

    render() {
        return (
            <div>
                <div style={{marginTop: "10px", display: "flex", flexDirection: "row", width: "auto"}}>
                    <div style={{width: "50%", paddingRight: "5px"}}>
                        <TextField key={"field_API_name"}
                                   sx={{marginBottom: 2}} fullWidth
                                   label={"API Name"}
                                   value={this.props.currentState.content["name"] || ''}
                                   required={true}
                                   onChange={(e) => this.props.fieldChange("name", e)}
                        />
                    </div>
                    <div style={{width: "50%", paddingLeft: "5px"}}>
                        <TextField key={"field_API_slug"}
                                   sx={{marginBottom: 2}} fullWidth
                                   label={"API Endpoint"}
                                   value={this.props.currentState.content["slug"] || ''}
                                   error={this.props.currentState.slugError}
                                   required={true}
                                   helperText={this.props.currentState.slugError ? "This endpoint is already in use. Please choose another" : ""}
                                   onChange={(e) => {
                                       this.props.fieldChange("slug", e)
                                   }}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "auto"}}>

                    <div >
                        {this.props.currentState.filters  ?
                            <Autocomplete
                                id="filter_select"
                                options={this.props.currentState.filters}
                                autoHighlight
                                filterSelectedOptions
                                value={this.props.currentState.currentFilterRec || null}
                                required
                                onChange={(e, newValue) => {
                                    this.props.selectFilter(e, newValue);
                                    /*
                                    let content = this.props.currentState.content;
                                    content.currentFilter = newValue.id;
                                    this.setState({
                                        content: content,
                                        currentFilterRec: newValue,
                                        currentFilter: newValue.id
                                    });
                                    */

                                }}
                                getOptionLabel={(option) => option.title}

                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.title}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Dataset Filter to Access"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        required
                                    />
                                )}
                            /> : null
                        }
                    </div>
                    <div >
                        <TextField key={"field_Description"}
                                   sx={{marginBottom: 1}} fullWidth
                                   label={"Description"}
                                   value={this.props.currentState.content["description"] || ''}
                                   required={true}
                                   onChange={(e) => this.props.fieldChange("description", e)}
                        />
                    </div>
                </div>


            </div>

        )
    }
}