
const baseObj = {
    "fieldId": null,
    "type": "",
    "label": "",
    "format": "",
    "required": false,
    "error": false,
    "errorMessage": "",
    "fieldName": "",
    "fieldType": ""
};

export class FormObject{

    constructor(fieldId){
        this.fieldId = fieldId;
        this.dObj = {...baseObj};
        this.dObj.fieldId = fieldId;
    }

    updateObj = (key, val) => {

        if (key === "required") {
            this.dObj[key] = (val === true || val === "true");
        } else if (key === "type" && val === "date") {
            this.dObj["type"] = "string";
            this.dObj["format"] = "date";
        } else if (key === "type" && val === "number") {
            this.dObj["type"] = "number";
        }
        else {
            this.dObj[key] = val;
        }
    }

    resetObjBase = (fieldType) => {
        if (["decimal","int"].includes(fieldType)) {
            this.updateObj("fieldType", fieldType);
            this.updateObj("type", "number");
        } else if (["boolean","string", "enum", "object"].includes(fieldType)){
            this.updateObj("fieldType", fieldType);
            this.updateObj("type", fieldType);
        } else if (["date","datetime", "time"].includes(fieldType)){
            this.updateObj("fieldType", "string");
            this.updateObj("type", "string");
            this.updateObj("format", "date");
            this.updateObj("dateFormat", fieldType);
        }
    }

    removeExtraneousFields = () => {
        let tempObj = {...this.dObj};
        for (const [key,] of Object.entries(this.dObj)){
            if (!(key in baseObj))
                delete tempObj[key];
        }
        this.dObj = {...tempObj}
    }

    clearError = () => {
        this.dObj.error = false;
        this.dObj.errorMessage = "";
    }

    convertToPropertyObject(sortOrder=null){
        let base = this.dObj;
        delete base["error"];
        delete base["errorMessage"];
        delete base["required"];
        base["label"] = base["label"] ? base["label"] : base["fieldName"];
        if (sortOrder != null)
            base["sortOrder"] = sortOrder;

        base = this.coerceObj(base);

        return base;
    }

    coerceObj(dObj){

        if (dObj.type === "string" && dObj.format === "date"){
            dObj.dateFormat = dObj.dateFormat ? dObj.dateFormat : "datetime";
            dObj.defaultCurrentDate = dObj.defaultCurrentDate ? dObj.defaultCurrentDate : false;
            return dObj;
        }

        else if (dObj.type === "decimal"){
            dObj.decimalValues = dObj.decimalValues ? parseInt(dObj.decimalValues) : null;
            dObj.round = dObj.round ? dObj.round : "no";
            return dObj;
        }

        else if (dObj.type === "int"){
            dObj.minimum = dObj.minimum ? dObj.minimum : null;
            dObj.maximum = dObj.maximum ? dObj.maximum : null;

            if ((dObj.minimum && dObj.maximum) && (dObj.minimum > dObj.maximum)){
                dObj["error"] = true;
                dObj["errorMessage"] = "Minimum integer cannot be larger than maximum.";
            }
            return dObj;
        }

        else if (dObj.type === "string"){
            dObj.format = dObj.format ? dObj.format : "none";
            return dObj;
        }

        else if (dObj.type === "enum"){
            dObj.format = dObj.format ? dObj.format : "none";
            delete dObj["type"];
            return dObj;
        }

        else if (dObj.type === "boolean"){

            if (dObj.default && ((dObj.default.toString()).toLowerCase() === "false" || dObj.default === false))
                dObj.default = false;
            else if (dObj.default && ((dObj.default.toString()).toLowerCase() === "true" || dObj.default === true))
                dObj.default = true;
            else {
                dObj.default = false;
            }
            return dObj;
        }

        else {
            return dObj;
        }
    }

}