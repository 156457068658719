import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/compat/database";

const config = {
    apiKey: process.env.REACT_APP_STR_FIRE_API_KEY,
    authDomain: process.env.REACT_APP_STR_FIRE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_STR_FIRE_DATABASE_URL
};

firebase.initializeApp(config);

export const auth = firebase.auth;
export const db = firebase.database();