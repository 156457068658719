import React, {Component} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default class SnackbarInvoke extends Component {
    render() {
        let showType = this.props.alertObj.type ? this.props.alertObj.type : "info";
        return(
            <Snackbar
                open={this.props.alertObj.open}
                autoHideDuration={this.props.alertObj.timeout}
                onClose={this.props.purgeAlert}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert severity={showType} sx={{ width: '100%' }}>
                    {this.props.alertObj.message}
                </Alert>
            </Snackbar>

        )
    }
}
