import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import {
    Dialog,
    DialogTitle,
    FormControl,
    FormControlLabel, FormHelperText,
    FormLabel,
    InputLabel, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
    RadioGroup,
    Select
} from "@mui/material";
import { Container, Draggable } from "react-smooth-dnd";
import {arrayMoveMutable} from "array-move";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from "@mui/material/IconButton";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Radio from '@mui/material/Radio';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export default class FormFieldCreator extends Component {

    state = {
        fieldName: "",
        fieldType: "",
        hasError: this.props.hasError,
        errorMessage: this.props.errorMessage,
        fieldRequired: false,
        fieldObj: this.props.fieldObj,
        openOptions: false,
        optionItems: [],
        currentOptionValue: ""
    }

    updateFieldCreator = (e, key, ftype=null) => {
        let val = "";
        if (ftype === "boolean") {
            val = e.target.value === "true";
        } else {
            val = e.target.value;
        }

        if (key === "type" && ["decimal","int"].includes(val)){
            this.props.fieldObj.removeExtraneousFields();
            let dObj = this.props.fieldObj.dObj;
            dObj["type"] = "number";
            dObj["fieldType"] = val;
            dObj["format"] = "";
            this.props.fieldObj.dObj = {...dObj};

        } else if (key === "type" && ["date","datetime","time"].includes(val)){
            this.props.fieldObj.removeExtraneousFields();
            let dObj = this.props.fieldObj.dObj;
            dObj["type"] = "string";
            dObj["fieldType"] = "string";
            dObj["format"] = "date";
            this.props.fieldObj.dObj = {...dObj};
        } else if (key === "type" && val === "boolean") {
            this.props.fieldObj.removeExtraneousFields();
            this.props.fieldObj.updateObj("type", val);
            this.props.fieldObj.updateObj("fieldType", "boolean");
            this.props.fieldObj.updateObj("format", "");
            this.props.fieldObj.updateObj("default", false);
        } else if (key === "type" && val === "Select a Field Type") {
            this.props.fieldObj.removeExtraneousFields();
            this.props.fieldObj.updateObj("fieldType", "");
            this.props.fieldObj.updateObj("format", "");
            this.props.fieldObj.updateObj("type", "Select a Field Type");
        } else if (key === "type" && val === "string") {
            this.props.fieldObj.removeExtraneousFields();
            this.props.fieldObj.updateObj("type", val);
            this.props.fieldObj.updateObj("fieldType", "string");
            this.props.fieldObj.updateObj("format", "");
        } else if (key === "type" && val === "object") {
            this.props.fieldObj.removeExtraneousFields();
            this.props.fieldObj.updateObj("type", val);
            this.props.fieldObj.updateObj("fieldType", "object");
            this.props.fieldObj.updateObj("format", "");
        } else if (key === "type" && val === "enum") {
            this.props.fieldObj.removeExtraneousFields();
            this.props.fieldObj.updateObj("type", "enum");
            this.props.fieldObj.updateObj("fieldType", "enum");
            this.props.fieldObj.updateObj("format", "");
        } else if (key === "required" ) {
            this.props.fieldObj.updateObj("required", e.target.checked);
        }
        else {
            if (key !== "type" && key !== "fieldType") {
                this.props.fieldObj.updateObj(key, val);
            }
        }

        this.props.onChange(this.props.fieldObj.dObj.fieldId);
    }

    updateOptions = () => {
        //map of options
        let keys = []
        this.state.optionItems.map((item) => {return ( keys.push(item["text"]) )});
        this.props.fieldObj.updateObj("enum", keys);
        this.props.onChange(this.props.fieldObj.dObj.fieldId);
    }

    openAddOptions = () => { this.setState({"openOptions": true}); }

    handleCloseOptions = () => { this.setState({"openOptions": false}); }

    addOptionValue = (event) => {

        let listItems = this.state.optionItems;
        let newVal = 0;
        listItems.forEach((item) => { if (item.id >= newVal){ newVal = item.id + 1; } })
        listItems.push({ id: newVal, text: this.state.currentOptionValue })
        this.setState({"optionItems": [...listItems]})
        this.setState({"currentOptionValue": ""})
        this.updateOptions();
    }

    deleteOptionValue = (id) => {
        let listItems = [];
        this.state.optionItems.forEach((item) => {
            if (parseInt(item.id) !== parseInt(id)){ listItems.push(item) }
        });
        this.setState({"optionItems": [...listItems]});
        this.updateOptions();
    }

    onDrop = ({ removedIndex, addedIndex }) => {

        this.setState({"items": arrayMoveMutable(this.state.optionItems, removedIndex, addedIndex)})
        //setItems(items => arrayMoveImmutable(items, removedIndex, addedIndex));
        this.updateOptions();
    };

    getFieldType(dObj){
        if (dObj.type === "number"){
            return dObj.fieldType;
        } else if (dObj.type && dObj.format !== "date"){
            return dObj.type;
        } else if (dObj.type && dObj.format === "date"){
            return "date";
        } else {
            return "";
        }
    }

    render() {

        return(
            <div style={{
                display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px",
                border: "1px solid #ccc",
                padding: "10px",
                paddingBottom: 0,
                borderRadius: "3px",
                background: "#eee"
            }}>
                <div>
                    <TextField key={""}
                               size={"small"}
                               fieldkey={""}
                               fieldtype={"string"}
                               error={this.props.fieldObj.dObj.error}
                               helperText={this.props.fieldObj.dObj.errorMessage}
                               value={this.props.fieldObj.dObj.fieldName}
                               fullWidth
                               label={"Field Name"}
                               onChange={(e) => {
                                   this.updateFieldCreator(e, "fieldName");
                               }}
                    />
                </div>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id={"field_type_label_" + this.props.fieldObj.dObj.fieldId }>Field Type</InputLabel>
                        <Select
                            size={"small"}
                            labelId={"field_type_label_" + this.props.fieldObj.dObj.fieldId }
                            id={"field_type_label_" + this.props.fieldObj.dObj.fieldId }
                            defaultValue="Select a Field Type"
                            error={this.props.fieldObj.dObj.typeError}
                            value={
                                this.getFieldType(this.props.fieldObj.dObj)
                            }
                            label="Field Type"
                            style={{width: "200px"}}

                            onChange={(e) => {
                                this.updateFieldCreator(e, "type");
                            }}
                        >
                            <MenuItem value="date">Date & Time</MenuItem>
                            <MenuItem value="decimal">Decimal (ex: 1.2)</MenuItem>
                            <MenuItem value="int">Integer (ex: 1, 2, 3)</MenuItem>
                            <MenuItem value="string">Text String</MenuItem>
                            <MenuItem value="boolean">True/False</MenuItem>
                            <MenuItem value="enum">Option List</MenuItem>
                            <MenuItem value="object">JSON Object</MenuItem>
                        </Select>
                        <FormHelperText style={{color: '#D14343'}}>{this.props.fieldObj.dObj.typeErrorMessage ? this.props.fieldObj.dObj.typeErrorMessage : ""}</FormHelperText>
                    </FormControl>
                </div>
                <div style={{width: "200px"}}>
                    {
                        (this.props.fieldObj.dObj.type === "string" && this.props.fieldObj.dObj.format !== "date") ?
                            <FormControl fullWidth>
                                <InputLabel id="field_formatting_label">Formatting Rules</InputLabel>
                                <Select
                                    size={"small"}
                                    labelId={"field_formatting_label_" + this.props.fieldObj.dObj.fieldId}
                                    id={"field_formatting_label_" + this.props.fieldObj.dObj.fieldId}
                                    defaultValue="none"
                                    label="Formatting Rules"
                                    style={{minWidth: "200px"}}
                                    onChange={(e) => {
                                        this.updateFieldCreator(e, "format");
                                    }}
                                >
                                    <MenuItem value="none">None</MenuItem>
                                    <MenuItem value="multiline">Multiline (big text)</MenuItem>
                                    <MenuItem value="markdown">Markdown</MenuItem>
                                    <MenuItem value="xml">*ML (HTML/XML)</MenuItem>
                                    <MenuItem value="email">Email Address</MenuItem>
                                    <MenuItem value="phone">Phone</MenuItem>
                                    <MenuItem value="url">URL</MenuItem>
                                </Select>
                            </FormControl>: null
                    }
                    {
                        (this.props.fieldObj.dObj.type === "number" && this.props.fieldObj.dObj.fieldType === "int") ?
                            <FormControl fullWidth>
                                <div/>
                            </FormControl>: null
                    }

                    {
                        (this.props.fieldObj.dObj.type === "object") ?
                            <FormControl fullWidth>
                                <div/>
                            </FormControl>: null
                    }

                    {
                        (this.props.fieldObj.dObj.type === "boolean") ?
                            <FormControl fullWidth>
                                <FormLabel style={{fontSize: ".8rem"}} id="default-radio-buttons-group-label">Default Selection</FormLabel>
                                <RadioGroup
                                    size={"small"}
                                    row
                                    aria-labelledby="bool-row-radio-buttons-group-label"
                                    name="bool-row-radio-buttons-group"
                                    error={this.props.fieldObj.dObj.contextError}
                                    onChange={(e) => {
                                        this.updateFieldCreator(e, "default", "boolean");
                                    }}
                                >
                                    <FormControlLabel size={"small"} value={false}
                                                      checked={!this.props.fieldObj.dObj.default || this.props.fieldObj.dObj.default === false} control={<Radio />} label="False"  />
                                    <FormControlLabel size={"small"} value={true}
                                                      checked={this.props.fieldObj.dObj.default === true} control={<Radio />} label="True" />
                                </RadioGroup>
                            </FormControl>: null
                    }
                    {
                        (this.props.fieldObj.dObj.type === "number" && this.props.fieldObj.dObj.fieldType === "decimal") ?
                            <FormControl fullWidth>
                                <div/>
                            </FormControl>: null
                    }
                    {
                        (this.props.fieldObj.dObj.type === "string" && this.props.fieldObj.dObj.format === "date") ?
                            <div>
                            <FormControl >
                                <InputLabel id="field_formatting_label_date">Formatting Rules</InputLabel>
                                <Select
                                    size={"small"}
                                    labelId="field_formatting_label_date"
                                    id="field_formatting_label_date"
                                    defaultValue="datetime"
                                    label="Formatting Rules"
                                    style={{minWidth: "200px"}}
                                    value={ this.props.fieldObj.dObj.dateFormat || "" }
                                    onChange={(e) => {
                                        this.updateFieldCreator(e, "dateFormat");
                                    }}
                                >
                                    <MenuItem value="datetime">Date + Time</MenuItem>
                                    <MenuItem value="date">Date Only</MenuItem>
                                    <MenuItem value="time">Time Only</MenuItem>
                                </Select>
                            </FormControl>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size={"small"}
                                                style={{"fontSize":".8rem"}}
                                                checked={this.props.fieldObj.dObj.defaultCurrentDate || false}
                                                color="primary"
                                                value={!this.props.fieldObj.dObj.defaultCurrentDate}
                                                onChange={(e) => {
                                                    this.updateFieldCreator(e, "defaultCurrentDate", "boolean");
                                                }}
                                            />
                                        }
                                        label={<span style={{ fontSize: '.8rem' }} >Current Date as Default?</span>}
                                        style={{"fontSize":".8rem"}}
                                    />
                                </FormControl>
                            </div>: null
                    }

                    {
                        (this.props.fieldObj.dObj.type === "enum") ?
                            <div>
                            <FormControl fullWidth>
                                <Button
                                    size="small"
                                    variant="contained"
                                    aria-label="open add options"
                                    onClick={() => {this.openAddOptions(this.props.fieldObj.dObj.fieldId) }}
                                    endIcon={<AddBoxIcon />}>
                                    Add/Edit Options
                                </Button>
                            </FormControl>
                                <div>
                                    {this.state.optionItems.map(({id, text}) => (
                                        <div style={{"marginTop": "2px", "border":"1px solid #ccc", "padding":"3px","fontSize":".9em"}}>{text}</div>
                                        ))
                                    }
                                </div>
                            </div>: null
                    }

                </div>
                {(this.props.fieldObj.dObj.type !== "boolean") && this.props.deactivateRequired !== true ?
                    <div>
                        <FormLabel id="required-radio-buttons-group-label"
                                   style={{fontSize: ".8rem"}}>Required?</FormLabel>
                        <Checkbox
                            size={"small"}
                            checked={this.props.fieldObj.dObj.required}
                            color="primary"
                            onChange={(e) => {
                                this.updateFieldCreator(e, "required", "boolean");
                            }}
                        /></div> : <div style={{width: "95px"}}>&nbsp;</div>
                }
                <div>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="delete dataset"
                        onClick={() => {this.props.handleCreatorDelete(this.props.fieldObj.dObj.fieldId) }}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </div>


                <Dialog onClose={this.handleCloseOptions} open={this.state.openOptions}>
                    <div style={{"padding": "10px", "listStyleType": "none", "width": "390px"}}>

                        <DialogTitle>Add Selector List Options Below</DialogTitle>
                            <Box>
                                <TextField
                                    style={{"width": "80%"}}
                                    required
                                    label="New Option Value"
                                    id="optionValue"
                                    name="optionValue"
                                    value={this.state.currentOptionValue}
                                    onChange={ (e) => this.setState({"currentOptionValue": e.target.value})}
                                /><Button onClick={this.addOptionValue}
                                          style={{"margin":"5px"}}
                                          variant="contained">Add</Button>
                            </Box>
                        <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={this.onDrop} style={{"listStyleType": "none","marginBottom":"10px"}}>
                            {this.state.optionItems.map(({ id, text }) => (

                            <Draggable key={id}>
                                <ListItem style={{"padding":"3px", "border":"1px solid #eee", "marginTop": "1px", "marginBottom": "1px"}}>
                                    <div style={{"display":"flex", "justifyContent": "space-between", "width": "100%"}}>
                                        <div>
                                            <ListItemSecondaryAction style={{"left":"0"}}>
                                                <ListItemIcon className="drag-handle">
                                                    <DragIndicatorIcon />
                                                </ListItemIcon>
                                            </ListItemSecondaryAction>
                                        </div>
                                        <div style={{"width":"60%"}}>
                                            <ListItemText primary={text} />
                                        </div>
                                        <div>
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="delete"
                                                onClick={() => { this.deleteOptionValue(id) }}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </div>
                                    </div>

                                </ListItem>
                            </Draggable>
                            ))}


                        </Container>
                        <div style={{"textAlign":"right"}}>
                            <Button
                                onClick={(e) => {
                                    this.handleCloseOptions();
                                }}
                            >Close</Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}
