import React, {Component} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import {req_filter_post} from "../../helpers/requests";
import {TextField} from "@mui/material";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import BackdropAppear from "../staticDisplays/backdropAppear";
import AutocompleteSelect from "../formControls/AutocompleteSelect";
import {openOnCreate} from "../../helpers/settings";
import DialogAppBar from "../dialogs/dialogAppBar";

export default class FilterDialog extends Component {

    state = {
        open: this.props.open || false,
        content: {},
        datasets: [],
        currentDataset: this.props && this.props.itemDetail && this.props.itemDetail.dataset ? this.props.itemDetail.dataset : "Select a Dataset",
        currentDatasetRec: null,
        loading: false,
        alert: { open: false, message: "", type: "" }
    };

    setOpen = (visible) => { this.setState({ open: visible }); }

    handleClickOpen = () => { this.setOpen(true); };

    handleClose = () => {
        this.setOpen(false);
        this.props.close();
    };

    handleChange = (field, e) => {
        let content = this.state.content;
        content[field] = e.target.value;
        this.setState({ content: {...content}});
    };

    loadDatasets = () => {
        let self = this;
        this.setState({ loading: true });
        let datasets = JSON.parse(localStorage.getItem("ds_datasets"));

        let rowrecs = [];
        datasets.data.data.forEach(function (record) {
            if (record.projectId) {
                rowrecs.push(record);
            }
        });
        self.setState({datasets: [...rowrecs], loading: false});
        if (self.props.itemDetail && self.props.itemDetail.dataset) {
            rowrecs.forEach(dataset => {
                if (dataset.collectionName === self.props.itemDetail.dataset) {
                    self.setState({"currentDatasetRec": dataset})
                }
            })
        }
    }

    selectDataset = (e, newValue) => {
        this.setState({showQueryEditor: false});
        let set = false;
        this.state.datasets.forEach(dataset => {
            if (newValue && (dataset.collectionName === newValue.id) && !set) {
                this.setState({"currentDataset": newValue.id, "currentDatasetRec": dataset})
                set = true;
            }
        })
    }

    componentDidMount() {
        this.loadDatasets();
        if (this.props.expAdd){
            this.handleClickOpen();
        }
    }

    saveFilter = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const pKeys = []
        for (const [, value] of Object.entries(this.state.currentDatasetRec.schema.properties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])) {
            pKeys.push(value.fieldName)
        }
        let body = {
            "datasetId": this.state.currentDataset,
            "title": this.state.content.title,
            "sortBy": "",
            "sortOrder": "1",
            "dql": {},
            "selectFields": pKeys,
            "jlql": {"errors":[],"logic":{},"data":{}}
        }

       let self = this;

       req_filter_post("filter", body).then(function (result) {
           if (result.status === 200) {
               self.handleClose();
               if (openOnCreate("filter")) {
                   self.props.openItem("filter", {name: self.state.content.title, id: result.data.id})
               } else {
                   self.props.refresh(true, null);
               }
               self.setState({loading: false, alert:{...{ "open": true, "message": "The filter was created successfully.", "type": "success", "timeout": 5000 }}});

           } else {
               self.handleClose();
               self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem saving the filter.", "type": "error", "timeout": 5000 }}});
           }
       })
    }

    purgeAlert = () => { this.setState({alert: {...{ open: false, message: "", type: "" }}}) }

    Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {

        return(<div style={{"display":"inline-block"}}>
            <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>

            {!this.props.expAdd ?
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="add or edit record"
                    onClick={this.handleClickOpen}
                >
                    {(this.props.type === "edit") ?
                        <EditIcon sx={{fontSize: "20px", color: '#666'}}/> : <AddBoxIcon/>
                    }
                </IconButton> : null
            }

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={this.Transition}
            >
                <BackdropAppear loading={this.state.loading || false} />
                <DialogAppBar title="New Filter" close={this.handleClose} />

                <Box sx={{padding: 2}} component="form" onSubmit={this.saveFilter}>

                    <AutocompleteSelect
                        rows={this.state.datasets}
                        selectedRecord={this.state.currentDatasetRec || null}
                        selectLabel={"Select a Dataset to Filter"}
                        selectValue={this.selectDataset}
                    />

                    <TextField key={"field_name"}
                               sx={{marginBottom: 2}} fullWidth
                               label={"Filter Name"}
                               value={this.state.content["title"]}
                               required={true}
                               onChange={(e) => this.handleChange("title", e)}
                    />

                    <div align={"right"}>
                        <Button type="submit" variant="contained" size="small" >Save</Button>
                        <Button variant="contained" size="small"  style={{marginLeft: "5px"}} onClick={this.handleClose}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>
        </div>)
    }
}
