import React, {Component} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FormFieldCreator from "./formFieldCreator"
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import {FormObject} from "../../helpers/formObjects"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { Card, FormControl, FormControlLabel, InputLabel, Select, Stack, TextField
} from "@mui/material";
import {
    req_filter_post, req_generic_dataset_post, req_projects_get_list,
    req_templates_get_list, req_upload_csv_create, req_upload_excel_create,req_templates_search_post
} from "../../helpers/requests";
import {Autocomplete} from "@mui/lab";
import "./formDialogCollection.css"
import MenuItem from "@mui/material/MenuItem";
import TagInput from "../formControls/tagInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { read } from 'xlsx';
import Checkbox from "@mui/material/Checkbox";
import {openOnCreate} from "../../helpers/settings";
import DialogAppBar from "../dialogs/dialogAppBar";
import ColumnTypesPanel from "../formControls/columnTypesPanel";
import {DATASET_COLUMN_EXISTS, GENERIC_LOAD_ERROR} from "../../helpers/strConstants";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const TemplatesTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default class FormDialogCollection extends Component {

    state = {
        open: false,
        fields: this.props.fieldSchema,
        content: this.props.objectContent,
        collectionObjects: [],
        currentProjectRec: null,
        createFields: {},
        loading: false,
        projects: [],
        templates: [],
        templateSearchResults: [],
        templateSearchTerms: "",
        selectedProject: this.props && this.props.itemDetail && this.props.itemDetail.project ? this.props.itemDetail.project : "Select a Project",
        selectedProjectError: false,
        alert: { open: false, message: "", type: "" },
        dataTab: 0,
        templateTab: 0,
        schemaFileName: "",
        uplFile: null,
        submitVals: {type: "", approved: false, payload: {}},
        csvCreateType: "full",
        excel: {},
        excelSheets: [],
        excelUploadSheets: {}

    };

    setOpen = (visible) => { this.setState({ open: visible }); }

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }

    loadProjects = () => {
        let self = this;
        this.setState({ loading: true });
        req_projects_get_list().then(function (result) {
            if (result.status === 200) {
                let rowrecs = [];
                result.data.data.forEach(function (record) {
                    if (JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))){
                        rowrecs.push(record);
                    } else if (record.hasOwnProperty("accessPermissions")){
                        let rec = record.accessPermissions.filter(perm  => {
                            return perm.personId === localStorage.getItem("uid") && perm.role !== "viewer"
                        })
                        if (rec.length > 0){ rowrecs.push(record); }
                    }
                });

                if (self.props.itemDetail && self.props.itemDetail.project) {
                    rowrecs.forEach(project => {
                        if (project.id === self.props.itemDetail.project) {
                            self.setState({"currentProjectRec": project})
                        }
                    })
                }

                self.setState({projects: [...rowrecs]});
                self.setState({ loading: false });
            } else {
                self.setState({ loading: false, alert: {...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    updateState = () => { this.setState({ loading: true }); }

    loadTemplates = () => {
        let self = this;
        this.setState({ loading: true });
        req_templates_get_list("template").then(function (result) {
            if (result.status === 200) {
                let rowrecs = [];
                result.data.data.forEach(function (record) {
                    rowrecs.push(record);
                });

                if (self.props.itemDetail && self.props.itemDetail.template ){
                    self.importFromTemplate(self.props.itemDetail.templateObj.schema.properties, self.props.itemDetail.templateObj.schema.required)
                }

                self.setState({templates: [...rowrecs]});
                self.setState({ loading: false });
            } else {
                self.setState({ loading: false, alert: {...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    a11yProps = (index) => { return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` }; }

    selectProject = (e, newValue) => {
        this.setState({selectedProject: newValue.id});
        let set = false;
        this.state.projects.forEach(project => {
            if (newValue && (project.id === newValue.id) && !set) {
                this.setState({"currentProjectRec": project})
                set = true;
            }
        })
    }

    handleCreatorChange = (id) => {
        let currentIndex = null;
        let i = 0;
        let fieldNames = [];
        this.state.collectionObjects.forEach((collection) => {
                if (parseInt(collection.fieldId) === parseInt(id))
                    currentIndex = i;
                else if (collection.dObj.fieldName)
                    fieldNames.push(collection.dObj.fieldName.toLowerCase());
                i++;
        })

        if (fieldNames.length > 0 && fieldNames.includes(this.state.collectionObjects[currentIndex].dObj.fieldName.toLowerCase())){
            let cObs = this.state.collectionObjects;
            cObs[currentIndex].dObj.error = true;
            cObs[currentIndex].dObj.errorMessage = DATASET_COLUMN_EXISTS;
            this.setState({collectionObjects: {...cObs}});
        } else {
            if (this.state.collectionObjects[currentIndex]) {
                this.state.collectionObjects[currentIndex].clearError();
            }
        }
        this.setState(this.state);
    }

    handleCreatorDelete = (id) => {
        let newCollection = this.state.collectionObjects.filter(item => item.fieldId !== parseInt(id));
        this.setState({collectionObjects: [...newCollection]})
    }

    handleClickOpen = () => { this.setOpen(true); };
    handleClose = () => { this.setOpen(false); this.props.close(); };

    setUploadOpen = (type) => {
        if (!type) {
            this.setState({...{type: "", approved: false, payload: {}}})
        } else {
            this.setState({submitVals: {type: "upload", approved: false, payload: {uplType: type}}})
        }
    }

    handleClickUploadOpen = (type) => { this.setUploadOpen(type); };

    handleFile = (e) => { this.setState({uplFile: e, filename: e.target.files[0].name}) }

    handleExcelFile = (e) => {
        let self = this;
        this.setState({uplFile: e, filename: e.target.files[0].name, excelSheets: [...[]], excelUploadSheets: {...{}} })
        const reader = new FileReader();
        reader.onload = (e) => {
            let wb = read(e.target.result, {type: 'binary'});
            let uploadSheets = [];
            wb.SheetNames.forEach((sheet) => { uploadSheets[sheet] = { keep: true, newName: sheet } })
            self.setState({ excelSheets: [...wb.SheetNames], excelUploadSheets: {...uploadSheets}} )
        }
        reader.readAsBinaryString(e.target.files[0])
    }

    toggleCheckExcelSheet = (key) => {
        let sheets = this.state.excelUploadSheets;
        sheets[key].keep = !sheets[key].keep;
        this.setState({ excelUploadSheets: sheets})
    }

    updateExcelSheetName = (key, e) => {
        let sheets = this.state.excelUploadSheets;
        sheets[key].newName = e.target.value;
        this.setState({ excelUploadSheets: sheets})
    }

    changeTab = (event, newValue) => {
        this.setState({dataTab: newValue });
        if (newValue !== 0){
            this.setState({submitVals: {type: "", approved: false, payload: {}}})
        }
    }

    handleChange = (field, e) => {

        let fields = this.state.fields;
        let content = this.state.content;
        content[field] = e.target.value;

        this.setState({
            fields: {...fields},
            content: {...content},
            createFields: {...content}
        });
    };

    handleSearchTermsChange = (e) => { this.setState({ templateSearchTerms: e.target.value }); };

    searchTemplates = () => {
        let self = this;
        const searchTerms = this.state.templateSearchTerms.split(" ");
        let body = { "search": searchTerms.length > 0 ? searchTerms : [] }

        this.setState({loading: true});
        req_templates_search_post(body).then(function (result) {
            if (result.status === 200) {
                self.setState({templateSearchResults: [...result.data.data]});
                self.setState({loading: false});

            } else {
                self.setState({
                    loading: false,
                    alert: {
                        ...{
                            "open": true, "message": "There was a problem with the template search.",
                            "type": "error", "timeout": 5000
                        }
                    }
                });
            }
        })
    }

    saveCollectionObject = (e) => {
        e.preventDefault();
        let properties = {};
        let required = [];
        let i = 0;
        let formError = false;
        let isUpload = false;
        this.setState({ loading: true });
        const valFields = this.state.createFields;

        if (Object.keys(this.state.createFields).length === 0) {
            formError = true;
        }

        if (this.state.submitVals.type === "upload"){
            isUpload = true;
            if (!(this.state.uplFile && this.state.filename)){
                formError = true;
                this.setState({alert: { open: true, message: "It does not appear a file was selected for upload.", type: "error" }})
            }
            if (this.state.submitVals.payload.uplType === "Excel"){
                for (const [key,] of Object.entries(this.state.excelUploadSheets)){
                    if (this.state.excelUploadSheets[key].keep === true && !this.state.excelUploadSheets[key].newName){
                        formError = true;
                        this.setState({alert: { open: true, message: "It appears something is missing or incomplete. Please review the messaging and try again.", type: "error" }})
                    }
                }
            }
        }

        let currentIndex = 0;
        this.state.collectionObjects.forEach((collection) => {
            if (!collection.dObj.fieldName || collection.dObj.fieldName.trim() === "")
            {
                let cObs = this.state.collectionObjects;
                cObs[currentIndex].dObj.error = true;
                cObs[currentIndex].dObj.errorMessage = "Field Name is required";
                this.setState({collectionObjects: {...cObs}});
                formError = true;
            }

            if (!collection.dObj.type || collection.dObj.type.trim() === "" || collection.dObj.type.trim() === "Select a Field Type")
            {
                let cObs = this.state.collectionObjects;
                cObs[currentIndex].dObj.typeError = true;
                cObs[currentIndex].dObj.typeErrorMessage = "Field Type is required";
                this.setState({collectionObjects: {...cObs}});
                formError = true;
            }
            currentIndex++;
        })

        this.setState( { createFields: {...valFields} } )

        // see if this can be removed
        // console.log((this.state.fields)["properties"])
        //if (!formError && ((this.state.fields)["properties"]["datasetName"].hasError === true)){
        //    formError = true;
        //}
        if (formError) {
            this.setState({loading: false, alert: { open: true, message: "There are issues with the submission. Please review and try again.", type: "error" }})
        } else {

            if (isUpload){
                this.setState({ loading: true });
                let formData = new FormData();
                let self = this;

                formData.append("file", this.state.uplFile.target.files[0]);
                formData.append("name", this.state.content.datasetName);
                formData.append("projectId", this.state.selectedProject);
                formData.append("account", JSON.parse(localStorage.getItem("selectedAccount")));

                if (this.state.submitVals.payload.uplType === "CSV"){
                    formData.append("csvCreateType", this.state.csvCreateType);
                    req_upload_csv_create(formData).then((result) => {
                        if (result.status === 200) {

                            let dBody = {
                                "name": self.state.content.datasetName,
                                "projectId": self.state.selectedProject,
                                "id": result.data.id,
                                "collectionName": result.data.id,
                                "schema": result.data.schema
                            }

                            let datasets = JSON.parse(localStorage.getItem("ds_datasets"));
                            datasets.data.data.push(dBody)
                            localStorage.setItem("ds_datasets", JSON.stringify(datasets));

                            if (openOnCreate("dataset")) {
                                self.props.openItem("dataset", {"name":self.state.content.datasetName, "id": result.data.id })
                            } else {
                                self.props.refresh(true, null);
                            }

                            const pKeys = []
                            for (const [, value] of Object.entries(result.data.schema.properties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])){
                                pKeys.push(value.fieldName)
                            }

                            let body = {
                                "datasetId": result.data.id,
                                "title": "Default Filter: " + self.state.content.datasetName,
                                "dql": {},
                                "jlql": {"errors": [], "logic": {}, "data": {}},
                                selectFields: pKeys,
                                sortOrder: 1,
                                sortBy: pKeys[0]
                            }

                            req_filter_post("filter", body).then(function (result) {
                                if (result.status === 200) {
                                    if (openOnCreate("filter")) {
                                        self.props.openItem("filter", {name: body.title, id: result.data.id})
                                    } else {
                                        self.props.refresh(true, null);
                                    }

                                    self.setState({loading: false, filename: ""});
                                    self.handleClose()
                                } else {
                                    self.setState({
                                        loading: false,
                                        alert: {
                                            ...{
                                                "open": true, "message": "There was a problem saving the filter.",
                                                "type": "error", "timeout": 5000
                                            }
                                        }
                                    });
                                }
                            })

                        } else {

                            self.setState({
                                loading: false,
                                alert: {
                                    ...{
                                        "open": true, "message": "There was a problem saving the dataset. ",
                                        "type": "error", "timeout": 5000
                                    }
                                }
                            });
                        }
                    })

                } else if (this.state.submitVals.payload.uplType === "Excel"){

                    formData.append("excelUploadSheets", JSON.stringify(self.state.excelUploadSheets));

                    req_upload_excel_create(formData).then((result) => {
                        if (result.status === 200) {

                            result.data.multiResponse.forEach((dataset) => {
                                if (dataset.id){

                                    let dBody = {
                                        "name": dataset.name,
                                        "projectId": self.state.selectedProject,
                                        "id": dataset.id,
                                        "collectionName": dataset.id,
                                        "schema": dataset.schema
                                    }

                                    let datasets = JSON.parse(localStorage.getItem("ds_datasets"));
                                    datasets.data.data.push(dBody)
                                    localStorage.setItem("ds_datasets", JSON.stringify(datasets));

                                    if (openOnCreate("dataset")) {
                                        self.props.openItem("dataset", {"name":dataset.name, "id": dataset.id })
                                        self.props.refresh(true, null);
                                    } else {
                                        self.props.refresh(true, null);
                                    }

                                    const pKeys = []

                                    for (const [, value] of Object.entries(dataset.schema.properties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])){
                                        pKeys.push(value.fieldName)
                                    }

                                    let body = {
                                        "datasetId": dataset.id,
                                        "title": "Default Filter: " + dataset.name,
                                        "dql": {},
                                        "jlql": {"errors": [], "logic": {}, "data": {}},
                                        selectFields: pKeys,
                                        sortOrder: 1,
                                        sortBy: pKeys[0]
                                    }

                                    req_filter_post("filter", body).then(function (result) {
                                        if (result.status === 200) {

                                            if (openOnCreate("filter")) {
                                                self.props.openItem("filter", {name: body.title, id: result.data.id})
                                            } else {
                                                self.props.refresh(true, null);
                                            }
                                            self.setState({loading: false, filename: ""});
                                        } else {
                                            self.setState({
                                                loading: false,
                                                alert: {
                                                    ...{
                                                        "open": true, "message": "There was a problem saving the filter.",
                                                        "type": "error", "timeout": 5000
                                                    }
                                                }
                                            });
                                        }
                                    })
                                    self.handleClose()
                                } else {
                                    self.setState({
                                        loading: false,
                                        alert: {
                                            ...{
                                                "open": true, "message": "There was a problem with one or more sheets in the excel document." + dataset.response.errorList.join(", "),
                                                "type": "error", "timeout": 5000
                                            }
                                        }
                                    });

                                }
                            })

                        } else {
                            self.setState({
                                loading: false,
                                alert: {
                                    ...{
                                        "open": true, "message": "There was a problem with the Excel submission.",
                                        "type": "error", "timeout": 5000
                                    }
                                }
                            });

                            self.props.refresh(true, null);
                        }
                    })
                }

            } else {
                if(this.state.collectionObjects.length > 0){
                this.state.collectionObjects.forEach(collectionObject => {
                    if (collectionObject.dObj.required) { required.push(collectionObject.dObj.fieldName) }
                    properties[collectionObject.dObj.fieldName] = collectionObject.convertToPropertyObject(i);
                    i++;
                });

                let dBody = {
                    "name": this.state.content.datasetName,
                    "projectId": this.state.selectedProject,
                    "schema": {
                        "type": "object", "properties": properties, "required": required, "additionalProperties": true
                    }
                }

                const pKeys = Object.keys(properties)
                this.setState({loading: true});
                let self = this;

                req_generic_dataset_post("dataset", dBody).then(function (result) {

                    if (result.status === 200 && (!result.data.status || result.data.status !== "fail")) {

                        self.props.refresh(true, {
                            "name": self.state.content.datasetName,
                            "id": result.data.datasetId,
                            "type": "dataset"
                        });

                        self.setState({
                            alert: { "open": true, "message": "The dataset was created successfully.",
                                "type": "success", "timeout": 5000 }
                        })
                        dBody["collectionName"] = result.data.datasetId;
                        dBody["id"] = result.data.datasetId;
                        let datasets = JSON.parse(localStorage.getItem("ds_datasets"));
                        datasets.data.data.push(dBody)
                        localStorage.setItem("ds_datasets", JSON.stringify(datasets));

                        let body = {
                            "datasetId": result.data.datasetId,
                            "title": "Default Filter: " + self.state.content.datasetName,
                            "dql": {},
                            "jlql": {"errors": [], "logic": {}, "data": {}},
                            selectFields: pKeys,
                            sortOrder: 1,
                            sortBy: pKeys[0]
                        }

                        req_filter_post("filter", body).then(function (result) {
                            if (result.status === 200) {

                                if (openOnCreate("filter")) {
                                    self.props.openItem("filter", {name: body.title, id: result.data.id})
                                } else {
                                    self.props.refresh(true, null);
                                }

                                self.setState({loading: false})
                                self.handleClose()
                            } else {
                                self.setState({
                                    loading: false,
                                    alert: {
                                        ...{
                                            "open": true, "message": "There was a problem saving the filter.",
                                            "type": "error", "timeout": 5000
                                        }
                                    }
                                });
                            }
                        })

                        if (["private_template", "community_template"].includes(self.state.content.createTemplate)) {
                            let templateBody = {
                                name: self.state.content.templateName,
                                permission: self.state.content.createTemplate === "private_template" ? "private" : "community",
                                notes: self.state.content.templateNotes,
                                tags: self.state.content.templateTags,
                                schema: {
                                    "type": "object",
                                    "properties": properties,
                                    "required": required,
                                    "additionalProperties": true
                                }
                            }

                            req_generic_dataset_post("template", templateBody).then(function (tresult) {
                                if (result.status === 200) {

                                    self.handleClose()
                                } else {
                                    self.setState({
                                        loading: false,
                                        alert: {
                                            ...{
                                                "open": true, "message": "There was a problem saving the template.",
                                                "type": "error", "timeout": 5000
                                            }
                                        }
                                    });
                                }
                            })
                        }

                    }  else if (result && result.data && result.data.msg) {
                        self.setState({
                            loading: false,
                            alert: { ...{ "open": true, "message": result.data.msg, "type": "error", "timeout": 5000 }}
                        });
                    } else {
                        self.setState({loading: false,
                            alert: {...{
                                "open": true, "message": "The was an error creating the dataset.",
                                "type": "error", "timeout": 5000
                            }}
                        })
                    }
                }) } else {
                    this.setState({loading: false,
                        alert: {...{
                            "open": true, "message": "New datasets must have at least one column defined.",
                            "type": "error", "timeout": 5000
                        }}
                    })
                }
            }
            //this.handleClose();
        }
    }

    addFieldsToCollection = (e, type) => {
        let collectionObjects = this.state.collectionObjects;
        let max = 0;
        for (const [, value] of Object.entries(this.state.collectionObjects)) {
            max = (parseInt(value.fieldId) > max) ? parseInt(value.fieldId) : max;
        }
        const newId = max + 1;
        let collectionObject = new FormObject(newId);
        collectionObject.resetObjBase(type);
        collectionObjects.push(collectionObject);
        this.setState({collectionObjects: [...collectionObjects]});
    };

    importFromTemplate = (properties, required) => {

        let myCollectionObjects = [];
        for (const [, value] of Object.entries(properties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])) {
            let collectionObject = new FormObject(value.fieldId);

            for (const [key2, value2] of Object.entries(value)){
                collectionObject.updateObj(key2, value2);
            }

            collectionObject.dObj = {...collectionObject.coerceObj(value)};
            if (required.includes(value.fieldName)){
                collectionObject.updateObj("required", true);
            }

            //this.props.fieldObj.updateObj("enum", keys);
            myCollectionObjects.push(collectionObject);
        }

        this.setState({collectionObjects: [...myCollectionObjects], dataTab: 1});
    }

    handleTagSelect = (e) => {
        let content = this.state.content;
        content.templateTags = e;
        this.setState({content: {...content}});
    }

    componentDidMount() {
        let content = this.state.content;
        content.datasetName = "";
        this.setState({content: {...content}});
        if (this.props.expAdd){
            this.handleClickOpen();
            this.loadProjects();
            this.loadTemplates();
            this.handleChange("createFilter", {target: {value: true}})
        }
    }

    Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        return(<div style={{"display":"inline-block"}}>

            {!this.props.expAdd ?
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={this.handleClickOpen}
                >
                    {(this.props.type === "edit") ?
                        <EditIcon/> : <AddBoxIcon/>
                    }
                </IconButton> : null
            }
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={this.Transition}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                <DialogAppBar title={"New " + this.props.objectType} close={this.handleClose} />

                <Box sx={{padding: 2}} component={"form"} onSubmit={(e) => {
                    this.saveCollectionObject(e)
                }}>

                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{width: "100%"}}>
                            <Autocomplete
                                id="project_select"
                                options={this.state.projects}
                                autoHighlight
                                required
                                value={this.state.currentProjectRec || null}
                                onChange={(e, newValue) => {
                                    this.selectProject(e, newValue);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a Project"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        required
                                    />
                                )}
                            />
                        </div>

                    </div>

                    <Tabs value={this.state.dataTab}
                          onChange={this.changeTab}
                          TabIndicatorProps={{
                              style: {
                                  backgroundColor: "#666"
                              }
                          }}
                    >
                        <Tab label="Create Dataset From File" {...this.a11yProps(0)} style={{
                            fontSize: "1rem",
                            minHeight: "20px",
                            textTransform: "none"
                        }} />

                        <Tab label="Create Custom Dataset" {...this.a11yProps(1)} style={{
                            fontSize: "1rem",
                            minHeight: "20px",
                            textTransform: "none"
                        }}/>

                        <Tab label="Create Dataset From Template" {...this.a11yProps(2)} style={{
                            fontSize: "1rem",
                            minHeight: "20px",
                            textTransform: "none"
                        }}/>
                    </Tabs>
                    <Card  variant="outlined" style={{padding: "5px", backgroundColor: "#f7f7f7", borderColor: "1px solid #ccc", borderRadius: "3px"}}>

                        <TabPanel value={this.state.dataTab} index={0} style={{marginTop: "0", padding: "0 !important"}}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                Upload Dataset Types:
                                <Button variant="contained" style={{marginLeft: "10px"}}  onClick={() => {this.handleClickUploadOpen("CSV")}}>
                                    <FontAwesomeIcon icon={faFileCsv} size="xl"/>
                                </Button>
                                <Button variant="contained" onClick={() => {this.handleClickUploadOpen("Excel")}}>
                                    <FontAwesomeIcon icon={faFileExcel} size="xl"/>
                                </Button>
                                <Button variant="contained" style={{display: "none"}} onClick={() => {this.handleClickUploadOpen("Sheets")}}>
                                    Sheets
                                </Button>
                            </Stack>

                            { this.state.submitVals.type === "upload" && (this.state.submitVals.payload && this.state.submitVals.payload.uplType === "CSV") ?
                            <div className={"uploadContainer"}>
                                <div>
                                    <TextField key={"field_name"}
                                               sx={{marginBottom: 2, marginTop: "10px !important"}} fullWidth
                                               label={"New Dataset Name"}
                                               value={this.state.content["datasetName"]}
                                               required={true}
                                               onChange={(e) => this.handleChange("datasetName", e)}
                                    />
                                </div>
                                <Typography variant="body" gutterBottom component="div">
                                    <b>Select a CSV file to import:</b>
                                </Typography>
                                <div>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Button variant="contained" component="label" size="small">
                                        Select File
                                        <input hidden type="file" onChange={this.handleFile} />
                                    </Button> &nbsp; <b>{this.state.filename}</b>
                                </Stack>
                                </div>
                                <div style={{marginTop: "10px"}}>
                                    <FormControl>
                                        <RadioGroup defaultValue="full"
                                                    onChange={(e) => {
                                                        this.setState({csvCreateType: e.target.value})

                                                    }}
                                        >
                                            <FormControlLabel value="schema" control={<Radio />} label="Create Dataset Head (schema) Only" />
                                            <FormControlLabel value="full" control={<Radio />} label="Create Dataset & Insert Data" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div> : null
                            }

                            { this.state.submitVals.type === "upload" && (this.state.submitVals.payload && this.state.submitVals.payload.uplType === "Excel") ?
                                <div className={"uploadContainer"}>
                                    <Typography variant="body" gutterBottom component="div">
                                        <b>Select an Excel file to import:</b>
                                    </Typography>
                                    <div style={{paddingBottom: "5px"}}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Button variant="contained" component="label" size="small">
                                                Select File
                                                <input hidden type="file" onChange={this.handleExcelFile} />
                                            </Button> &nbsp; <b>{this.state.filename}</b>
                                        </Stack>
                                    </div>
                                    {
                                        this.state.excelSheets.length > 0 ?
                                            <div style={{marginTop: "10px"}}>
                                                <div>
                                                    <b>Confirm the Excel worksheets you'd like to import and the dataset name they should be imported to.</b>
                                                </div>
                                                {
                                                    this.state.excelSheets.map((sheet) => {
                                                    return <div key={sheet} style={{backgroundColor: "#eee", border: "1px solid #ccc", borderRadius: "2px", padding: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "5px"}}>
                                                                <div style={{width: "25%"}}>
                                                                    <Checkbox size="small"
                                                                              checked={this.state.excelUploadSheets[sheet].keep}
                                                                              onChange={() => {this.toggleCheckExcelSheet(sheet)}}
                                                                              />
                                                                    <b>{sheet}</b>
                                                                </div>
                                                                <div style={{width: "75%", paddingTop: "4px"}}>
                                                                    <TextField
                                                                    key={sheet+"_tf"} size="small" value={this.state.excelUploadSheets[sheet].newName}
                                                                    fullWidth
                                                                    disabled={this.state.excelUploadSheets[sheet].keep === false}
                                                                    style={{marginBottom: "5px !important"}}
                                                                    error={!this.state.excelUploadSheets[sheet].newName && this.state.excelUploadSheets[sheet].keep}
                                                                    helperText={!this.state.excelUploadSheets[sheet].newName && this.state.excelUploadSheets[sheet].keep ? "A name is required for the new dataset.": false}
                                                                    onChange={(e) => {this.updateExcelSheetName(sheet, e)}}
                                                                    label={"New Dataset Name To Import As"} />
                                                                </div>
                                                            </div>
                                                })
                                                }
                                            </div>: null
                                    }
                                </div> : null
                            }
                            { this.state.submitVals.type === "upload" && (this.state.submitVals.payload && this.state.submitVals.payload.uplType === "Sheets") ?
                                <div  className={"uploadContainer"}>
                                    <Typography variant="body" gutterBottom component="div">
                                        <b>Select a Sheets file to import:</b>
                                    </Typography>
                                    <div>
                                        Connect with Google
                                    </div>
                                </div> : null
                            }
                        </TabPanel>

                        <TabPanel value={this.state.dataTab} index={1} style={{marginTop: "0", padding: "0 !important"}}>
                            <div>
                                <TextField key={"field_name"}
                                           sx={{marginBottom: "0 !important", marginTop: 0}} fullWidth
                                           label={"New Dataset Name"}
                                           value={this.state.content["datasetName"]}
                                           required={true}
                                           onChange={(e) => this.handleChange("datasetName", e)}
                                />
                            </div>
                            { this.state.collectionObjects.map((fObj, index) => {
                                return <FormFieldCreator key={fObj.fieldId}
                                                         fieldObj={fObj}
                                                         updateState={this.updateState}
                                                         onChange={this.handleCreatorChange}
                                                         handleCreatorDelete={this.handleCreatorDelete} />
                            })
                            }
                            <ColumnTypesPanel addColumn={this.addFieldsToCollection} />

                        </TabPanel>

                        <TabPanel className="tabWindow" value={this.state.dataTab} index={2} style={{marginTop: "0", padding: "0 !important"}}>

                            <div style={{display: 'flex'}}>
                                <Tabs
                                    value={this.state.templateTab}
                                    onChange={(event, newValue) => {this.setState({templateTab: newValue })}}
                                    orientation="vertical"
                                    variant="scrollable"
                                    aria-label="Template Category Selection"
                                    sx={{ borderRight: 1, borderColor: 'divider', textAlign: 'left', width: "180px",textTransform: "none" }}
                                >
                                    <Tab style={{
                                        textTransform: "none !important",
                                        fontSize: ".8rem",
                                    }} label="My Templates" {...this.a11yProps(0)} />
                                    <Tab style={{
                                        textTransform: "none !important",
                                        fontSize: ".8rem",
                                    }}
                                         label="Public & Community Templates" {...this.a11yProps(1)} />
                                </Tabs>

                                <TemplatesTabPanel value={this.state.templateTab} index={0} style={{marginTop: "0", padding: "0 !important", width: "100%", height: "400px", overflow: "auto"}}>

                                    <div>
                                        { this.state.templates.map((template, index) => {
                                            if (template.permission === "private") {
                                                return <div key={template.id} style={{backgroundColor: "#eee", border: "1px solid #ccc", borderRadius: "2px", padding: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "5px"}}>
                                                    <div>{template.permission}: <b>{template.name}</b>
                                                        <br /><span style={{fontSize: ".7rem"}}>{template.notes}</span>
                                                    </div>
                                                    <div><Button size={"small"} variant="contained" onClick={() => {this.importFromTemplate(template.schema.properties, template.schema.required)}}>Load Dataset Schema</Button></div>
                                                </div>
                                            } else {
                                                return null
                                            }
                                        })
                                        }
                                    </div>
                                </TemplatesTabPanel>

                                <TemplatesTabPanel value={this.state.templateTab} index={1} style={{marginTop: "0", padding: "0 !important", width: "100%", height: "400px", overflow: "auto"}}>

                                    <div>

                                        <div style={{display: "flex", flexDirection: "row", flex: 1, border: "1px solid #ccc", padding: "5px", paddingTop: "10px", marginBottom: "5px", borderRadius: "3px" }}>
                                            <div style={{width: "80%", marginLeft: "5px"}}>
                                                <TextField size={"small"} onChange={(e) => { this.handleSearchTermsChange(e) }} fullWidth label={"Search by Name, Tag, or Template Id."} ></TextField>
                                            </div>
                                            <div style={{width: "20%", textAlign: "right"}}>
                                                <Button variant="contained" onClick={() => {this.searchTemplates()}}>Search</Button>
                                            </div>
                                        </div>

                                        { this.state.templateSearchResults.map((template, index) => {
                                            if (["public", "community"].includes(template.templateType)) {
                                                return <div key={template.id} style={{backgroundColor: "#eee", border: "1px solid #ccc", borderRadius: "2px", padding: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "5px"}}>
                                                    <div>{template.templateType}: <b>{template.name}</b></div>
                                                    <div><Button size={"small"} variant="contained" onClick={() => {this.importFromTemplate(template.schema.properties, template.schema.required)}}>Load Dataset Schema</Button></div>
                                                </div>
                                            } else {
                                                return null
                                            }
                                        })
                                        }
                                    </div>
                                </TemplatesTabPanel>
                            </div>
                        </TabPanel>

                        { this.state.collectionObjects.length > 0 && this.state.dataTab === 1 ?
                            <div style={{marginTop: "10px", marginLeft: "10px", marginRight: "10px", display: "flex", flexDirection: "row"}}>

                                <div style={{}}>
                                    <FormControl fullWidth>
                                        <InputLabel  style={{"marginTop": "7px"}} id="field_template_label_">Dataset Schema Template</InputLabel>
                                        <Select
                                            labelId={"field_template_label_"}
                                            id={"field_template_label_"}
                                            label="Dataset Schema Template"
                                            value={this.state.content.createTemplate || "no_template"}
                                            onChange={(e) => this.handleChange("createTemplate", e)}
                                        >
                                            <MenuItem value="no_template">Do not make a template for this dataset schema</MenuItem>
                                            <MenuItem value="private_template">Make a private template (My Templates) for this dataset schema</MenuItem>
                                            <MenuItem value="community_template">Make a community template for this dataset schema (visible in community templates)</MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>
                            </div> : null
                        }

                        {
                            ["private_template", "community_template"].includes(this.state.content.createTemplate) && this.state.dataTab === 1 ?
                                <div style={{marginTop: "5px", marginLeft: "10px", marginRight: "10px", display: "flex", flexDirection: "row"}}>
                                    <div style={{width: "50%", paddingRight: "5px", marginTop: "5px"}}>
                                        <TextField key={"template_name"}
                                                   sx={{marginBottom: 1}} fullWidth
                                                   label={"Template Name"}
                                                   value={this.state.content["templateName"]}
                                                   required={true}
                                                   onChange={(e) => this.handleChange("templateName", e)}
                                        />
                                    </div>
                                    <div style={{width: "50%", paddingLeft: "5px"}}>

                                        <TagInput
                                            selectedTags={this.handleTagSelect}
                                            fullWidth
                                            variant="outlined"
                                            id="tags"
                                            name="tags"
                                            placeholder="Add Tags"
                                            label="Template Tags"
                                        />

                                    </div>

                                </div>
                                : null
                        }
                        {
                            ["private_template", "community_template"].includes(this.state.content.createTemplate) && this.state.dataTab === 1 ?
                                <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", marginRight: "10px"}}>
                                    <div style={{width: "100%"}}>
                                        <TextField
                                            multiline
                                            key={"template_notes"}
                                            sx={{marginBottom: 2, width: "100%"}}
                                            label={"Template Notes"}
                                            rows={2}
                                            value={this.state.content["templateNotes"]}
                                            required={true}
                                            onChange={(e) => this.handleChange("templateNotes", e)}
                                        />
                                    </div>
                                </div>
                                : null
                        }

                            </Card>

                    <div align={"right"} style={{marginTop: "5px"}}>
                        {(this.props.type === "edit") ?
                            <Button
                                onClick={() => {
                                    this.props.onClick(this.state.fields, this.props.objectContent.id);
                                    this.handleClose();
                                }}
                            >Update</Button> :
                            <Button type="submit" variant="contained" size="small">Save</Button>
                        }
                        <Button variant="contained" size="small"  style={{marginLeft: "5px"}} onClick={this.handleClose}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>
        </div>)
    }
}
