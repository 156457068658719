import * as React from 'react';
import {FormControl, InputLabel, Select, TextareaAutosize, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/SimpleDatasetBeta.png";
import Button from "@mui/material/Button";
import {req_send_contact_email} from "../../helpers/requests";



const Contact = (props) => {

    const sendMessage = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        let contactType = data.get('contactType');
        let firstName = data.get('firstName');
        let lastName = data.get('lastName');
        let email = data.get('email');
        let comments = data.get('comments');

        let body = {
            contactType: contactType,
            firstName: firstName,
            lastName: lastName,
            email: email,
            comments: comments
        }

        req_send_contact_email(body).then(function (result) {
            if (result.status === 200) {
                props.closeContact()
            } else {
                //self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem submitting the contact request.", "type": "error", "timeout": 5000 }}});
            }
        })



    }

    return(

        <div className="Contact">
            <Box component="form" onSubmit={sendMessage} style={{width: '500px', minWidth: '390px', padding: '10px',margin: "0 auto","textAlign": "left", marginTop: "20px"}}>
                <div style={{marginTop: "10px"}}><img src={logo} alt="Simple:Dataset Logo" style={{maxWidth: "480px"}} /></div>
                <div style={{marginBottom: "10px"}}>
                    <FormControl fullWidth>
                        <InputLabel id="field_chart_type_label">Contact Type</InputLabel>
                        <Select
                            margin="normal"
                            fullWidth
                            required
                            id={"contactType"}
                            name={"contactType"}
                        >
                            <MenuItem value="inquiry">Inquiry</MenuItem>
                            <MenuItem value="feature_request">Feature Request</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id={"firstName"}
                        name={"firstName"}
                        label={"First Name"}
                    />
                </div>
                <div>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id={"lastName"}
                        name={"lastName"}
                        label={"Last Name"}
                    />
                </div>
                <div>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id={"email"}
                        name={"email"}
                        label={"Email Address"}
                    />
                </div>

                <div style={{marginTop: "10px"}}>
                    Tell us all about it.
                    <TextareaAutosize
                        fullWidth
                        minRows={7}
                        style={{ width: "100%", padding: "5px" }}
                        label={"Comments"}
                        id={"comments"}
                        name={"comments"}
                    />
                </div>
                <div align={"right"} style={{marginTop: "10px"}}>
                    <Button type="submit" variant="contained" size="small">Submit</Button>
                    <Button variant="contained" size="small" onClick={props.closeContact} style={{marginLeft: "10px"}}>Cancel</Button>
                </div>

            </Box>
        </div>
    )
}

export default Contact;
