import React, {Component} from 'react';
import {TabContext, TabPanel} from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import PortalItemDisplay from "./portalItemDisplay";
import TableViewIcon from "@mui/icons-material/TableView";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReportIcon from "@mui/icons-material/Assessment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ApiIcon from "@mui/icons-material/Api";
import FeedIcon from '@mui/icons-material/Feed';
import {Icon} from "@mui/material";

export default class PortalTabs extends Component {

    iconSelector = (itemType) => {
        if (itemType === 'dataset'){
            return <TableViewIcon fontSize={"small"} style={{color: "#406cbf"}} />
        } else if (itemType === 'report') {
            return <ReportIcon fontSize={"small"} style={{color: "#cf7e3a"}} />
        } else if (itemType === 'dashboard') {
            return <DashboardIcon fontSize={"small"} style={{color: "#cf7e3a"}} />
        } else if (itemType === 'filter') {
            return <FilterAltIcon fontSize={"small"} style={{color: "#66a173"}} />
        } else if (itemType === 'apiwebhook') {
            return <ApiIcon fontSize={"small"} style={{color: "#8a5ab5"}} />
        } else if (itemType === 'infopage') {
            return <FeedIcon fontSize={"small"} style={{color: "#FF9007"}} />
        }

    }

    render() {
        return (
            <div style={{"width": "100%"}}>
                    {this.props.tabs ?
                        <TabContext
                            style={{}}
                            value={this.props.tabIndex}>

                        <Tabs
                            value={this.props.tabIndex}
                            onChange={this.props.handleChangeIndex}
                            variant="scrollable"
                            scrollButtons
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#666"
                                }
                            }}

                            sx={{
                                "& button:Mui-selected": {backgroundColor: "#fcfcfc"},
                                "& button:focus": {backgroundColor: "#fcfcfc"},
                                "& button:active": {backgroundColor: "#fcfcfc"},
                                "& .MuiTab-root > .MuiTab-iconWrapper": {
                                    fontSize: "1.1rem"
                                }
                                , borderBottom: "1px solid #ccddee"
                            }}
                            allowScrollButtonsMobile
                            aria-label="Content Tabs"
                            style={{fontSize: ".8rem",  "margin": 0, minHeight: "20px", marginTop: "-3px"}}
                        >
                            {
                                this.props.tabs.map((tab, ind) =>
                                    <Tab
                                        key={ind}
                                        label={
                                        <span>{tab.name} <Icon onClick={(event) => {
                                            this.props.closeTab(ind);
                                            event.preventDefault();
                                        }}><DisabledByDefaultIcon style={{
                                            fontSize: ".9rem"
                                        }}/></Icon></span>
                                    }
                                         icon={this.iconSelector(tab.itemType)} iconPosition="start"
                                         value={ind.toString()}
                                         style={{
                                             fontSize: ".8rem",
                                             fontWeight: "400",
                                             border: "1px solid #e7e7e7",
                                             minHeight: "20px",
                                             textTransform: "none",
                                             padding: "3px 5px",
                                             borderTopLeftRadius: "7px",
                                             borderTopRightRadius: "7px",
                                             borderBottom: 0
                                         }}/>
                                )
                            }
                        </Tabs>
                        {
                            this.props.tabs.map((tab, ind) =>
                                <TabPanel key={ind} value={ind.toString()} index={ind} style={{padding: "0", width: (window.innerWidth - 250) + "px"}}>
                                    <PortalItemDisplay tabData={tab} addItem={this.props.addItem} openItem={this.props.openItem} refresh={this.props.refreshMenu}/>
                                </TabPanel>
                            )
                        }
                        </TabContext>
                        : null
                    }

            </div>);
    }
}