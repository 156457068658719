import React, {} from 'react';
import Box from "@mui/material/Box";
import {
    req_filter_put,
    req_filter_get,
    req_generic_get_dataset_query
} from "../../helpers/requests";
import Button from "@mui/material/Button";
import {ClickAwayListener, Drawer, Grow, MenuList, Popper, Select} from "@mui/material";
import {Query, Builder, Utils as QbUtils} from 'react-awesome-query-builder';
import MuiConfig from "react-awesome-query-builder/lib/config/mui";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import './filterEditPanel.css';
import '../theme/styles.css';
import '../theme/compact_styles.css';
import AutocompleteSelect from "../formControls/AutocompleteSelect";
import MenuItem from "@mui/material/MenuItem";
import AutocompleteSelectMulti from "../formControls/AutocompleteSelectMulti";
import BackdropAppear from "../staticDisplays/backdropAppear";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";
import LinearProgress from '@mui/material/LinearProgress';
import DataGrid from 'react-data-grid';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {GENERIC_LOAD_ERROR} from "../../helpers/strConstants";
import {getColumnImage} from "../../helpers/dataViewMethods";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const InitialConfig = MuiConfig;
const config = {
    ...InitialConfig,
    fields: {}
};

const queryValue = {"id": QbUtils.uuid(), "type": "group"};

export default class FilterEditPanel extends React.Component {

    state = {
        content: {},
        id: this.props.did,
        datasets: [],
        tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
        config: { ...InitialConfig, fields: {} },
        showQueryEditor: false,
        currentDataset: "",
        filterData: {columns:[], rows:[]},
        loading: false,
        alert: { open: false, message: "", type: "" },
        currentDatasetRec: null,
        sortOrder: null,
        datasetColumns: [],
        currentSortByRec: null,
        currentFieldsRec: null,
        filterQuery: {},
        jlQuery: {"errors":[],"logic":{},"data":{}},
        openSettings: false,
        dataLoaded: false,
        currentUserRole: (JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))) ? "owner": "viewer",
        apiMenuRef: React.createRef(),
        openApiMenu: false,
        dependentApis: [],
        objectColumns: []
    }

    renderBuilder = (props) => (
        <div className="query-builder-container">
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    )

    standardColumnRenderer = (p) => {
        return (<div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <div style={{paddingLeft: "5px", lineHeight: "28px", verticalAlign: "middle"}}>{p.column.name}
                <div className={"columnRenderImageContent"}>
                    {getColumnImage(p.column.fieldType, p.column.format || null, p.column.dateFormat || null)}
                </div>
            </div>
        </div>);
    }

    loadRec = () => {
        let self = this;
        self.setState({ loading: true });
        req_filter_get(this.props.did).then(function (result) {
            if (result.status === 200) {
                let rowData = result.data.data;

                if (!rowData.sortOrder){ rowData.sortOrder = "1"; }

                self.selectDataset({},{id: rowData.datasetId});
                let cf = {...self.state.config};
                cf.logic = rowData.jlql && rowData.jlql.logic ? rowData.jlql.logic : {"errors":[],"logic":{},"data":{}};
                queryValue.logic = cf.logic;
                self.setState({filterQuery: rowData.dql || {}, jlQuery: rowData.jlql && rowData.jlql.logic ? rowData.jlql : {"errors":[],"logic":{},"data":{}}})

                let currentDataset = null;

                let apis = JSON.parse(localStorage.getItem("ds_apis"));

                let dependents = [];
                apis.data.data.forEach((api) => {
                    if(api.filterId === self.props.did)
                        dependents.push(api);
                });
                self.setState({dependentApis: [...dependents]});

                self.state.datasets.forEach(dataset => {
                    if (dataset.collectionName === rowData.datasetId) {
                        self.setState({"currentDatasetRec": dataset})
                        currentDataset = dataset;
                        let perms = {role: self.state.currentUserRole}
                        if (!(JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount")))) {
                            if (!["admin", "owner"].includes(self.state.currentUserRole)){
                                perms = currentDataset.accessPermissions.find(permission => permission.personId === localStorage.getItem("uid"));
                                self.setState({currentUserRole: perms.role})
                            }
                        }
                    }
                })

                let schemaProperties = currentDataset.schema.properties
                let fData = self.setFields(schemaProperties);
                cf.fields = fData.cf.fields;
                let currentFields = [];
                fData.fieldData.forEach(column => {
                    if (!rowData.selectFields || rowData.selectFields.includes(column.name)){
                        currentFields.push(column);
                    }
                });
                if (currentFields.length === 0){
                    currentFields = fData.fieldData;
                }
                self.setState({currentFieldsRec: currentFields, tree: QbUtils.loadFromJsonLogic(cf.logic, cf),
                    content: rowData, currentDataset: rowData.datasetId })


                let currentSortByTemp = "";
                if (currentDataset) {
                    for (const [key, value] of Object.entries(schemaProperties).sort(([, v1], [, v2]) => v1["fieldId"] - v2["fieldId"])) {
                        if (key === rowData.sortBy) {
                            self.setState({currentSortByRec: {id: value.fieldId, name: value.fieldName}});
                            currentSortByTemp = {id: value.fieldId, name: value.fieldName}
                        }
                    }
                }


                //if (Object.keys(cf.logic)) {
                    let appendSort = "";
                    if (rowData.sortOrder && currentSortByTemp){
                        appendSort = "&sortBy=" + currentSortByTemp.name + "&sortDir=" + rowData.sortOrder;
                    }
                    req_generic_get_dataset_query(rowData.datasetId, JSON.stringify(rowData.dql || {}) + "&records=1000" + appendSort).then(function (result) {
                        if (result.status === 200) {
                            let cols = [];
                            let rows = [];

                            let currentColumns = [];
                            currentFields.forEach((column) => { currentColumns.push(column.name) })
                            let ocs = self.state.objectColumns;
                            if (currentDataset) {

                                for (const [key, value] of Object.entries(schemaProperties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])) {
                                    const commonVals = {key: value["fieldName"], name: value["label"], resizable: true, sortable: true, fieldType: value.fieldType,
                                        format: value.format || null}
                                    if(value.fieldType === "object") {
                                        ocs.push(value["fieldName"])
                                    }
                                    if (currentColumns.includes(key)) {


                                        cols.push({
                                            ...commonVals,
                                            key: value["fieldName"],
                                            headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                            name: value["label"],
                                            resizable: true,
                                            width: 150,
                                            formatter({ row }) {
                                                if (value.fieldType === "boolean") {
                                                    return (<div>{row[value["fieldName"]] ? "true" : "false"}</div>)
                                                } else {
                                                    if (row[value["fieldName"]]) {
                                                        return (<div>{(row[value["fieldName"]]).toString()}</div>);
                                                    } else {
                                                        return (<div>&nbsp;</div>);
                                                    }
                                                }
                                            }
                                        })

                                    }
                                }
                            }
                            self.setState({objectColumns: [...ocs]})
                            result.data.data.forEach(function (record) {
                                ocs.forEach(col => { record[col] = JSON.stringify(record[col]); })
                                rows.push(record);
                            });

                            let dObj = {};
                            dObj.columns = cols;
                            dObj.rows = rows;
                            self.setState({filterData: dObj, loading: false, dataLoaded: true});
                        } else {
                            self.setState({filterData: {}, loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
                        }
                    })
                //}
                self.setState({  loading: false});
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    loadDatasets = () => {
        let self = this;
        self.setState({ loading: true });
        let datasets = JSON.parse(localStorage.getItem("ds_datasets"));
        let rowrecs = [];
        datasets.data.data.forEach(function (record) {
            if (record.projectId) {
                rowrecs.push(record);
            }
        });
        self.setState({currentUserRole: datasets.data.role});
        self.setState({datasets: [...rowrecs], loading: false});
        self.loadRec();
    }

    handleChange = (field, e) => {
        let content = this.state.content;
        content[field] = e.target.value;
        this.setState({ content: {...content}});
    };

    selectSortBy = (e, newValue) => {
        this.setState({currentSortByRec: newValue})
        let sortOrder = "1";

        if (!this.state.sortOrder){
            let content = this.state.content;
            content.sortOrder = "1";
            this.setState({sortOrder: "1", content: {...content}});
        } else {
            sortOrder = this.state.sortOrder;
        }
        this.sendQuery(this.state.filterQuery, this.state.jlQuery, sortOrder, newValue.name);
    }

    selectSortOrder = (e) => {
        this.handleChange("sortOrder", e)
        this.setState({sortOrder: e.target.value})
        this.sendQuery(this.state.filterQuery, this.state.jlQuery, e.target.value, this.state.currentSortByRec.name);
    }

    setFields = (properties) => {

        let fieldData = [];
        let cf = {...this.state.config};
        cf.fields = {}

        for (const [key, value] of Object.entries(properties).sort(([, v1], [, v2]) => v1["fieldId"] - v2["fieldId"])) {

            fieldData.push({ id: value.fieldId, name: value.fieldName})
            if (!this.state.currentSortByRec && value.fieldName !== this.state.content.sortBy){
                this.setState({currentSortByRec: { id: value.fieldId, name: value.fieldName}});
            }
            cf.fields[key] = this.setObject(value);
        }
        return ({"fieldData": fieldData, "cf": cf})
    }

    selectDataset = (e, newValue) => {

        this.setState({showQueryEditor: false});
        let set = false;
        let properties = {};
        this.state.datasets.forEach(dataset => {
            if (newValue && (dataset.collectionName === newValue.id) && !set) {
                this.setState({"currentDataset": newValue.id, "currentDatasetRec": dataset, currentSortByRec: {...{}}, SortOrder: "1"})
                properties = dataset.schema.properties;
                set = true;
            }
        })
        this.setState({datasetProperties: properties, filterData: {...{columns:[], rows:[]}}})

        let cf = {...this.state.config};
        cf.fields = {}

        let fData = this.setFields(properties)
        let currentFields = [];

        fData.fieldData.forEach(column => {
            currentFields.push(column);
        });

        this.setState({config: {...fData.cf}, showQueryEditor: true, datasetColumns: [...fData.fieldData], currentFieldsRec: currentFields, currentSortByRec: fData.fieldData[0]});
    }

    setCUrrentColumns = () => {
        let currentColumns = [];
        this.state.currentFieldsRec.forEach((column) => {
            currentColumns.push(column.name)
        })
    }

    selectFields = (e, newValue) => {
        let cols = [];
        let filterData = this.state.filterData;

        newValue.forEach((field) => {
            cols.push({
                key: field.name,
                name: field.name,
                resizable: true,
                width: 130
            })
        })

        filterData.columns = [...cols];
        this.setState({filterData: {...filterData}, currentFieldsRec: [...newValue], dataLoaded: false});
        this.sendQuery(this.state.filterQuery, this.state.jlQuery, this.state.sortOrder, this.state.currentSortByRec.name);
    }

    setObject = (pObj) => {
        let base = {label: pObj.fieldName};
        base.valueSources = ['value'];

        if (pObj.type === "boolean"){
            base.type = "boolean";
        } else if (pObj.enum){
            base.type = "select";
            base.fieldSettings = {listValues :
                    pObj.enum.map(value => {
                        return { "value" : value, "title" : value }
                    })
            };
        } else if (pObj.type === "number"){
            base.type = "number";
        } else if (pObj.type === "string" && pObj.format === "date" && pObj.dateFormat === "date"){
            base.type = "date";
        } else if (pObj.type === "string" && pObj.format === "date" && pObj.dateFormat === "datetime"){
            base.type = "datetime";
        } else if (pObj.type === "string" && pObj.format === "date" && pObj.dateFormat === "time"){
            base.type = "time";
        } else if (pObj.type === "string" ){
            base.type = "text";
        }

        return base;
    }

    submitFormData = (e) => {
        e.preventDefault();

        let selectedFields = [];
        this.state.currentFieldsRec.forEach((field) => selectedFields.push(field.name))

        let body = {
            "datasetId": this.state.currentDataset,
            "title": this.state.content.title,
            "dql": this.state.filterQuery,
            "jlql": this.state.jlQuery,
            "sortBy": this.state.currentSortByRec.name,
            "sortOrder" : this.state.content.sortOrder || "1",
            selectFields: selectedFields
        }

        let self = this;
        self.setState({ loading: true });
        req_filter_put("filter", this.state.content.id, body).then(function (result) {
            if (result.status === 200) {
                self.toggleSettings(false);
                //self.refreshContent();
                self.setState({ loading: false });
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem saving the filter.", "type": "error", "timeout": 5000 }}});
            }
        })
    }

    onChange = (immutableTree, config) => {
        this.sendQuery(QbUtils.mongodbFormat(immutableTree, config), QbUtils.jsonLogicFormat(immutableTree, config), this.state.sortOrder, this.state.currentSortByRec.name);
    }

    sendQuery(mdbQuery, jlQuery, sortOrder, SortBy){

        this.setState({dataLoaded: false})
        if (mdbQuery){
            this.setState({ filterQuery: mdbQuery || {} , jlQuery: jlQuery, loading: true });
            let self = this;

            let appendSort = "";
            if (sortOrder && SortBy){
                appendSort = "&sortBy=" + SortBy + "&sortDir=" + sortOrder;
            }

            mdbQuery = (typeof mdbQuery === "object") ? JSON.stringify(mdbQuery) : mdbQuery;
            jlQuery = (typeof jlQuery === "object") ? JSON.stringify(jlQuery) : jlQuery;

            req_generic_get_dataset_query(this.state.currentDataset, mdbQuery + "&records=1000" + appendSort).then(function (result) {
                if (result.status === 200) {
                    let cols = [];
                    let rows = [];

                    let currentColumns = [];
                    self.state.currentFieldsRec.forEach((column) => {
                        currentColumns.push(column.name)
                    })

                    for (const [key, value] of Object.entries(self.state.currentDatasetRec.schema.properties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])) {
                        if (currentColumns.includes(key)) {
                            cols.push({
                                key: value["fieldName"],
                                name: value["label"],
                                resizable: true,
                                width: 130
                            })
                        }
                    }

                    result.data.data.forEach(function (record) {
                        self.state.objectColumns.forEach(col => { record[col] = JSON.stringify(record[col]); })
                        rows.push(record);
                    });

                    let dObj = result.data;
                    dObj.columns = cols;
                    dObj.rows = rows;
                    self.setState({filterData: dObj, loading: false, dataLoaded: true});
                } else {
                    self.setState({filterData: {}, loading: false, dataLoaded: true, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
                }
            })
        }
    }

    componentDidMount() { this.loadDatasets(); }
    refreshContent() { this.loadDatasets(); }
    purgeAlert = () => { this.setState({alert: {...{ open: false, message: "", type: "" }}}) }
    toggleSettings = (open) => { this.setState({"openSettings": open}); };

    render() {
        return (
            <Paper sx={{ width: '100%', marginTop: 0, boxShadow: 'none' }}>
                <div style={{"display": "flex", "justifyContent": "space-between", "backgroundColor": "#e5e5e5", height: "36px", borderBottom: "1px solid #ccc", paddingLeft: "10px"}}>
                    <BackdropAppear loading={this.state.loading || false} />
                    <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>

                    {this.state && ["owner", "admin", "editor"].includes(this.state.currentUserRole) ?
                    <div style={{marginRight: "10px", paddingLeft: "5px", paddingBottom: "5px", fontSize: ".9rem"}}>
                        <b>Filter</b>: {this.state ? this.state.content.title : ""}

                        <Button
                            sx={{ "& .MuiButton-startIcon": { marginRight: "3px" }}}
                            onClick={() => {this.toggleSettings(!this.state.openSettings)}}
                            aria-label="settings"
                            variant={"text"}
                            style={{color: "#556677", marginLeft: "10px", fontWeight: "400"}}
                            startIcon={<SettingsIcon style={{fontSize: "16px"}}/>}>Settings</Button>

                    </div>:
                    <div style={{marginRight: "10px", paddingLeft: "5px", paddingBottom: "5px", fontSize: ".9rem"}}> <b>Filter</b>: {this.state ? this.state.content.title : ""}
                        <div style={{height: "36px", lineHeight: "36px", display: "inline-block", marginLeft: "10px", fontWeight: "bold"}}>[ View Only ]</div>













                    </div>}



                        {this.state && this.state.dependentApis.length > 0 ?
                            <Button size="small"
                                    ref={this.state.apiMenuRef}
                                    onClick={() => {
                                        this.setState({openApiMenu: true})
                                    }}
                                    variant={"text"}
                                    style={{paddingTop: 0, paddingBottom: 0, color: "#5f6368", marginRight: "10px"}}
                                    startIcon={<FilterAltIcon/>}>Dependent APIs</Button> : null
                        }
                        <Popper
                            open={this.state.openApiMenu}
                            style={{"zIndex": 1}}
                            anchorEl={this.state.apiMenuRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => {
                                            this.setState({openApiMenu: false})
                                        }}>
                                            <MenuList
                                                id="composition-menu"
                                                aria-labelledby="composition-button"

                                            >
                                                {this.state.dependentApis.map(api =>
                                                    < MenuItem onClick={(e) => {
                                                        this.props.openItem("apiwebhook", {
                                                            name: api.name,
                                                            id: api.id
                                                        })
                                                    }}>{api.name}</MenuItem>
                                                )
                                                }

                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>

                </div>

                <div style={{position: "relative", overflow: "hidden"}}>
                <Drawer
                    transitionDuration={22}
                    sx={{
                        '& .MuiDrawer-root': {
                            position: 'absolute',
                            backgroundColor: "#f4f4f4",
                            boxShadow: "0 4px 2px -2px #ccddee70"
                        },
                        '& .MuiPaper-root': {
                            position: 'absolute',
                            backgroundColor: "#f4f4f4",
                            boxShadow: "0 4px 2px -2px #ccddee70",
                            borderLeft: "1px solid #bbb",
                            borderBottom: "1px solid #bbb"
                        }
                    }}

                    variant="persistent"
                    anchor="right"
                    open={this.state.openSettings}
                    onClose={() => {this.toggleSettings(false)}}
                >

                    <Box sx={{ width: 'intrisic',
                             padding: "10px",
                             textAlign: "left"
                         }} component="form" onSubmit={this.submitFormData}>
                        <Typography align={"left"} style={{fontWeight: "bold", fontSize: "1rem"}}>
                            <IconButton onClick={() => this.toggleSettings(false)}><ExitToAppIcon style={{fontSize: "1.3rem"}} /></IconButton>Filter Settings</Typography>
                        <div style={{marginTop: "10px", display: "flex", flexDirection: "column", width: 487}}>
                            <div >
                                <TextField
                                    label={"Filter Name"}

                                    sx={{marginBottom: 2}} fullWidth
                                    value={this.state.content["title"] || ''}
                                    required={true}
                                    onChange={(e) => {
                                        this.handleChange("title", e)
                                    }}
                                />
                            </div>
                            <div >
                                <AutocompleteSelect

                                    rows={this.state.datasets}
                                    selectedRecord={this.state.currentDatasetRec || null}
                                    selectLabel={"Select a Dataset to Filter"}
                                    selectValue={ (e, newValue) => {
                                        this.selectDataset(e, newValue);
                                        this.sendQuery(this.state.filterQuery, this.state.jlQuery, this.state.sortOrder);
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: 487}}>
                            <div style={{ display: "flex"}}>
                                <div style={{width: "60%", paddingRight: "5px"}}>
                                    <AutocompleteSelect
                                        rows={this.state.datasetColumns}
                                        selectedRecord={this.state.currentSortByRec || null}
                                        selectLabel={"Sort By"}
                                        selectValue={this.selectSortBy}
                                    />
                                </div>
                                <div style={{width: "40%", paddingLeft: "5px"}}>
                                    <div>
                                        <Select

                                            style={{width: "100%"}}
                                            labelId={"field_sortorder_label_"}
                                            id={"field_sortorder_label_"}
                                            label="Sort Order"
                                            value={this.state.content.sortOrder || ""}
                                            onChange={this.selectSortOrder}
                                        >
                                            <MenuItem value="1">Ascending (ASC)</MenuItem>
                                            <MenuItem value="-1">Descending (DESC)</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </div>

                            {this.state.datasetColumns && this.state.currentFieldsRec ?
                                <div >
                                    <AutocompleteSelectMulti
                                        size={"small"}
                                        rows={this.state.datasetColumns}
                                        selectedRecords={this.state.currentFieldsRec || []}
                                        selectLabel={"Include Fields in Result"}
                                        selectValue={this.selectFields}
                                    />
                                </div> : null
                            }

                        </div>
                        {this.state && this.state.showQueryEditor ?
                            <Query
                                {...this.state.config}
                                value={this.state.tree}
                                onChange={this.onChange}
                                renderBuilder={this.renderBuilder}
                            />: null
                        }

                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button type="submit" variant="contained" size="small">Save</Button>
                            <Button variant="contained" size="small" style={{marginLeft: "5px"}} onClick={() => {
                                this.toggleSettings(false);
                                this.refreshContent();
                            }}>Cancel</Button>
                        </div>
                    </Box>
                </Drawer>

                    <div style={{padding: "10px", textAlign: "left", display: "flex", flexDirection: "column", minHeight: window.innerHeight - 126}}>
                        <h3 id="parent-modal-title">Filter Results (to 1000 records)</h3>
                        <div style={{fontSize: ".75rem", backgroundColor: "#f7f7f7", border: "1px solid #E6E8F0", borderRadius: "3px", marginTop: "10px", padding: "10px"}}>

                            <div>
                                <div><b>Dataset</b>: {this.state.currentDatasetRec ? this.state.currentDatasetRec.name : ""}</div>
                                <div><b>Sort By</b>: {this.state.content && this.state.content.sortBy ? this.state.content.sortBy : "-- none set --"}</div>
                                <div><b>Sort Order</b>: {this.state.content && this.state.content.sortOrder ? this.state.content.sortOrder : "-- none set --"}</div>
                                <div><b>Fields Included</b>: {this.state.content && this.state.content.selectFields ? this.state.content.selectFields.join(", ") : ""}</div>
                                <div><b>Query</b>: {this.state.content && this.state.content.jlql ? JSON.stringify(this.state.content.jlql.logic) : ""}</div>

                            </div>
                        </div>


                        {this.state.filterData.rows.length > 0 ?
                            <div style={{marginTop: "15px"}}>
                            <DataGrid
                                style={{height: window.innerHeight - 300, overflow: "scrollable", boxShadow: "none", border: 0}}
                                className="fill-grid"

                                columns={this.state.filterData.columns}
                                direction={'ASC'}
                                rows={this.state.filterData.rows}
                                rowHeight={33}
                            >
                            </DataGrid>
                            </div>:
                            <div>
                            { this.state.dataLoaded && this.state.filterData.rows.length === 0 ?
                                        <div style={{marginTop: "10px", minHeight: "200px"}}>No records found with current filter.</div> :
                                <div style={{marginTop: "10px"}}>
                                    <LinearProgress />
                                </div>
                            }
                            </div>
                        }
                    </div>
                </div>

            </Paper>
        )
    }
}