import React, {Component} from 'react'
import Paper from '@mui/material/Paper';
import {JsonArray, download} from "json-to-csv-in-browser"
import AddBoxIcon from '@mui/icons-material/AddBox';
import SettingsIcon from "@mui/icons-material/Settings";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import {CSVLink} from 'react-csv';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    STD_ALERT_TIMEOUT,
    GENERIC_LOAD_ERROR,
    DATASET_DELETE_COLUMN_SUCCESS,
    DATASET_DELETE_COLUMN_FAILURE,
    DATASET_ADD_COLUMN_SUCCESS,
    DATASET_ADD_COLUMN_FAILURE,
    DATASET_CLONE_COLUMN_SUCCESS,
    DATASET_CLONE_COLUMN_FAILURE
} from "../../helpers/strConstants";
import {
    req_generic_delete, req_generic_purge, req_generic_get_list, req_generic_post,
    req_generic_put, req_generic_get_dataset, req_generic_dataset_put,
    req_upload_dataset_csv, req_generic_dataset_post, req_delete_dataset_column,
    req_add_dataset_column, req_clone_dataset_column
} from "../../helpers/requests";
import {
    TextFormatter,
    BigTextFormatter,
    JsonFormatter,
    DecimalFormatter,
    IntegerFormatter,
    DateTimeFormatter,
    DateFormatter,
    TimeFormatter,
    dropDownEditor,
    getColumnImage
} from "../../helpers/dataViewMethods";
import 'react-data-grid/lib/styles.css';
import DataGrid, {Row as GridRow, SelectCellFormatter } from 'react-data-grid';
import './dataConsoleData.css';
import IconButton from "@mui/material/IconButton";
import {TextField, Drawer, Stack, InputLabel, Select, Popper, Grow, ClickAwayListener, MenuList} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Autocomplete} from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import BackdropAppear from "../staticDisplays/backdropAppear";
import LinearProgress from '@mui/material/LinearProgress';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import TemplateFieldsPanel from "../formControls/templateFieldsPanel";
import ColumnDeleteDialog from "../dialogs/columnDeleteDialog";
import DialogAppBar from "../dialogs/dialogAppBar";
import NewColumnDialog from "../dialogs/newColumnDialog";
import ColumnCloneDialog from "../dialogs/columnCloneDialog";
import eventBus from "../../helpers/eventBus";


/*
function sortStatus({ sortDirection, priority }) {
    return (
        <>
            {sortDirection !== undefined ? (sortDirection === 'ASC' ? '\u2B9D' : '\u2B9F') : null}
            <span style={{color: "grey", marginLeft: "2px"}}>{priority}</span>
        </>
    );
}
*/

function onEditorNavigation({ key, target }: React.KeyboardEvent<HTMLDivElement>): boolean {

    if (
        key === 'Tab' &&
        (target instanceof HTMLInputElement ||
            target instanceof HTMLTextAreaElement ||
            target instanceof HTMLSelectElement)
    ) {
        return target.matches(
            '.rdg-editor-container > :only-child, .rdg-editor-container > label:only-child > :only-child'
        );
    }
    return false;
}

function rowRenderer(key, props) {
    return (
        <GridRow key={key} {...props} onContextMenuCapture={props.onContextMenu}
                 onContextMenu={(e) => {
                     e.preventDefault();
                     props.onRowClick(e, "context-menu", props);
                 }}
        />
    );
}

export default class DataConsoleData extends Component {
    state = {
        columns: [],
        gridColumns: [],
        page: 0,
        rowsPerPage: 10,
        rows: [],
        gridRows: [],
        schema: {},
        dataLoaded: false,
        loading: false,
        objectDefaults: {},
        recsLoaded: false,
        selectedRows: [],
        sortColumns: [],
        direction: 'ASC',
        contextMenu: null,
        optionsMenu: null,
        columnOptionsMenu: null,
        columnDeleteOpen: false,
        datasetContextData: {},
        optionContextData: {},
        columnOptionContextData: {},
        uploadOpen: false,
        openSettings: false,
        projects: [],
        content: {},
        csvColumns: [],
        deleteOpen: false,
        templateOpen: false,
        allowDelete: false,
        gridRef: null,
        alert: { open: false, message: "", type: "" },
        writePreference: "append",
        deleteColumnKey: "",
        newColumnOpen: false,
        setInsertRef: false,
        currentUserRole: (JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))) ? "owner": "viewer",
        filterMenuRef: React.createRef(),
        openFilterMenu: false,
        dependentFilters: [],
        objectColumns: [],

    };

    gridRef = React.createRef()

    idColumnRenderer = () => {
        return (<div>
            <IconButton size="small" disableRipple={true} style={{paddingTop: 0}} onClick={this.openOptionsMenu}>
                <MoreVertIcon style={{color: "#406cbf", fontSize: 18}} />
            </IconButton>
        </div>);
    }

    standardColumnRenderer = (p) => {
        return (<div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <div style={{paddingLeft: "5px", lineHeight: "28px", verticalAlign: "middle"}}>{p.column.name}
                <div className={"columnRenderImageContent"}>
                    {getColumnImage(p.column.fieldType, p.column.format || null, p.column.dateFormat || null)}
                </div>
            </div>

            { p.column.editable ?
            <div style={{textAlign: "right"}}>
                <IconButton size="small" disableRipple={true} style={{paddingTop: 0}}
                            onClick={(e) =>
                                this.openMenu(e, null, p.column.key, "columnOptionsMenu", "columnOptionContextData" )
                            }>
                    <MoreVertIcon style={{color: "#406cbf", fontSize: 18}} />
                </IconButton>
            </div> : null
            }
        </div>);
    }

    openOptionsMenu = (e, id, name) => {
        this.openMenu(e, id, name, "optionsMenu", "optionContextData" )
    }

    openMenu = (e, id, name, optionsMenu, optionContextData) => {
        e.preventDefault();
        this.setState({
            [optionsMenu]: this.state[optionsMenu] === null
                ? {
                    mouseX: e.clientX + 2, mouseY: e.clientY - 6,
                } : null
        })
        this.setState({[optionContextData]: {id: id, name: name}});
    }

    setUploadOpen = (visible) => { this.setState({ uploadOpen: visible }); }
    handleUploadClose = () => { this.setUploadOpen(false); };
    handleDeleteColumnClose = () => { this.setState({columnDeleteOpen: false }); }
    handleCloneColumnClose = () => { this.setState({columnCloneOpen: false }); }
    handleDeleteClose = () => { this.setState({deleteOpen: false}) }
    handleTemplateClose = () => { this.setState({templateOpen: false}) }
    handleNewColumnClose = () => { this.setState({newColumnOpen: false}) }
    handleOptionsMenuClose = () => { this.setState({optionsMenu: null}); }
    handleColumnOptionsMenuClose = () => { this.setState({columnOptionsMenu: null}); }

    loadProjects = () => {

        this.setState({ loading: true });
        let projects = JSON.parse(localStorage.getItem("ds_projects"));
        let filters = JSON.parse(localStorage.getItem("ds_filters"));

        let dependents = [];
        filters.data.data.forEach((filter) => {
            if(filter.datasetId === this.props.did)
                dependents.push(filter);
        });
        this.setState({dependentFilters: [...dependents]});

        let rowRecs = [];
        projects.data.data.forEach(function (record) {
                if (JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))){
                    rowRecs.push(record);
                } else if (record.hasOwnProperty("accessPermissions")){
                    let rec = record.accessPermissions.filter(perm  => {
                        return perm.personId === localStorage.getItem("uid") && perm.role !== "viewer"
                    })
                    if (rec.length > 0){ rowRecs.push(record); }
                }
        });
        this.setState({projects: [...rowRecs], loading: false });
    }

    loadRec = () => {
        let self = this;
        let cols = [];
        self.setState({ loading: true });
        req_generic_get_dataset( this.props.did).then(function (result) {
            if (result.status === 200) {
                console.log(result.data)
                if (JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))) {
                    self.setState({currentUserRole: 'owner'})
                } else if(result.data.role === "admin") {
                    self.setState({currentUserRole: 'admin'})
                } else if(result.data.data.hasOwnProperty("accessPermissions"))  {
                    const perms = result.data.data.accessPermissions.find(permisssion => permisssion.personId === localStorage.getItem("uid"));
                    self.setState({currentUserRole: perms.role})
                }
                for (const [key,] of Object.entries(result.data.data.schema.properties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])) { cols.push(key) }
                self.state.projects.forEach(project => {
                    if (project.id === result.data.data.projectId) {
                        self.setState({"currentProjectRec": project})
                    }
                })
                self.setState({content: result.data.data, selectedProject: result.data.data.projectId, loading: false, csvColumns: cols});
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": STD_ALERT_TIMEOUT }}});
            }
        })
    }



    cloneColumn = (original_column, new_column) => {
        let self = this;
        let body = {
            original: original_column,
            new: new_column
        };
        self.setState({ loading: true });
        req_clone_dataset_column(this.props.did, body).then(function (result) {
            if (result.status === 200) {
                self.loadRecs()
                self.setState({
                    loading: false,
                    alert: {...{
                            "open": true, "message": DATASET_CLONE_COLUMN_SUCCESS,
                            "type": "success", "timeout": STD_ALERT_TIMEOUT
                        }
                    }
                });
            } else {
                self.setState({
                    loading: false,
                    alert: {...{
                            "open": true, "message": DATASET_CLONE_COLUMN_FAILURE,
                            "type": "error", "timeout": STD_ALERT_TIMEOUT
                        }
                    }
                });
            }
        })

    }

    deleteColumn = () => {

        let self = this;
        let body = {
            name: this.state.columnOptionContextData.name
        };
        self.setState({ loading: true });
        req_delete_dataset_column(this.props.did, body).then(function (result) {
            if (result.status === 200) {
                self.loadRecs()
                self.setState({
                    loading: false,
                    alert: {...{
                            "open": true, "message": DATASET_DELETE_COLUMN_SUCCESS,
                            "type": "success", "timeout": STD_ALERT_TIMEOUT
                        }
                    }
                });
            } else {
                self.setState({
                    loading: false,
                    alert: {...{
                            "open": true, "message": DATASET_DELETE_COLUMN_FAILURE,
                            "type": "error", "timeout": STD_ALERT_TIMEOUT
                        }
                    }
                });
            }
        })
    }

    isNewRequired = (row, result, value) => {
        return (row.id.includes("new_") && result.data.schema.schema.required.includes(value["fieldName"]));
    }

    isFieldRequired = (result, value) => {
        return result.data.schema.schema.required.includes(value["fieldName"]);
    }

    isEditable = () => {
        return this.state && ["owner", "admin", "editor"].includes(this.state.currentUserRole)
    }


    loadRecs = () => {

        let self = this;
        self.setState({ loading: true });
        req_generic_get_list(this.props.did).then(function (result) {
            if (result.status === 200) {
                let rowRecs = [];
                let gCols = [];
                let enums = {};
                let content = {};
                let schemaProps = result.data.schema.schema;
                self.setState({ schema: {...schemaProps}, objectDefaults: {...content} });

                gCols.push({
                    key: "sd_id",
                    name: "",
                    cellClass(row) { return "idColumn" },
                    headerRenderer: (p) => self.idColumnRenderer({...p}),
                    width:"40px",
                    minWidth: "40",
                    frozen: true,
                })

                for (const [, value] of Object.entries(result.data.schema.schema.properties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])) {

                    const commonVals = {key: value["fieldName"], name: value["label"], resizable: true, sortable: true, fieldType: value.fieldType,
                    format: value.format || null, dateFormat: value.dateFormat || null}
                    if (!value.enum) {

                        if (value.fieldType === "decimal") {
                            gCols.push({
                                maxWidth: "200px",
                                width: "170px",
                                ...commonVals,
                                editable: self.isEditable(),
                                editor: DecimalFormatter,
                                editorOptions:{onNavigation(e) { return e.key.toLowerCase() === 'tab' || e.key.startsWith('Arrow'); }},
                                headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                cellClass(row) {
                                    if (
                                        (self.isNewRequired(row, result, value) && !row[value["fieldName"]]) ||
                                        (self.isFieldRequired(result, value) && !(typeof row[value["fieldName"]] === "number"))
                                    ) { return "requiredCell"; }
                                }
                            })
                        } else if (value.fieldType === "int") {
                            gCols.push({
                                ...commonVals,
                                width: "170px",
                                maxWidth: "200px",
                                editable: self.isEditable(),
                                editor: IntegerFormatter,
                                editorOptions:{onNavigation(e) { return e.key.toLowerCase() === 'tab' || e.key.startsWith('Arrow'); }},
                                headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                cellClass(row) {
                                    if (
                                        (self.isNewRequired(row, result, value) && !row[value["fieldName"]])
                                        || (self.isFieldRequired(result, value) && !(Number.isInteger(row[value["fieldName"]])))
                                    ) { return "requiredCell"; }
                                }
                            })
                        } else if (value.type === "string" && value.format === "date" && value.dateFormat === "date") {
                            gCols.push({
                                ...commonVals,
                                width: "170px",
                                maxWidth: "200px",
                                editable: self.isEditable(),
                                editor: DateFormatter,
                                editorOptions:{onNavigation(e) { return e.key.toLowerCase() === 'tab' || e.key.startsWith('Arrow'); }},
                                headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                cellClass(row) {
                                    if (
                                        (self.isNewRequired(row, result, value) && !row[value["fieldName"]])
                                        || (self.isFieldRequired(result, value) && !row[value["fieldName"]])
                                    ) { return "requiredCell"; }
                                }
                            })
                        } else if (value.type === "string" && value.format === "date" && value.dateFormat === "datetime") {
                            gCols.push({
                                width: "190px",
                                maxWidth: "200px",
                                ...commonVals,
                                editorOptions:{onNavigation(e) { return e.key.toLowerCase() === 'tab' || e.key.startsWith('Arrow'); }},
                                editable: self.isEditable(),
                                editor: DateTimeFormatter,
                                headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                cellClass(row) {
                                    if (
                                        (self.isNewRequired(row, result, value) && !row[value["fieldName"]])
                                        || (self.isFieldRequired(result, value) && !row[value["fieldName"]])
                                    ) { return "requiredCell"; }
                                }
                            })
                        } else if (value.type === "string" && value.format === "date" && value.dateFormat === "time") {
                            gCols.push({
                                width: "170px",
                                maxWidth: "200px",
                                ...commonVals,
                                editorOptions:{onNavigation(e) { return e.key.toLowerCase() === 'tab' || e.key.startsWith('Arrow'); }},
                                editable: self.isEditable(),
                                editor: TimeFormatter,
                                headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                cellClass(row) {
                                    if (
                                        (self.isNewRequired(row, result, value) && !row[value["fieldName"]])
                                        || (self.isFieldRequired(result, value) && !row[value["fieldName"]])
                                    ) { return "requiredCell"; }
                                }
                            })
                        } else if (value.fieldType === "boolean") {

                            gCols.push({
                                ...commonVals,
                                width: "170px",
                                maxWidth: "200px",
                                editable: self.isEditable(),
                                editorOptions:{onNavigation(e) { return e.key.toLowerCase() === 'tab' || e.key.startsWith('Arrow'); }},
                                headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                formatter({ row, onRowChange, isCellSelected }) {

                                    return (
                                        <SelectCellFormatter
                                            value={row[value["fieldName"]]}
                                            onChange={() => {
                                                onRowChange({ ...row, [value["fieldName"]]: !row[value["fieldName"]] });
                                            }}
                                            isCellSelected={isCellSelected}
                                        />
                                    );
                                },
                                cellClass(row) {}
                            })
                        } else if(value.fieldType === "object") {
                            let ocs = self.state.objectColumns;
                            ocs.push(value["fieldName"])
                            self.setState({objectColumns: [...ocs]})
                            gCols.push({
                                ...commonVals,
                                width: "250px",
                                maxWidth: "500px",
                                editable: self.isEditable(),
                                editorOptions:{
                                    editOnClick: false,
                                    commitOnOutsideClick: false,
                                    onNavigation(e) { return e.key.toLowerCase() === 'tab' || e.key.startsWith('Arrow'); }
                                },
                                editor: JsonFormatter,
                                headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                cellClass(row) {
                                    return (
                                        (self.isNewRequired(row, result, value) && !row[value["fieldName"]])
                                        || (self.isFieldRequired(result, value) && !row[value["fieldName"]])
                                    ) ? "requiredCell" : "standardCell";
                                }
                            })
                        } else {
                            gCols.push({
                                ...commonVals,
                                width: "250px",
                                maxWidth: "500px",
                                editable: self.isEditable(),
                                editorOptions:{
                                    editOnClick: false,
                                    commitOnOutsideClick: false,
                                    onNavigation(e) { return e.key.toLowerCase() === 'tab' || e.key.startsWith('Arrow'); }
                                    },
                                editor: ["multiline", "markdown", "xml"].includes(value.format) ? BigTextFormatter: TextFormatter,
                                headerRenderer: (p) => self.standardColumnRenderer({...p}),
                                cellClass(row) {
                                    return (
                                        (self.isNewRequired(row, result, value) && !row[value["fieldName"]])
                                        || (self.isFieldRequired(result, value) && !row[value["fieldName"]])
                                    ) ? "requiredCell" : "standardCell";
                                }
                            })
                        }

                    } else {
                        commonVals["fieldType"] = "options"
                        let cData = [];
                        value.enum.forEach((v) => {
                                cData.push({ value: v, label: v })
                        })

                        enums[value["fieldName"]] = cData;

                        gCols.push({
                            ...commonVals,
                            width: "250px",
                            maxWidth: "400px",
                            key:value["fieldName"],
                            name: value["label"],
                            editable: self.isEditable(),
                            resizable: true,
                            sortable: true,
                            enum: cData,
                            headerRenderer: (p) => self.standardColumnRenderer({...p}),
                            editor: dropDownEditor
                        })
                    }
                }

                self.setState({ gridColumns: [...gCols] });
                let rowNum = 1;
                result.data.data.forEach(function (record) {
                    self.state.objectColumns.forEach(col => {
                            record[col] = JSON.stringify(record[col]);
                        }
                    )
                    record["sd_id"] = rowNum;
                    rowRecs.push(record);
                    rowNum++;


                });

                self.setState({ rows: [...rowRecs], recsLoaded: true, dataLoaded: true, loading: false });
            } else {
                console.log("failure");
                self.setState({ loading: false });
            }
        })
    }

    selectTextEditor = (value) => {
        if (value.format === "multiline")
            return BigTextFormatter;
        else if (value.format === "markdown")
            return BigTextFormatter;
        else
            return TextFormatter;

    }

    rowKeyGetter(row) { return row.id; }

    updateRows(rows, operation){

        let currentRows = this.state.rows;
        operation.indexes.forEach((ind) => {
            currentRows[ind] = {...rows[ind]}
            this.createUpdate({}, rows[ind], ind, rows[ind].id)
        });
        this.setState({rows: currentRows});
    }

    selectProject = (e, newValue) => {
        if (newValue.id){
            this.setState({selectedProject: newValue.id});
        }
        let set = false;
        this.state.projects.forEach(project => {
            if (newValue && (project.id === newValue.id) && !set) {
                this.setState({"currentProjectRec": project})
                set = true;
            }
        })
    }

    handleCopy({ sourceRow, sourceColumnKey }): void {
        if (window.isSecureContext) {
            navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
        }
    }

    handleNewColumnSave = (columnObject) => {
        let self = this;
        let body = columnObject.dObj;

        req_add_dataset_column(this.props.did, body).then(function (result) {
            if (result.status === 200) {
                self.loadRecs()
                self.setState({
                    loading: false,
                    alert: {...{
                            "open": true, "message": DATASET_ADD_COLUMN_SUCCESS,
                            "type": "success", "timeout": STD_ALERT_TIMEOUT
                        }
                    }
                });
                eventBus.dispatch("updateData", { itemType: "dataset"});
            } else {
                self.setState({
                    loading: false,
                    alert: {...{
                            "open": true, "message": DATASET_ADD_COLUMN_FAILURE,
                            "type": "error", "timeout": STD_ALERT_TIMEOUT
                        }
                    }
                });
            }
        })
    }

    handleTemplateSave = () => {
        let self = this;

        let templateBody = {
            name: self.state.content.templateName,
            permission: self.state.content.createTemplate === "private_template" ? "private" : "community",
            notes: self.state.content.templateNotes,
            tags: self.state.content.templateTags,
            schema: {
                "type": "object",
                "properties": self.state.content.schema.properties,
                "required": self.state.content.schema.required,
                "additionalProperties": true
            }
        }
        self.setState({ loading: true })
        req_generic_dataset_post("template", templateBody).then(function (result) {
            if (result.status === 200) {
                self.setState({
                    loading: false,
                    alert: {...{
                            "open": true, "message": "Your template was created and is accessible in dataset creation process.",
                            "type": "success", "timeout": STD_ALERT_TIMEOUT
                        }
                    }
                });

                self.handleTemplateClose()
            } else {
                self.setState({
                    loading: false,
                    alert: {...{
                            "open": true, "message": "There was a problem saving the template.",
                            "type": "error", "timeout": STD_ALERT_TIMEOUT
                        }
                    }
                });
            }
        })
    }

    handleTagSelect = (e) => {
        let content = this.state.content;
        content.templateTags = e;
        this.setState({content: {...content}});
    }

    handlePaste({
                    sourceColumnKey,
                    sourceRow,
                    targetColumnKey,
                    targetRow
    }: PasteEvent<Row>): Row {

        if (
            sourceColumnKey === 'avatar' ||
            ['id', 'avatar'].includes(targetColumnKey) ||
            ( sourceColumnKey !== targetColumnKey)) {
            return targetRow;
        }

        return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }

    handleFill({ columnKey, sourceRow, targetRow }) {
        return { ...targetRow, [columnKey]: sourceRow[columnKey] };
    }

    saveDataset = (e) => {
        e.preventDefault();
        let self = this;

        let body = {
            "name": this.state.content.name,
            "projectId": this.state.selectedProject,
            "schema": this.state.content.schema
        };
        self.setState({ loading: true });
        req_generic_dataset_put(this.props.did, body).then(function(result) {
            if (result.status === 200){
                self.loadRec();
                self.setState({ loading: false });
                self.toggleSettings(false);
            } else {
                self.setState({ loading: false, alert: {...{ "open": true, "message": "There was a problem saving the dataset.", "type": "error", "timeout": STD_ALERT_TIMEOUT }}});
            }
        })
    }

    handleFile = (e) => {
        this.setState({uplFile: e})
        this.setState({filename: e.target.value.split("\\")[(e.target.value.split("\\")).length - 1]})
    }

    submitFile = () => {
        let formData = new FormData();
        formData.append("file", this.state.uplFile.target.files[0]);
        formData.append("preference", this.state.writePreference);
        formData.append("account", JSON.parse(localStorage.getItem("selectedAccount")));
        let self = this;
        req_upload_dataset_csv(this.props.did, formData).then((result) => {
            if (result.status === 200) {
                console.log("pass")
                self.reloadData();
                self.handleUploadClose();
            } else {
                console.log("fail")
                self.handleUploadClose();
            }
        })
    }

    newRowID = () => { return "new_" + (Math.floor(Math.random() * (999999 - 1 + 1)) + 1) }

    cloneRow = (objId) => {
        let rows = this.state.rows;
        let row = {...rows.find(a => a.id === objId)};
        delete row["sd_id"];
        row["id"] = this.newRowID();
        rows.push(row)

        this.setState({rows: [...rows], setInsertRef: true});
        this.createUpdate({}, rows[rows.length-1], rows.length-1, rows[rows.length-1].id)
    }

    insertRow = () => {

        let rows = this.state.rows;
        let tempId = this.newRowID();
        const rowObj = {id: tempId}
        for (const [key, value] of Object.entries(this.state.schema.properties)){
            let todayDate = new Date().toISOString().slice(0, 10);
            if (value["dateFormat"] && value["dateFormat"] === "date" && value["defaultCurrentDate"]){
                rowObj[key] = todayDate
            } else if (value["dateFormat"] && value["dateFormat"] === "datetime" && value["defaultCurrentDate"]){
                todayDate = new Date().toISOString().slice(0, 16);
                rowObj[key] = todayDate
            } else if (value["dateFormat"] && value["dateFormat"] === "time" && value["defaultCurrentDate"]){
                todayDate = new Date().toISOString().slice(11, 16);
                rowObj[key] = todayDate
            } else if (value["type"] && value["type"] === "boolean" && value["default"]){
                rowObj[key] = true;
            }
        }

        rows.push(rowObj)
        this.setState({rows: [...rows], setInsertRef: true});
        this.createUpdate({}, rows[rows.length-1], rows.length-1, rows[rows.length-1].id)
    }

    componentWillUpdate = () => {
        if (this.state.setInsertRef){
            this.gridRef.current.scrollToColumn(1)
            this.gridRef.current.scrollToRow(this.state.rows.length-1)
            this.gridRef.current.selectCell({ idx: 1, rowIdx: this.state.rows.length-1}, false)
            this.setState({setInsertRef: false})
        }
    }

    createUpdate = (event, formdata, ind, objId=null) => {
        let ignoreFields = ["id","isoDate", "isoDateUpdated", "owner"];
        let self = this;
        let body = {};
        self.setState({ loading: false });
        let missingRequired = true;

        if (this.state.schema.required.length > 0){
            let fulfilled = 0;
            this.state.schema.required.forEach((required) => {
                if (formdata[required] && formdata[required].toString() !== ""){
                    fulfilled++;
                }
            })
            if  (this.state.schema.required.length === fulfilled){
                missingRequired = false;
            }
        } else {
            missingRequired = false;
        }


        if (!missingRequired) {
            for (const [key, value] of Object.entries(formdata)) {
                if (!ignoreFields.includes(key)) {

                    if (this.state.schema.properties[key] && this.state.schema.properties[key].type && this.state.schema.properties[key].type === "boolean") {
                        body[key] = (value === true || value === "true");
                    } else if (this.state.schema.properties[key] && this.state.schema.properties[key].type && this.state.schema.properties[key].type === "string" && this.state.schema.properties[key].format === "date") {
                        body[key] = value;
                    } else if (this.state.schema.properties[key] && this.state.schema.properties[key].type && this.state.schema.properties[key].type === "number") {
                        if (this.state.schema.properties[key].fieldType === "int") {
                            body[key] = parseInt(value)
                        } else if (this.state.schema.properties[key] && this.state.schema.properties[key].fieldType === "decimal") {
                            body[key] = parseFloat(value)
                        }
                    } else if (this.state.schema.properties[key] && this.state.schema.properties[key].enum) {
                        body[key] = value;
                    } else if (this.state.schema.properties[key] && this.state.schema.properties[key].type === "object") {
                        body[key] = JSON.parse(value);
                    } else {
                        body[key] = value ? value : "";
                    }
                }
            }

            if (objId !== null && !objId.includes("new_")) {
                delete body["id"];
                req_generic_put("dataset", this.props.did, objId, body).then(function (result) {
                    if (result.status === 200) {
                        self.setState({loading: false});
                    } else {
                        self.setState({loading: false});
                    }
                })
            } else {
                req_generic_post("dataset", this.props.did, body).then(function (result) {
                    if (result.status === 200) {
                        let rows = self.state.rows;
                        rows[ind]["id"] = result.data.id;
                        self.setState({rows: [...rows], loading: false});
                    } else {
                        self.setState({loading: false});
                    }
                })
            }
            if (event.target) { event.target.reset(); }
        } else {
            self.setState({ alert: {...{ "open": true, "message": "Some fields have missing or misformatted values.", "type": "error", "timeout": STD_ALERT_TIMEOUT }}});
        }

    }

    deleteAll = () => {
        let self = this;
        req_generic_purge( this.props.did).then(function (result) {
            if (result.status === 200) {
                self.setState({ loading: false });
                self.loadRecs();
            } else {
                self.setState({ loading: false });
            }
        })
    }

    downloadCSV = () => {
        let aRows = []
        this.state.rows.forEach((row) => {
            delete row["owner"]
            aRows.push(row)
        })
        let jsonArray = new JsonArray(aRows);
        let str = jsonArray.convertToCSVstring();
        let filename = this.state.content.name.toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');

        filename += ".csv";
        download(filename, str);
    }

    deleteElement = (objId) => {

        if (objId.includes("new_")) {
            let rows = this.state.rows;
            rows.splice( rows.findIndex(a => a.id === objId) , 1);
            this.setState({rows: rows});
        } else {
            let self = this;

            req_generic_delete("dataset", this.props.did, objId).then(function (result) {
                if (result.status === 200) {
                    self.loadRecs();
                } else {

                }
            })
        }
    }

    handleChangeRowsPerPage = (event) => { this.setState({ rowsPerPage: +event.target.value, page: 0 }); }
    handleChangePage = (event, newPage) => { this.setState({ page: newPage }); };

    handleChange = (field, e) => {
        let fields = this.state.fields;
        let content = this.state.content;
        content[field] = e.target.value;

        this.setState({ fields: {...fields}, content: {...content}, createFields: {...content} });
    };

    componentDidMount() {

        if (!this.state.recsLoaded) {
            this.setState({ recsLoaded: true })
            this.loadProjects();
            this.reloadData();
        }
    }

    reloadData = () => {
        this.loadRec();
        this.loadRecs();
    }

    onSortColumnsChange = (sortColumns) => { this.setState({ sortColumns: sortColumns}) };

    handleContextMenuClose = () => {
        this.setState({contextMenu: null});
        this.setState({datasetContextData: {...{}}});
    }

    EmptyRowsRenderer = () => {
        return (
            <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
                <div>
                    <br />
                    There are no rows in this dataset.
                    <br /><br />
                    {
                        this.isEditable() ?
                            <div>
                                Add a new record!
                                <IconButton
                                    style={{"marginLeft": "2px"}}
                                    edge="start"
                                    color="inherit"
                                    aria-label="upload"
                                    onClick={() => {
                                        this.insertRow()
                                    }}
                                >
                                    <AddBoxIcon style={{fontSize: "20px", color: "#6b7280"}}/>
                                </IconButton>
                                <br/>
                            </div> : null
                    }
                </div>
            </div>
        );
    }

    toggleSettings = (open) => { this.setState({"openSettings": open}); };

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }

    render() {
        return(
            <Paper sx={{ width: '100%', marginTop: 0, minHeight: "", boxShadow: "none", border: 0 }}>
                <div style={{"display": "flex", "justifyContent": "space-between", "backgroundColor": "#e5e5e5", height: "36px", borderBottom: "1px solid #ccc", paddingLeft: "10px"}}>

                    <BackdropAppear loading={this.state.loading || false} />
                    <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                    {this.state && ["owner", "admin", "editor"].includes(this.state.currentUserRole) ?
                        <div style={{marginRight: "10px", paddingLeft: "5px", paddingBottom: "5px", fontSize: ".9rem"}}> <b>Dataset</b>: {this.state ? this.state.content.name : ""}
                            <span style={{marginLeft: "10px", fontSize: ".7rem"}}>({this.state.rows.length || 0} record(s))</span>
                            <Button
                                sx={{ "& .MuiButton-startIcon": { marginRight: "3px" }}}
                                aria-label="options"
                                variant={"text"}
                                style={{color: "#556677", marginLeft: "10px", fontWeight: "400"}}
                                onClick={this.openOptionsMenu}
                                startIcon={<MenuIcon style={{fontSize: "16px"}}/>}>Options</Button>

                            <Button
                                sx={{ "& .MuiButton-startIcon": { marginRight: "3px" }}}
                                onClick={() => { this.toggleSettings(!this.state.openSettings) }}
                                aria-label="settings"
                                variant={"text"}
                                style={{color: "#556677", marginLeft: "10px", fontWeight: "400"}}
                                startIcon={<SettingsIcon style={{fontSize: "16px"}}/>}>Settings</Button>
                        </div>:

                        <div style={{marginRight: "10px", paddingLeft: "5px", paddingBottom: "5px", fontSize: ".9rem"}}> <b>Dataset</b>: {this.props ? this.props.name : ""}
                            <div style={{height: "36px", lineHeight: "36px", display: "inline-block", marginLeft: "10px", fontWeight: "bold"}}>[ View Only ]</div>
                        </div>
                    }

                    {this.state && this.state.dependentFilters.length > 0 ?
                        <Button size="small"
                                ref={this.state.filterMenuRef}
                                onClick={() => {
                                    this.setState({openFilterMenu: true})
                                }}
                                variant={"text"}
                                style={{paddingTop: 0, paddingBottom: 0, color: "#5f6368", marginRight: "10px"}}
                                startIcon={<FilterAltIcon/>}>Dependent Filters</Button> : null
                    }
                            <Popper
                                open={this.state.openFilterMenu}
                                style={{"zIndex": 1}}
                                anchorEl={this.state.filterMenuRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => {
                                                this.setState({openFilterMenu: false})
                                            }}>
                                                <MenuList
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"

                                                >
                                                    {this.state.dependentFilters.map(filter =>
                                                        < MenuItem onClick={(e) => {
                                                            this.props.openItem("filter", {
                                                                name: filter.title,
                                                                id: filter.id
                                                            })
                                                        }}>{filter.title}</MenuItem>
                                                    )
                                                    }

                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>



            </div>
                {(this.state.dataLoaded) ?
                    <div style={{position: "relative", overflow: "hidden"}}>
                        <Drawer
                            transitionDuration={22}
                            sx={{
                                '& .MuiDrawer-root': {
                                    position: 'absolute',
                                    backgroundColor: "#f4f4f4",
                                    boxShadow: "0 4px 2px -2px #ccddee70",

                                },
                                '& .MuiPaper-root': {
                                    position: 'absolute',
                                    backgroundColor: "#f4f4f4",
                                    boxShadow: "0 4px 2px -2px #ccddee70",
                                    borderLeft: "1px solid #bbb",
                                    borderBottom: "1px solid #bbb"
                                },

                            }}

                            variant="persistent"
                            anchor="right"
                            open={this.state.openSettings}
                            onClose={() => {this.toggleSettings(false)}}
                        >
                            <Box component="form" onSubmit={this.saveDataset}
                                 sx={{ width: 'intrinsic',
                                     padding: "10px",
                                 }}>
                                <Typography align={"left"} style={{fontWeight: "bold", fontSize: "1rem"}}>
                                    <IconButton onClick={() => this.toggleSettings(false)}><ExitToAppIcon style={{fontSize: "1.3rem"}} /></IconButton>Dataset Settings</Typography>
                                <div style={{display: "flex", flexDirection: "column", marginTop: "10px", width: 300}}>
                                        <div >
                                            <Autocomplete
                                                id="project_select"
                                                options={this.state.projects}
                                                autoHighlight
                                                required
                                                value={this.state.currentProjectRec || null}
                                                onChange={(e, newValue) => {
                                                    this.selectProject(e, newValue);
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props}>
                                                        {option.name}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select a Project"
                                                        inputProps={{ ...params.inputProps, }}
                                                        required
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div >
                                            <TextField key={"field_name"}
                                                       sx={{marginBottom: 2}} fullWidth
                                                       label={"Dataset Name"}
                                                       value={this.state.content.name || ""}
                                                       required={true}
                                                       onChange={(e) => this.handleChange("name", e)}
                                            />
                                        </div>
                                </div>
                                <div align={"right"} style={{marginTop: "5px"}}>
                                    <Button type="submit" variant="contained" size="small">Save</Button>
                                    <Button variant="contained" size="small"  style={{marginLeft: "5px"}} onClick={() => this.toggleSettings(false)}>Cancel</Button>
                                </div>
                            </Box>
                        </Drawer>
                        <DataGrid
                            ref={this.gridRef}
                            style={{height: window.innerHeight - 116, overflow: "auto", boxShadow: "none", border: 0}}
                            className="fill-grid"
                            rowKeyGetter={this.rowKeyGetter}
                            columns={this.state.gridColumns}
                            enableCellSelect={true}
                            rows={this.state.rows}
                            onFill={this.handleFill}
                            onCopy={this.handleCopy}
                            onPaste={this.handlePaste}
                            selectedRows={new Set()}
                            onSelectedRowsChange={(set) => {
                                this.setState({selectedRows: set})
                            }}
                            onRowClick={(e, content, retProps) => {
                                if (content === "context-menu"){
                                    this.openMenu(e, retProps.row.id, "", "contextMenu", "datasetContextData" )
                                }
                            }}
                            onRowsChange={(e, operations) => {
                                this.updateRows(e, operations);
                            }}
                            onRowsDelete={(e, operations) => {console.log(e,operations)}}
                            rowHeight={30}
                            rowClass={(row) => (row.id.includes('new_') ? 'newRecordClass' : undefined)}
                            direction={this.state.direction}
                            renderers={this.isEditable() ? { rowRenderer, noRowsFallback: this.EmptyRowsRenderer()}: null }
                        >

                        </DataGrid>


                        <Menu
                              open={this.state.contextMenu !== null}
                              onClose={this.handleContextMenuClose}
                              anchorReference="anchorPosition"
                              anchorPosition={
                                  this.state.contextMenu !== null
                                      ? {top: this.state.contextMenu.mouseY, left: this.state.contextMenu.mouseX}
                                      : undefined
                              }
                        >
                            <MenuItem
                                key={"insert_row"}
                                onClick={() => {
                                    this.insertRow();
                                    this.handleContextMenuClose();
                                }}
                            >Insert New Row</MenuItem>
                            <MenuItem
                                key={"clone_row"}
                                onClick={() => {
                                    this.cloneRow(this.state.datasetContextData.id);
                                    this.handleContextMenuClose();
                                }}
                            >Clone Row</MenuItem>
                            <MenuItem
                                key={"delete_row"}
                                onClick={() => {
                                this.deleteElement(this.state.datasetContextData.id);
                                this.handleContextMenuClose();
                            }}
                            >Delete Row</MenuItem>

                        </Menu>

                        <Menu
                            open={this.state.optionsMenu !== null}
                            onClose={this.handleOptionsMenuClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                this.state.optionsMenu !== null
                                    ? {top: this.state.optionsMenu.mouseY, left: this.state.optionsMenu.mouseX}
                                    : undefined
                            }
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}

                        >
                            <MenuItem
                                key={"insert_row"}
                                onClick={() => {
                                    this.insertRow();
                                    this.handleOptionsMenuClose();
                                }}
                            >Insert Row</MenuItem>
                            <MenuItem
                                key={"delete_all_rows"}
                                onClick={() => {
                                    this.setState({deleteOpen: true})
                                    this.handleOptionsMenuClose();
                                }}
                            >Delete All Rows</MenuItem>
                            <MenuItem
                                key={"import_csv"}
                                onClick={() => {
                                    this.setUploadOpen(true);
                                    this.handleOptionsMenuClose();
                                }}
                            >Import CSV</MenuItem>
                            <MenuItem
                                key={"export_csv"}
                                onClick={() => {
                                    this.downloadCSV();
                                    this.handleOptionsMenuClose();
                                }}
                            >Export CSV</MenuItem>
                            <MenuItem
                                key={"make_template"}
                                onClick={() => {
                                    let content = this.state.content;
                                    content.createTemplate = "private_template";
                                    this.setState({templateOpen: true, content: content})
                                    this.handleOptionsMenuClose();
                                }}
                            >Make Template From This Schema</MenuItem>
                            <MenuItem
                                key={"add_filter"}
                                onClick={() => {
                                    this.props.addItem("filter", {dataset: this.props.did});
                                    this.handleOptionsMenuClose();
                                }}
                            >Create New Filter From this Dataset</MenuItem>
                            <MenuItem
                                key={"add_column"}
                                onClick={() => {
                                    let content = this.state.content;
                                    content.createTemplate = "private_template";
                                    this.setState({newColumnOpen: true})
                                    this.handleOptionsMenuClose();
                                }}
                            >Add a New Column</MenuItem>
                            <MenuItem
                                key={"clone_dataset"}
                                style={{display: "none"}}
                                onClick={() => {
                                    console.log("clone this dataset clicked")
                                    this.handleOptionsMenuClose();
                                }}
                            >Clone this Dataset</MenuItem>
                            <MenuItem
                                key={"edit_settings"}
                                onClick={() => {
                                    this.toggleSettings(true);
                                    this.handleOptionsMenuClose();
                                }}
                            >Edit Settings</MenuItem>
                        </Menu>

                        <Menu
                            open={this.state.columnOptionsMenu !== null}
                            onClose={this.handleColumnOptionsMenuClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                this.state.columnOptionsMenu !== null
                                    ? {top: this.state.columnOptionsMenu.mouseY, left: this.state.columnOptionsMenu.mouseX}
                                    : undefined
                            }
                        >
                            <MenuItem
                                key={"clone_column"}
                                onClick={() => {
                                    this.setState({columnCloneOpen: true })
                                    this.handleColumnOptionsMenuClose();
                                }}
                            >Clone Column</MenuItem>
                            <MenuItem
                                key={"delete_column"}
                                onClick={() => {
                                    this.setState({columnDeleteOpen: true })
                                    this.handleColumnOptionsMenuClose();
                                }}
                            >Delete Column</MenuItem>

                        </Menu>


                    </div> :
                    <div style={{marginTop: "20px"}}>
                        <LinearProgress />
                    </div>
                }
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={this.state.uploadOpen}
                    onClose={this.handleUploadClose}
                    TransitionComponent={this.Transition}
                >
                    <DialogAppBar title="Import Dataset Data" close={this.handleUploadClose} />

                    <Box sx={{padding: 2}}>
                        <Typography variant="body" gutterBottom component="div">
                            <b>How to import:</b>
                        </Typography>
                        <Typography variant="body" gutterBottom component="div">
                            Use this template (<CSVLink uFEFF={false} data={[this.state.csvColumns]} filename={"import_template.csv"} >here</CSVLink>) to format import data.<br />
                        </Typography>
                        <Typography variant="body" gutterBottom component="div">
                            Once the data is prepared, import the CSV by uploading below.
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Button variant="contained" component="label">
                                Click to Upload
                                <input hidden type="file" onChange={this.handleFile} />
                            </Button> &nbsp; <b>{this.state.filename}</b>
                        </Stack>
                        <div style={{marginTop: "10px"}}>
                            <FormControl>
                                <RadioGroup
                                    defaultValue="append"
                                    onChange={(e) => {
                                        this.setState({writePreference: e.target.value});
                                    }}
                                >
                                    <FormControlLabel value="append" control={<Radio />} label="Append Records to Dataset" />
                                    <FormControlLabel value="overwrite" control={<Radio />} label="Delete Existing Records and Import CSV Records as New" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                    </Box>
                    <div align={"right"}>
                        <Button
                            onClick={() => {
                                this.submitFile();
                            }}
                        >Upload</Button>
                        <Button onClick={this.handleUploadClose}>Cancel</Button>
                    </div>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={ this.state.deleteOpen }
                    onClose={this.handleDeleteClose}
                    TransitionComponent={this.Transition}
                >
                    <DialogAppBar title="Delete Confirmation" close={this.handleDeleteClose} />

                    <Box sx={{padding: 2}}>
                        <Typography variant="body" gutterBottom component="div">
                            Are you sure you want to delete all of the records in this dataset?<br />
                        </Typography>

                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button
                                color="error"
                                variant="outlined"
                                size="small"
                                type="submit"
                                onClick={() => {
                                    this.deleteAll()
                                    this.handleDeleteClose()
                                }}
                            >Delete them all!</Button>
                            <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={this.handleDeleteClose}>Cancel</Button>
                        </div>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={ this.state.templateOpen }
                    onClose={this.handleTemplateClose}
                    TransitionComponent={this.Transition}
                >
                    <DialogAppBar title="Create Template From Schema" close={this.handleTemplateClose} />

                    <Box sx={{padding: 2}}>
                        <Typography variant="body" gutterBottom component="div">
                            Select the template audience. Once selected, populate the required fields.<br />
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel  style={{"marginTop": "7px"}} id="field_template_label_">Dataset Schema Template</InputLabel>
                            <Select
                                labelId={"field_template_label_"}
                                id={"field_template_label_"}
                                label="Dataset Schema Template"
                                value={this.state.content.createTemplate || "private_template"}
                                onChange={(e) => this.handleChange("createTemplate", e)}
                            >
                                <MenuItem value="private_template">Make a private template (My Templates) for this dataset schema</MenuItem>
                                <MenuItem value="community_template">Make a community template for this dataset schema (visible in community templates)</MenuItem>

                            </Select>
                        </FormControl>
                        <TemplateFieldsPanel content={this.state.content} handleChange={this.handleChange} handleTagSelect={this.handleTagSelect} dataTab={1}/>
                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    this.handleTemplateSave()
                                }}
                            >Create Template</Button>
                            <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={this.handleTemplateClose}>Cancel</Button>
                        </div>
                    </Box>
                </Dialog>

                <ColumnDeleteDialog open={this.state.columnDeleteOpen} column={this.state.columnOptionContextData.name} close={this.handleDeleteColumnClose} delete={this.deleteColumn} />
                <ColumnCloneDialog open={this.state.columnCloneOpen} default={""} columns={this.state.gridColumns.map((column)=> column.key)} column={this.state.columnOptionContextData.name} close={this.handleCloneColumnClose} clone={this.cloneColumn} />
                <NewColumnDialog open={this.state.newColumnOpen}
                                 column={this.state.columnOptionContextData.name} close={this.handleNewColumnClose}
                                 existingColumns={this.state.gridColumns.map((column)=> column.key)}
                                 ids = {this.state.schema.properties ? (Object.entries(this.state.schema.properties)).map(property => property[1].fieldId) : []}
                                 save={this.handleNewColumnSave}
                />
            </Paper>
        )
    }
}
