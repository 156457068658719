import React from "react";
import Box from "@mui/material/Box";
import {Autocomplete} from "@mui/lab";
import {TextField} from "@mui/material";
import AutocompleteSelect from "../formControls/AutocompleteSelect";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CodeMirror from "@uiw/react-codemirror";
import {req_user_test_request} from "../../helpers/requests";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import BackdropAppear from "../staticDisplays/backdropAppear";
import IconButton from "@mui/material/IconButton";

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 1}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default class ApiTestPanel extends React.Component {

    state = {
        requestTab: 1,
        responseTab: 0,
        currentToken: this.props.tokens[0],
        currentVerb: {name: "GET (list)", id: "GET_LIST"},
        responseBody: {},
        requestBody: null,
        requestId: "",
        responseHeaders: {},
        requestQueryPairs: [{ propId: 0, propKey: "", propValue: "" }],
        errorFields: {},
        alert: { open: false, message: "", type: "" },
        loading: false
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    changeRequestTab = (event, newValue) => { this.setState({requestTab: newValue}); }

    changeResponseTab = (event, newValue) => { this.setState({responseTab: newValue}); }

    setRecordId = (e) => { this.setState({requestId: e.target.value}); }

    deleteOptions = (index) => {
        let pairs = this.state.requestQueryPairs;
        pairs.splice(index, 1);
        this.setState({requestQueryPairs: [...pairs]})
    }

    handleOptionChange = (index, type, e) => {
        let pairs = this.state.requestQueryPairs;
        pairs[index][type] = e.target.value;
        this.setState({requestQueryPairs: [...pairs]})
    }

    sendRequest = () => {
        let self = this;
        let body = ["POST","PUT"].includes(this.state.currentVerb.name) ? this.state.requestBody : null;
        let hasError = false;

        let errorFields = this.state.errorFields;
        if (["PUT", "DELETE", "GET_ID"].includes(this.state.currentVerb.id) && !this.state.requestId) {
            errorFields["requestId"] = true;
            hasError = true;
        } else {
            errorFields["requestId"] = false;
        }

        if (this.state.currentToken && this.state.currentToken.token) {
            errorFields["currentToken"] = false;
        } else {
            errorFields["currentToken"] = true;
            hasError = true;
        }

        let qs = ""
        if(this.state.requestQueryPairs.length > 0) {
            this.state.requestQueryPairs.forEach((pair) => {
                if (pair.propKey.trim()) {
                    qs += "&" + pair.propKey + "=" + pair.propValue;
                }
            })
        }

        this.setState({ errorFields: errorFields });

        if (!hasError) {

            self.setState({ loading: true });
            self.setState({responseBody: {}});

            req_user_test_request(this.state.currentVerb.id, this.props.handle, this.props.slug, this.state.currentToken.token, JSON.parse(body), this.state.requestId, qs).then(function (result) {

                if (result.status === 200) {
                    self.setState({responseBody: result.data, loading: false});
                    self.setState({responseHeaders: result.headers, loading: false});
                } else if (result.response && [400, 401].includes(result.response.status)) {
                    self.setState({responseBody: result.response.data, loading: false});
                    self.setState({responseHeaders: result.response.headers, loading: false});
                } else {
                    self.setState({
                        loading: false,
                        alert: {
                            ...{
                                "open": true,
                                "message": "There was a problem testing the API.",
                                "type": "error",
                                "timeout": 5000
                            }
                        }
                    });
                }
            })
        } else {
            self.setState({
                loading: false,
                alert: {
                    ...{
                        "open": true,
                        "message": "Required values are missing. API request was not submitted.",
                        "type": "error",
                        "timeout": 5000
                    }
                }
            });
        }
    }

    selectVerb = (e, newValue) => {
        this.setState({currentVerb: newValue});
    }

    addQueryPair = () => {
        let pairs = this.state.requestQueryPairs;
        pairs.push({ propId: 0, propKey: "", propValue: "" });
        this.setState({requestQueryPairs: [...pairs]});
    }

    objectFormatter = () => {
        let comma = ",";
        let it = 0;
        return this.props.currentFilter.selectFields.map((field) => {
            it++;
            if (this.props.currentFilter.selectFields.length === it){ comma = "";}
            return <div style={{marginLeft: "20px"}}>"{field}": "[FIELD_VALUE]"{comma}</div>
        })
    }

    purgeAlert = () => { this.setState({alert: {...{ open: false, message: "", type: "" }}}) }

    render() {

        return (
            <div style={{margin: "10px", backgroundColor: "#f7f7f7", border: "1px solid #e6e8f0"}}>
                <BackdropAppear loading={this.state.loading || false} />
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                <div style={{padding: "10px"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "5px"}}>
                        <div style={{width: "40%", paddingRight: "5px"}}>
                            <Autocomplete
                                id="test_token_select"
                                options={this.props.tokens}
                                autoHighlight
                                value={this.state.currentToken || null}

                                onChange={(e, newValue) => {
                                    this.setState({
                                        currentToken: newValue
                                    });
                                }}
                                getOptionLabel={(option) => option.name + " - [ " + option.verbs.toString() + " ] - Expires: " + option.expiration}

                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.name + " - [ " + option.verbs.toString() + " ] - Expires: " + option.expiration}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!this.state.errorFields["currentToken"]}
                                        helperText={!!this.state.errorFields["currentToken"] ? "Token is required to submit an API request." : ""}
                                        label="Test with Token"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}

                                    />
                                )}
                            />
                        </div>

                        <div style={{width: "20%", paddingLeft: "5px"}}>
                            <AutocompleteSelect
                                rows={[
                                    {name: "GET (list)", id: "GET_LIST"},
                                    {name: "GET (by ID)", id: "GET_ID"},
                                    {name: "POST", id: "POST"},
                                    {name: "PUT", id: "PUT"},
                                    {name: "DELETE", id: "DELETE"},
                                ]}
                                selectValue={this.selectVerb}
                                selectedRecord={this.state.currentVerb || {name: "GET (list)", id: "GET_LIST"}}
                                selectLabel={"Test Verb"}
                            />
                        </div>

                        <div style={{width: "30%", paddingLeft: "10px"}}>
                            <TextField key={"record_Id"}
                                       sx={{marginBottom: 2}} fullWidth
                                       label={"Record Id"}
                                       disabled={(this.state.currentVerb &&this.state.currentVerb.id) && !["GET_ID", "PUT", "DELETE"].includes(this.state.currentVerb.id)}
                                       error={!!this.state.errorFields["requestId"]}
                                       helperText={!!this.state.errorFields["requestId"] ? "A record Id is required for PUT, GET (by ID), and DELETE": ""}
                                       value={this.state.requestId}
                                       onChange={this.setRecordId}
                            />
                        </div>
                        <div style={{width: "10%", paddingLeft: "5px", paddingTop: "5px", textAlign:"right"}}>
                            <Button

                                variant="contained"
                                onClick={this.sendRequest}
                            >Send</Button>
                        </div>
                    </div>
                    <div>

                        <Tabs value={this.state.requestTab}
                              onChange={this.changeRequestTab}
                              TabIndicatorProps={{
                                  style: {
                                      backgroundColor: "#333"
                                  }
                              }}
                        >

                            <Tab label="Request Query Parameters"
                                 key={"req_params"}
                                 style={{
                                     fontSize: ".85rem",
                                     minHeight: "20px",
                                     textTransform: "none"
                                 }}
                                 {...this.a11yProps(0)} />
                            <Tab label="Request Body"
                                 key={"req_body"}
                                 style={{
                                     fontSize: ".85rem",
                                     minHeight: "20px",
                                     textTransform: "none"
                                 }}
                                 {...this.a11yProps(1)}
                            />
                            <Tab label="POST & PUT Request Body Example"
                                 key={"req_example"}
                                 style={{
                                     fontSize: ".85rem",
                                     minHeight: "20px",
                                     textTransform: "none"
                                 }}
                                 {...this.a11yProps(2)}
                            />
                        </Tabs>

                        <TabPanel key={"0_tab0"} value={this.state.requestTab} index={0} style={{padding: "0", border: "1px solid #ddd"}}>
                            { this.state.requestQueryPairs.map((pair, index) => (
                                    <div key={"pair" + index} style={{display: "flex", flexDirection: "row"}}>
                                        <div style={{width: "40%", paddingRight: "5px"}}>
                                            <TextField key={"record_key"}
                                                       size={"small"}
                                                       sx={{marginBottom: 2}} fullWidth
                                                       label={"Key"}
                                                       value={pair.propKey}
                                                       onChange={(e) => { this.handleOptionChange(index, "propKey", e) }}
                                            />
                                        </div>
                                        <div style={{width: "60%", paddingLeft: "5px"}}>
                                            <TextField key={"record_value"}
                                                       size={"small"}
                                                       sx={{marginBottom: 2}} fullWidth
                                                       label={"Value"}
                                                       value={pair.propValue}
                                                       onChange={(e) => { this.handleOptionChange(index, "propValue", e) }}
                                            />
                                        </div>

                                        <IconButton size="small" disableRipple={true} style={{paddingTop: 0, marginLeft: "10px"}} onClick={() => this.deleteOptions(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ))
                            }

                            <div style={{textAlign: "right"}}>
                                <Button variant="outlined" size="small"  startIcon={<PlaylistAddIcon />}
                                onClick={this.addQueryPair}>
                                    Add Pair
                                </Button>
                            </div>

                        </TabPanel>

                        <TabPanel key={"0_tab1"} value={this.state.requestTab} index={1} style={{padding: "0", border: "1px solid #ddd"}}>

                            <CodeMirror
                                value={JSON.stringify({}, null, 2)}
                                style={{maxHeight: "calc(40vh)"}}
                                options={{
                                    keyMap: 'sublime',
                                    mode: 'json',
                                    tabSize: 2
                                }}
                                onChange={(e) => {
                                    this.setState({requestBody: e})
                                }}
                            />
                        </TabPanel>

                        <TabPanel key={"0_tab2"} value={this.state.requestTab} index={2} style={{padding: "0", border: "1px solid #ddd"}}>

                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{width: "50%"}}>
                                    <h4>POST & PUT Example Object</h4>

                                    {this.props.currentFilter ?
                                        <pre>
                                            &#123;
                                              <this.objectFormatter/>
                                            &#125;
                                    </pre> : null
                                    }

                                </div>

                            </div>
                        </TabPanel>

                        <div style={{borderTop: "1px solid #ccc", marginTop: "10px", marginBottom: "10px"}}>
                            <Tabs value={this.state.responseTab}
                                  onChange={this.changeResponseTab}
                                  TabIndicatorProps={{
                                      style: {
                                          backgroundColor: "#333"
                                      }
                                  }}
                            >
                                <Tab label="Response Body"
                                     key={"res_body"}
                                     style={{
                                         fontSize: ".85rem",
                                         minHeight: "20px",
                                         textTransform: "none"
                                     }}
                                     {...this.a11yProps(0)}
                                />
                                <Tab label="Response Headers"
                                     key={"res_headers"}
                                     style={{
                                         fontSize: ".85rem",
                                         minHeight: "20px",
                                         textTransform: "none"
                                     }}
                                     {...this.a11yProps(1)}
                                />

                            </Tabs>

                            <TabPanel key={"1_tab0"} value={this.state.responseTab} index={0} style={{padding: "0", border: "1px solid #ddd"}}>
                                <CodeMirror
                                    value={JSON.stringify(this.state.responseBody, null, 2)}
                                    style={{maxHeight: "calc(40vh)", overflow: "auto"}}
                                    options={{
                                        maxHeight: "200",
                                        keyMap: 'sublime',
                                        mode: {
                                            name: "javascript",
                                            json: true,
                                            statementIndent: 2,
                                        }
                                    }}
                                />
                            </TabPanel>

                            <TabPanel key={"1_tab1"} value={this.state.responseTab} index={1} style={{padding: "0", border: "1px solid #ddd"}}>
                                {
                                    Object.entries(this.state.responseHeaders).map(([key, value]) => (
                                        <div key={key}><b>{key}</b>: {value}</div>
                                    ))
                                }
                            </TabPanel>
                        </div>

                    </div>

                </div>
            </div>
        );
    }

};
