import React, {Component} from 'react';
import ProjectDialog from "../forms/projectDialog";
import {
    req_generic_put
} from "../../helpers/requests";

export default class PortalItemEditor extends Component {

    state = {
        loading: false
    }

    getProjectData = (formdata, objId=null) => {
        let body = {
            "name": formdata.name,
            "schema": {
                "type": "object",
                "properties": formdata.properties,
                "required": formdata.required,
                "additionalProperties": false
            }
        }

        this.setState({loading: true});
        let self = this;
        if (objId !== null){
            req_generic_put("project", objId, body).then(function(result) {
                if (result.status === 200){
                    self.setState({loading: false});
                    //setAlert({...{ "open": true, "message": "The project was updated successfully.", "type": "success", "timeout": 5000 }});
                    //loadRecs();
                } else {
                    self.setState({loading: false});
                    //setAlert({...{ "open": true, "message": "The was an error updating the project.", "type": "error", "timeout": 5000 }});
                }
            })
        }
    };

    render() {
        return (
            <div>
                {this.props.itemType !== "" && this.props.open ?
                    <div>
                        { this.props.itemType === "project" ?
                            <ProjectDialog type={"edit"} objectType={"Project"} objectContent={{}} itemDetail={this.props.itemDetail}
                                           projectExists={true}
                                           open={this.props.open} close={this.props.close} expAdd={true} refresh={this.props.refresh} />
                            : null }
                    </div>
                    : null
                }
            </div>);
    }
}