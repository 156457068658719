import * as React from 'react';
import Box from "@mui/material/Box";

    const Product = () => (
    <div className="Product">
        <Box component="form" style={{width: '500px', minWidth: '390px', padding: '10px',margin: "0 auto"}}>
            Simple dataset is exactly that; a way to create and store data without the hassle and overheard of technical and time consuming solutions.
            <br />
            <br />
            But don't be fooled in that simple implies a lack of robustness! While you may choose to use simple dataset to log your kids' sports scores or keep track of your workout, Simple Dataset can do much more.
            <br />
            <br />
            <ul>
                <li>Create embeddable forms and charts from your data.</li>
                <li>ETL, ELT </li>
                <li>Serve headless websites</li>
                <li>Log and retain application interactions</li>
                <li>Public face your data science data and exploratory data analysis</li>
                <li>Simplified front-end for data lake data</li>
                <li>Integrate with Google Sheets</li>
                <li>Create, store, and sell datasets of value in our marketplace</li>
                <li>And more. The sky's the limit!</li>

            </ul>
        </Box>
    </div>

    )

    export default Product;
