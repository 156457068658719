import {req_user_settings_put} from "./requests";


export function openOnCreate(itemType) {
    const settings = JSON.parse(localStorage.getItem("settings"))
    if (itemType === "dataset"){
        return settings["openTabsDatasets"] ? settings["openTabsDatasets"] : false;
    } else if (itemType === "filter"){
        return settings["openTabsFilters"] ? settings["openTabsFilters"] : false;
    } else if (itemType === "apiwebhook"){
        return settings["openTabsApis"] ? settings["openTabsApis"] : false;
    }
}


export function clearLogout(logoutType = "clear") {

    localStorage.removeItem("ds_datasets");
    localStorage.removeItem("ds_filters");
    localStorage.removeItem("ds_projects");
    localStorage.removeItem("ds_apis");
    localStorage.removeItem("ds_menu");
    localStorage.removeItem("plan");
    localStorage.removeItem("ownerEmail");


    // if acctChange and save settings do the right thing
    let settings = JSON.parse(localStorage.getItem("settings"))
    if (settings && settings.retainTabs){
        const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));

        settings.account = settings.account || {[selectedAccount]: {}};
        if (settings.account[selectedAccount]) {
            settings.account[selectedAccount]["tabs"] = settings.account[selectedAccount] && settings.account[selectedAccount]["tabs"] ? settings.account[selectedAccount]["tabs"] : [];
            settings.account[selectedAccount]["tabs"] = JSON.parse(localStorage.getItem("portalTabs")) || [];
        }

        req_user_settings_put(localStorage.getItem("uid"), settings).then(function (result) {
            if (result.status === 200) {
                console.log("settings written successfully")
            } else {
                console.log("there was a problem writing settings")
            }
        })
        localStorage.removeItem("portalTabs");
    } else {
        localStorage.removeItem("portalTabs");
    }

    if (logoutType !== "acctChange") {
        localStorage.removeItem("rtoken");
        localStorage.removeItem("ownedAccount");
        localStorage.removeItem("selectedAccount");
        localStorage.removeItem("accounts");
        localStorage.removeItem("settings");
        localStorage.removeItem("uid");

    }

}

