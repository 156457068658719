import React, {Component} from 'react';
import {Tooltip} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from "@mui/material/IconButton";

export default class HelperInvoke extends Component {
    render() {

        return(
            <Tooltip
                style={{
                    display: 'inline-block',
                    color: '#445566',
                    fontSize: ".9rem",
                    padding: 0,
                    margin: "5px",
                    fontWeight: "300"
                }}
                title={this.props.content}
            >
                <IconButton size={"small"} disableRipple><HelpIcon style={{fontSize: "20px"}} /></IconButton>
            </Tooltip>





        )
    }
}
