import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import DialogAppBar from "./dialogAppBar";
import {DATASET_CLONE_COLUMN_HEAD} from "../../helpers/strConstants";
import {TextField} from "@mui/material";

export default class ColumnCloneDialog extends React.Component {

    state = {
        newColumnName: this.props.default,
        columnError: false
    }

    handleChange = (e) => {
        this.setState({ newColumnName: e.target.value, columnError: this.props.columns.includes(e.target.value) });

    };

    cloneColumn = (e) => {
        e.preventDefault();
        this.props.clone(this.props.column, this.state.newColumnName)
        this.setState({ newColumnName: ""})
        this.props.close()
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.props.open || false}
                onClose={this.props.close}
            >
                <DialogAppBar title={DATASET_CLONE_COLUMN_HEAD} close={this.handleDeleteClose} />
                <Box component="form" onSubmit={this.cloneColumn} sx={{padding: 2}}>
                    <Typography variant="body" gutterBottom component="div">
                        Please provide a name for the new column to be created. A duplicate of the "{this.props.column}" column contents will be copied into it.<br /><br />
                        <TextField key={"new_column_name"}
                                   sx={{marginBottom: 2}} fullWidth
                                   label={"New Cloned Column Name"}
                                   required={true}
                                   error={this.state.columnError}
                                   helperText={this.state.columnError ? "This column name is already in use. Please choose another." : ""}
                                   value={this.state.newColumnName || ""}
                                   onChange={this.handleChange}

                        />

                    </Typography>

                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button
                            variant="contained"
                            size="small"
                            type="submit"
                        >Clone it!</Button>
                        <Button variant="contained" size="small" style={{marginLeft: "10px"}}
                                onClick={() => { this.props.close() }}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>)
    }
}

