import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {req_login} from "../../helpers/requests";

const theme = createTheme();

export default function LogIn() {

    const [loading, setLoading] = React.useState(false);
    const [showError, setShowError] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true)

        req_login(data.get('email'), data.get('password'))
            .then(function(result) {
                if (result.status === 200){
                    localStorage.setItem("rtoken", result.data.token);
                    localStorage.setItem("uid", result.data.id);
                    localStorage.setItem("settings", JSON.stringify(result.data.settings));
                    localStorage.setItem("accounts", JSON.stringify(result.data.accounts));
                    localStorage.setItem("ownedAccount", JSON.stringify(result.data.ownedAccount));
                    localStorage.setItem("selectedAccount", JSON.stringify(result.data.ownedAccount));
                    localStorage.setItem("plan", result.data.plan ? result.data.plan : "");
                    localStorage.setItem("ownerEmail", result.data.email ? result.data.email : "");
                    if (result.data.settings.account
                        && result.data.settings.account[result.data.ownedAccount]
                        && result.data.settings.account[result.data.ownedAccount]["tabs"]){
                        localStorage.setItem("portalTabs", JSON.stringify(result.data.settings.account[result.data.ownedAccount]["tabs"]));
                    }
                    window.location = '/';
                    setLoading(false)
                } else {
                    setLoading(false);
                    setShowError(true);
                    setTimeout(() => setShowError(false), 5000);
                }
        })
    };

    return (
        <ThemeProvider theme={theme}>

            <Container component="main" maxWidth="xs">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {(showError) ?
                    <Alert severity="error" style={{"width":"100%", "marginBottom": "10px"}}>Login failed. Email or password are incorrect.</Alert> : null
                    }

                    <Avatar sx={{ m: 1, bgcolor: 'main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Log in
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Log In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                 <Link href={"/resetpass"} variant="body2">
                                    Request Password Reset
                                </Link>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}