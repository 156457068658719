import React, {Component} from 'react';
import FormDialogCollection from "../forms/formDialogCollection";
import ProjectDialog from "../forms/projectDialog";
import ApiDialog from "../forms/apiDialog";
import FilterDialog from "../forms/filterDialog";

export default class PortalItemCreator extends Component {

    state = {
        loading: false
    }

    datasetExists = (datasetName) => { return false; };
    projectExists = (datasetName) => { return false; };

    render() {
        return (
            <div>
                {this.props.itemType !== "" && this.props.open ?
                    <div>
                        { this.props.itemType === "project" ?
                            <ProjectDialog type={"add"} objectType={"Project"} objectContent={{}} itemDetail={this.props.itemDetail}
                                           projectExists={this.projectExists}
                                           open={this.props.open} close={this.props.close} expAdd={true} refresh={this.props.refresh} />
                            : null }

                        { this.props.itemType === "dataset" ?
                            <FormDialogCollection type={"add"} objectType={"Dataset"} objectContent={{}} itemDetail={this.props.itemDetail}
                                                  datasetExists={this.datasetExists}
                                                  openItem={this.props.openItem} open={this.props.open} close={this.props.close} expAdd={true} refresh={this.props.refresh} />
                            : null }

                        { this.props.itemType === "filter" ?
                            <FilterDialog type={"add"} objectType={"Filter"} objectContent={{}} itemDetail={this.props.itemDetail}
                                          openItem={this.props.openItem} open={this.props.open} close={this.props.close} expAdd={true} refresh={this.props.refresh} />
                            : null }

                        { this.props.itemType === "apiwebhook" ?
                            <ApiDialog type={"add"} objectType={"apiwebhook"} objectContent={{}} itemDetail={this.props.itemDetail}
                                       openItem={this.props.openItem} open={this.props.open} close={this.props.close} expAdd={true}  refresh={this.props.refresh}/>
                            : null }
                    </div>
                    : null
                }
            </div>);
    }
}