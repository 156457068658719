import React, {Component} from 'react';
import Typography from "@mui/material/Typography";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {
    req_get_token_list,
    req_token_delete,
    req_token_get, req_token_post,
    req_token_put
} from "../../helpers/requests";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {FormControl, FormHelperText, InputLabel, ListItemText, OutlinedInput, Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import RemoteAddressInput from "../formControls/remoteAddressInput";
import CreateTokenPanel from "../formControls/createTokenPanel";
import TokenCreatedDialog from "../dialogs/dialogs";
import DialogAppBar from "../dialogs/dialogAppBar";
import {GENERIC_LOAD_ERROR} from "../../helpers/strConstants";
import BackdropAppear from "../staticDisplays/backdropAppear";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import HelperInvoke from "../messaging/helperInvoke";
import {HLP_TOKEN_PERMITTED_ORIGINS} from "../../helpers/helpConstants";


export default class PageTokens extends Component {

    state = {
        tokens: [],
        token: {
            tokenName: "",
            tokenRemoteAddresses: ["0.0.0.0"],
            tokenExpirationDateError: false,
            tokenNameError: false,
            tokenVerbsError: false,
            tokenRemoteAddressesError: false,
        },
        columns: [],
        showCreate: false,
        showEdit: false,
        showDelete: false,
        showToken: false,
        editId: null,
        deleteId: null,
        deleteTokenName: "",
        tokenValue: "",
        showNewToken: false,
        newTokenValue: "",
        loading: false,
        alert: {open: false, message: "", type: ""},
    }

    handleEditClick = (id) => {
        let self = this;
        req_token_get("token", id).then(function (result) {
            if (result.status === 200) {
                let rowrec = result;
                let token = self.state.token;
                token["tokenName"] = rowrec.data.data.name;
                token["tokenExpirationDate"] = rowrec.data.data.expiration;
                token["tokenVerbs"] = rowrec.data.data.verbs;
                token["tokenRemoteAddresses"] = rowrec.data.data.allowed;
                self.setState({token: {...token}})

            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })

        this.setState({showEdit: true, editId: id})
    }

    saveToken =  (e) => {
        e.preventDefault();

        let body = {
            name: this.state.token.tokenName,
            verbs: this.state.token.tokenVerbs,
            allowed: this.state.token.tokenRemoteAddresses,
            expiration: this.state.token.tokenExpirationDate,
        }

        let self = this;
        req_token_put("token", this.state.editId, body).then(function (result) {
            if (result.status === 200) {
                self.loadTokens()
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem saving the Token.", "type": "error", "timeout": 5000 }}});
            }
        })

        this.handleEditClose()
    }

    handleNewTokenChange = (field, e) => {
        let token = this.state.token;
        if (field === "tokenRemoteAddresses"){
            token[field] = e;
        } else {
            token[field] = e.target.value;
        }
        this.setState({token: {...token}});
    };

    handleTokenClick = (val) => { this.setState({showToken: true, tokenValue: val}) }

    handleDeleteClick = (id) => {
        let tokenName = "";
        this.state.tokens.forEach((token) => {
            if (token.id === id){
                tokenName = token.name;
            }
        })
        this.setState({showDelete: true, deleteId: id, deleteTokenName: tokenName})
    }

    deleteToken = () => {
        let self = this;
        req_token_delete(this.state.deleteId).then(function(result) {
            if (result.status === 200){
                self.setState({loading: false, alert: {...{ "open": true, "message": "The Token was deleted successfully.", "type": "success", "timeout": 5000 }}, open: false});
                self.loadTokens();

            } else {
                self.setState({loading: false, alert: {...{ "open": true, "message": "There was an error deleting the Token.", "type": "error", "timeout": 5000 }}, open: false});
            }
            self.handleDeleteClose();
        })
    }

    handleDeleteClose = () => { this.setState({showDelete: false, deleteId: null, deleteTokenName: ""}) }

    handleTokenClose = () => { this.setState({showToken: false, tokenValue: ""}) }

    handleEditClose = () => { this.setState({showEdit: false, editId: null}) }

    handleCreateClose = () => { this.setState({showCreate: false}) }

    handleCloseNewToken = () => { this.setState({showNewToken: false}) }

    loadColumns = () => {

        const columns = [
            { field: 'name', headerName: 'Name', minWidth: 180, editable: true },
            { field: 'expiration', headerName: 'Expires', minWidth: 120, type: 'date', editable: true, renderCell: this.renderCellExpired},
            { field: 'verbs', headerName: 'Verbs', minWidth: 160, renderCell: this.renderCellExpand},
            { field: 'allowed', headerName: 'Permitted Origin(s)', minWidth: 180, renderCell: this.renderCellExpand },
            { field: 'token', headerName: 'Token Value', minWidth: 180, renderCell: (params) => this.renderCellTrim(params, this.handleTokenClick) },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {

                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => { this.handleEditClick(id) }}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => { this.handleDeleteClick(id) }}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];
        this.setState({ columns: columns });
    }

    loadTokens = () => {
        let self = this;
        self.setState({loading: true});
        req_get_token_list("token").then(function (result) {
            if (result.status === 200) {
                let rowrecs = [];
                result.data.data.forEach(function (record) {
                    rowrecs.push(record);
                });
                self.setState({tokens: [...rowrecs]});
                self.setState({loading: false});

            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    createToken = () => {
        const fields = ["tokenName", "tokenRemoteAddresses", "tokenExpirationDate", "tokenVerbs"];
        let missingRequired = false;
        let newTokenCopy = this.state.token;
        fields.forEach((field) => {
            if (!this.state.token[field]){
                newTokenCopy[field + "Error"] = true;
                missingRequired = true;
            } else {
                newTokenCopy[field + "Error"] = null;
            }
        })

        if (missingRequired) {
            this.setState({token: {...newTokenCopy}})
        } else {
            let body = {
                name: this.state.token.tokenName,
                allowed: this.state.token.tokenRemoteAddresses,
                verbs: this.state.token.tokenVerbs,
                expiration: this.state.token.tokenExpirationDate,
            }

            let self = this;
            req_token_post("token", body).then(function (result) {
                if (result.status === 200 && (!result.data.status || result.data.status !== "fail")) {
                    let t = {"tokenRemoteAddresses": ["0.0.0.0"]};
                    self.setState({token: {...t}})
                    self.loadTokens();
                    self.setState({loading: false, tokenTab: 0, newTokenValue: result.data.token, showNewToken: true});
                    self.handleCreateClose();
                }  else if (result && result.data && result.data.msg) {
                    self.setState({
                        loading: false,
                        alert: { ...{ "open": true, "message": result.data.msg, "type": "error", "timeout": 5000 }}
                    });
                } else {
                    self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem saving the Token.", "type": "error", "timeout": 5000 }}});
                }
            })
        }
    }

    componentDidMount() {
        this.loadColumns();
        this.loadTokens();
    }

    renderCellExpand(params) {
        return (<div>{params.value.join(", ")}</div>);
    }

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }

    renderCellTrim(params, handleTokenClick) {
        return (
            <div>************{params.value.substr(params.value.length - 4)}
            <IconButton onClick={() => {handleTokenClick(params.value)}}>
                <VisibilityIcon style={{fontSize: "18px"}} />
            </IconButton></div>
        );
    }

    renderCellExpired(params) {
        let date = new Date(params.row.expiration);
        let today = new Date();
        let expDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
        let todayDate = new Date(today.getTime() + today.getTimezoneOffset() * 60000)
        return (
            <div style={{color: expDate >= todayDate ? "inherit" : "#dd0000" }}>
                {params.value}
            </div>
        );
    }

    render() {
        return (

            <div className="tokens" style={{height: '100%'}}>
                <BackdropAppear loading={this.state.loading || false} />
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                <div style={{marginTop: "10px", display: "flex", flexDirection: "row", textAlign: "left"}}>
                    <div style={{width: "100%", paddingRight: "5px"}}>
                        <div style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", height: "auto"}}>
                            <Typography variant="h5" align={"left"} gutterBottom>
                                Token Information
                                <Button size="small" variant="contained" style={{marginLeft: "10px", paddingTop: "2px", paddingBottom: "2px"}}
                                        onClick={() => {
                                            this.setState({showCreate: true});
                                        }} >New Token</Button>
                            </Typography>
                            <DataGrid
                                style={{maxWidth: "925px"}}
                                autoHeight
                                rows={this.state.tokens}
                                columns={this.state.columns}
                                experimentalFeatures={{ newEditingApi: true }}
                                pageSize={10}
                            />

                        </div>
                    </div>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={ this.state.showDelete }
                    onClose={this.handleDeleteClose}
                    TransitionComponent={this.Transition}
                >

                    <DialogAppBar title="Delete Token" close={this.handleDeleteClose} />

                    <Box sx={{padding: 2}}>
                        <Typography variant="body" gutterBottom component="div">
                            Deleting a token that is in use will prevent all future API requests that use that token. Are you sure you want to delete the token '{this.state.deleteTokenName}'?<br />
                        </Typography>

                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button
                                color="error"
                                variant="outlined"
                                size="small"
                                type="submit"
                                onClick={() => {
                                    this.deleteToken()
                                    this.handleDeleteClose()
                                }}
                            >Delete it!</Button>
                            <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={this.handleDeleteClose}>Cancel</Button>
                        </div>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={ this.state.showToken }
                    onClose={this.handleTokenClose}
                    TransitionComponent={this.Transition}
                >
                    <DialogAppBar title="View Token Value" close={this.handleTokenClose} />
                    <Box sx={{padding: 2}}>
                        <Typography variant="body" gutterBottom component="div">
                            Tokens should be shared sparingly and only with people you trust. Allowing access, as granted to the token, may give the user a lot of power over your data.<br />
                        </Typography>

                        <Typography variant="body" gutterBottom component="div">
                            <b>Token Value</b>: {this.state.tokenValue}<br />
                        </Typography>

                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={this.handleTokenClose}>Close</Button>
                        </div>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={ this.state.showEdit }
                    onClose={this.handleEditClose}
                    TransitionComponent={this.Transition}
                >

                    <DialogAppBar title="Edit Token" close={this.handleEditClose} />
                    <Box component="form" onSubmit={this.saveToken}  sx={{padding: 2}}>

                        <div><TextField fullWidth label={"Token Name"} style={{marginBottom: "10px"}} value={this.state.token["tokenName"] || ""}
                                        onChange={(e) => { this.handleNewTokenChange("tokenName", e) }}
                                        helperText={this.state.token["tokenNameError"] ? "Token Name is required" : ""}
                                        error={this.state.token["tokenNameError"]}
                                        required
                        />
                        </div>
                        <div><TextField fullWidth type={"date"} label={"Expiration Date"} style={{marginBottom: "10px"}}
                                        value={this.state.token["tokenExpirationDate"] || ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { this.handleNewTokenChange("tokenExpirationDate", e) }}
                                        helperText={this.state.token["tokenExpirationDateError"] ? "Expiration Date is required" : ""}
                                        error={this.state.token["tokenExpirationDateError"]}
                                        required
                        />
                        </div>
                        <div style={{display: "flex", flexDirection: "row"}} >
                            <div style={{width: "100%"}}>
                            <RemoteAddressInput
                                selectedTags={(e) => {this.handleNewTokenChange("tokenRemoteAddresses", e)}}
                                fullWidth
                                variant="outlined"
                                id="permittedAddresses"
                                name="permittedAddresses"
                                placeholder="Add Permitted Addresses"
                                label="Permitted Remote IPs"
                                required={this.state.token["tokenRemoteAddresses"] && this.state.token["tokenRemoteAddresses"].length === 0}
                                tags={this.state.token["tokenRemoteAddresses"]}

                                helperText={this.state.token["tokenRemoteAddressesError"] ? "At least one remote address it required." : ""}
                                error={this.state.token["tokenRemoteAddressesError"]}
                            />
                        </div>
                        <div><HelperInvoke content={HLP_TOKEN_PERMITTED_ORIGINS} /></div>
                        </div>
                        <div>
                            <FormControl fullWidth style={{paddingBottom: "10px"}}>
                                <InputLabel id="field_verb_label">Select HTTP Access Verbs</InputLabel>
                                <Select
                                    fullWidth
                                    labelId={"field_verb_label"}
                                    defaultValue={"Select HTTP Access Verbs"}
                                    multiple
                                    required
                                    value={this.state.token.tokenVerbs || []}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    error={this.state.token["tokenVerbsError"]}
                                    onChange={(e) => {
                                        this.handleNewTokenChange("tokenVerbs", e);
                                    }}>
                                    <MenuItem value={"Select HTTP Verb Access"}>Select HTTP Verb Access</MenuItem>
                                    {["GET", "POST", "PUT", "DELETE"].map(record => {
                                        return <MenuItem key={record}
                                                         value={record} name={record}>
                                            <Checkbox checked={(this.state.token.tokenVerbs ? this.state.token.tokenVerbs: []).indexOf(record) > -1} />
                                            <ListItemText key={record} primary={record} />
                                        </MenuItem>
                                    })
                                    }
                                </Select>
                                <FormHelperText style={{color: '#D14343'}}>{this.state.token["tokenVerbsError"] ? "At least one Verb selection is required" : ""}</FormHelperText>
                            </FormControl>

                        </div>


                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button
                                variant="contained"
                                size="small"
                                type="submit"
                            >Save</Button>
                            <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={this.handleEditClose}>Cancel</Button>
                        </div>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={ this.state.showCreate }
                    onClose={this.handleCreateClose}
                    TransitionComponent={this.Transition}
                >
                    <DialogAppBar title="Create Token" close={this.handleCreateClose} />
                    <Box sx={{padding: 2}}>
                        <CreateTokenPanel token={this.state.token} handleNewTokenChange={this.handleNewTokenChange} createToken={this.createToken} />
                    </Box>
                </Dialog>

                <TokenCreatedDialog open={this.state.showNewToken} tokenValue={this.state.newTokenValue} close={this.handleCloseNewToken} />


            </div>
        )
    }
}