import React, {Component} from 'react';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TextField} from "@mui/material";
import {req_templates_get_list, req_templates_search_post, req_template_delete} from "../../helpers/requests";
import "./templates.css"
import TemplateDeleteDialog from "../dialogs/templateDeleteDialog";
import BackdropAppear from "../staticDisplays/backdropAppear";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import {GENERIC_LOAD_ERROR} from "../../helpers/strConstants";


const TemplatesTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const SchemaPrettyPrint = (props) => {
    const schema = props.schema;

    return (<div>
        {
            (Object.entries(schema.properties).sort(([, v1], [, v2]) => v1["sortOrder"] - v2["sortOrder"])).map(property => {
                return <div key={property[0].toString()}><b>{property[0].toString()}</b>
                    {
                        Object.entries(property[1]).filter(name => ["fieldType", "format", "type", "default", "defaultDateFormat"].includes(name[0])).map(attribute => {
                            return <div key={attribute[0].toString()} style={{fontSize: ".65rem", paddingLeft: "10px"}}>{attribute[0].toString()} = {attribute[1].toString()}</div>
                            })
                    }
                </div>
                })
        }

    </div>)
}

export default class Templates extends Component {

    state = {
        loading: false,
        alert: { open: false, message: "", type: "" },
        templates: [],
        templateSearchResults: [],
        templateSearchTerms: "",
        templateTab: 0,
        templateDeleteOpen: false,
        deleteTemplate: {}
    }
    a11yProps = (index) => { return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` }; }

    handleCloseDeleteTemplate = () => { this.setState({templateDeleteOpen: false}) }

    openTemplateDelete = (template) => { this.setState({templateDeleteOpen: true, deleteTemplate: {...template}}) }

    loadTemplates = () => {
        let self = this;
        this.setState({ loading: true });
        req_templates_get_list("template").then(function (result) {
            if (result.status === 200) {
                let rowrecs = [];
                result.data.data.forEach(function (record) {
                    rowrecs.push(record);
                });
                self.setState({templates: [...rowrecs]});
                self.setState({ loading: false });
            } else {
                self.setState({ loading: false, alert: {...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    deleteTemplate = (templateId) => {

        let self = this;
        req_template_delete(templateId).then(function (result) {
            if (result.status === 200) {
                self.setState({deleteTemplate: {...{}}})
                self.loadTemplates()
                self.setState({ loading: false, alert: {...{ "open": true, "message": "The template has be deleted successfully.", "type": "success", "timeout": 5000 }}});
            } else {
                self.setState({ loading: false, alert: {...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
        this.handleCloseDeleteTemplate()
    }

    importFromTemplate = (template) => { this.props.addItem("dataset", {template: template.id, templateObj: template}); }

    handleSearchTermsChange = (e) => { this.setState({ templateSearchTerms: e.target.value }); };

    searchTemplates = (e) => {
        e.preventDefault();
        let self = this;
        const searchTerms = this.state.templateSearchTerms.split(" ");
        let body = { "search": searchTerms.length > 0 ? searchTerms : [] }

        this.setState({loading: true});
        req_templates_search_post(body).then(function (result) {
            if (result.status === 200) {
                self.setState({templateSearchResults: [...result.data.data], loading: false});
            } else {
                self.setState({
                    loading: false,
                    alert: {
                        ...{
                            "open": true, "message": "There was a problem with the template search.",
                            "type": "error", "timeout": 5000
                        }
                    }
                });
            }
        })
    }

    componentDidMount() {
        this.loadTemplates();
    }

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }

    render() {
        return (
            <div className="Templates">
                <BackdropAppear loading={this.state.loading || false} />
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                <Box style={{width: '100%', padding: '10px', margin: "0 auto"}}>
                    <div style={{display: 'flex'}}>
                        <Tabs
                            value={this.state.templateTab}
                            onChange={(event, newValue) => {
                                this.setState({templateTab: newValue})
                            }}
                            orientation="vertical"
                            variant="scrollable"
                            aria-label="Template Category Selection"
                            sx={{
                                borderRight: 1,
                                borderColor: 'divider',
                                textAlign: 'left',
                                width: "180px",
                                textTransform: "none"
                            }}
                        >
                            <Tab style={{
                                textTransform: "none !important",
                                fontSize: ".8rem",
                            }} label="My Templates" {...this.a11yProps(0)} />
                            <Tab style={{
                                textTransform: "none !important",
                                fontSize: ".8rem",
                            }}
                                 label="Public & Community Templates" {...this.a11yProps(1)} />
                        </Tabs>

                        <TemplatesTabPanel value={this.state.templateTab} index={0} className={"templateTabPanel"}>
                            <div>
                                {this.state.templates.map((template) => {
                                        return <Accordion key={template.id}  >
                                            <AccordionSummary style={{backgroundColor: "#f5f5f5"}}
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography><b>{template.permission}</b>: {template.name}</Typography>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{display: 'flex', flex:1, justifyContent: "space-between", marginBottom: "10px", textAlign:"left"}}>
                                                    <div style={{width: "33%", border: "1px solid #ccc", padding: "5px"}}>
                                                        <Typography variant="h6">Template Id</Typography>
                                                        <span style={{fontSize: ".7rem"}}>{template.id}</span>

                                                        <Typography variant="h6">Description</Typography>
                                                        <span style={{fontSize: ".7rem"}}>{template.notes}</span>

                                                    </div>
                                                    <div style={{width: "33%", border: "1px solid #ccc", padding: "5px"}}>
                                                        <Typography variant="h6">Schema</Typography>
                                                            <SchemaPrettyPrint schema={template.schema} />
                                                    </div>
                                                    <div style={{width: "33%", border: "1px solid #ccc", padding: "5px"}}>
                                                        <Typography variant="h6">Tags</Typography>
                                                        {
                                                            template.tags.map(tag => {return <Chip key={template.id + "_" + tag} label={tag} style={{marginRight: "5px"}} />})
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{textAlign: "right"}}>
                                                    <Button size={"small"} variant="contained" onClick={() => {
                                                        this.importFromTemplate(template)
                                                        }}>Create Dataset From Template
                                                    </Button>
                                                    <Button size={"small"} variant="contained" style={{marginLeft:"10px", display: "none"}}>Convert to Community</Button>
                                                    <Button size={"small"} variant="contained" style={{marginLeft:"10px", display: "none"}}>Convert to Private</Button>

                                                    <Button size={"small"} variant="contained" style={{marginLeft:"10px", display: "none"}}>Edit</Button>
                                                    <Button size={"small"} variant="contained" style={{marginLeft:"10px"}}
                                                            onClick={() => {
                                                                this.openTemplateDelete(template)
                                                            }}
                                                    >Delete</Button>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                })
                                }
                            </div>
                        </TemplatesTabPanel>

                        <TemplatesTabPanel value={this.state.templateTab} index={1}  className={"templateTabPanel"}>
                            <div>
                                <Box style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flex: 1,
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                    paddingTop: "10px",
                                    marginBottom: "5px",
                                    borderRadius: "3px"
                                }}
                                     component="form"
                                     onSubmit={this.searchTemplates}
                                >
                                    <div  style={{width: "80%", marginLeft: "5px"}} >
                                        <TextField size={"small"}
                                                   onChange={(e) => {
                                                       this.handleSearchTermsChange(e)
                                                   }}
                                                   fullWidth label={"Search by Name, Tag, or Template Id."}/>
                                    </div>
                                    <div style={{width: "20%", textAlign: "right"}}>
                                        <Button variant="contained" type={"submit"}>Search</Button>
                                    </div>
                                </Box>

                                {this.state.templateSearchResults.map((template) => {
                                    if (["public", "community"].includes(template.templateType)) {

                                        return <Accordion key={template.id}  >
                                            <AccordionSummary style={{backgroundColor: "#f5f5f5"}}
                                                              expandIcon={<ExpandMoreIcon />}
                                                              aria-controls="panel1a-content"
                                                              id="panel1a-header"
                                            >
                                                <Typography><b>{template.templateType}</b>: {template.name}</Typography>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{display: 'flex', flex:1, justifyContent: "space-between", marginBottom: "10px", textAlign:"left"}}>
                                                    <div style={{width: "33%", border: "1px solid #ccc", padding: "5px"}}>
                                                        <Typography variant="h6">Template Id</Typography>
                                                        <span style={{fontSize: ".7rem"}}>{template.id}</span>

                                                        <Typography variant="h6">Description</Typography>
                                                        <span style={{fontSize: ".7rem"}}>{template.notes}</span>

                                                    </div>
                                                    <div style={{width: "33%", border: "1px solid #ccc", padding: "5px"}}>
                                                        <Typography variant="h6">Schema</Typography>
                                                        <SchemaPrettyPrint schema={template.schema} />
                                                    </div>
                                                    <div style={{width: "33%", border: "1px solid #ccc", padding: "5px"}}>
                                                        <Typography variant="h6">Tags</Typography>
                                                        {
                                                            template.tags.map(tag => {return <Chip key={template.id + "_" + tag} label={tag} style={{marginRight: "5px"}} />})
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{textAlign: "right"}}>
                                                    <Button size={"small"} variant="contained" onClick={() => {
                                                        this.importFromTemplate(template)
                                                    }}>Create Dataset From Template
                                                    </Button>

                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                    } else {
                                        return null
                                    }

                                })
                                }
                            </div>
                        </TemplatesTabPanel>
                    </div>


                </Box>
                <TemplateDeleteDialog open={this.state.templateDeleteOpen} template={this.state.deleteTemplate} close={this.handleCloseDeleteTemplate} delete={this.deleteTemplate} />
            </div>

        )
    }
}

