import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import DialogAppBar from "./dialogAppBar";
import {ACCOUNT_INVITE_USER_HEAD} from "../../helpers/strConstants";
import {InputLabel, Select, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {req_user_invite} from "../../helpers/requests";

export default class AccountShareDialog extends React.Component {

    state = {
        email: "",
        emailError: false,
        userType: "standard",
        accountId: this.props.accountId,
        alert: { open: false, message: "", type: "" },
        loading: false
    }

    handleChange = (field, e) => {
        this.setState({ [field]: e.target.value });
    }

    inviteUser = (e) => {
        e.preventDefault();
        let self = this;

        let body = {
            email: this.state.email,
            account: this.props.user.ownedAccount,
            role: this.state.userType
        }

        req_user_invite(this.props.user.id, body).then(function (result) {
            if (result.status === 200) {
                self.setState({ email: "", userType: "standard"})
                self.setState({loading: false, alert:{...{ "open": true, "message": "The account invitation has been sent.", "type": "success", "timeout": 5000 }}});
                self.props.close()
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem sending the account invitation.", "type": "error", "timeout": 5000 }}});
            }
        })
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.props.open || false}
                onClose={this.props.close}
            >
                <DialogAppBar title={ACCOUNT_INVITE_USER_HEAD} close={this.handleDeleteClose} />
                <Box component="form" onSubmit={this.inviteUser} sx={{padding: 2}}>
                    <Typography variant="body" gutterBottom component="div">
                        Please provide the email and access level of new user. Upon confirmation, <b>Admin Users will be granted full view of all account datasets and their related filters, APIs, and the like.</b> Alternatively,
                        standard users will only be able to see and/or modify those dataset items which are shared with them and those which they create.
                    </Typography>

                        <TextField key={"new_account user"}
                                   sx={{marginBottom: 2, marginTop: "10px"}} fullWidth
                                   label={"Email Address"}
                                   required={true}
                                   error={this.state.emailError}
                                   helperText={this.state.emailError ? "There is a problem with this email address." : ""}
                                   value={this.state.email || ""}
                                   onChange={(e) => {this.handleChange("email", e) }}

                        />
                        <FormControl fullWidth style={{marginTop: "10px"}}>
                            <InputLabel id="user_type_select_label">User Type</InputLabel>
                            <Select
                                required
                                labelId="user_type_select_label"
                                id="user_type_select"
                                value={this.state.userType || ""}
                                label="User Type"
                                onChange={(e) => {this.handleChange("userType", e) }}
                            >
                                <MenuItem value={"admin"}>Admin User</MenuItem>
                                <MenuItem value={"standard"}>Standard User</MenuItem>
                            </Select>
                        </FormControl>



                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button
                            variant="contained"
                            size="small"
                            type="submit"
                        >Invite User</Button>
                        <Button variant="contained" size="small" style={{marginLeft: "10px"}}
                                onClick={() => { this.props.close() }}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>)
    }
}

