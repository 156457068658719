import {Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import iconText from "../assets/typeImages/iconText.png";
import iconDecimal from "../assets/typeImages/iconDecimal.png";
import iconInteger from "../assets/typeImages/iconInteger.png";
import iconBoolean from "../assets/typeImages/iconBoolean.png";
import iconDate from "../assets/typeImages/iconDate.png";
import iconDatetime from "../assets/typeImages/iconDatetime.png";
import iconTime from "../assets/typeImages/iconTime.png";
import iconList from "../assets/typeImages/iconList.png";
import iconObject from "../assets/typeImages/iconObject.png";

import EditorBaseDialog from "../components/dialogs/editorBaseDialog";
const defaultprops = {size: "small", autoFocus: true}

export function TextFormatter({ row, column, onRowChange }) {
    return <TextField
        inputProps={{ className: "baseEditInput" }}
        style={{ width: '100%', marginTop: "-5px", borderRadius: 0, padding: 0}}
        {...defaultprops}
        onChange={(event) => {
            onRowChange({ ...row, [column.key]: event.target.value }, false)
        }}
        value={row[column.key]}
    />;
}

export function BigTextFormatter({ row, column, onRowChange }) {
    let titles = {"xml": "HTML Editor", "markdown": "Markdown Editor", "multiline": "Text Editor"}
    return <EditorBaseDialog title={titles[column.format]}
                             column={column}
                             value={row[column.key]}
                             save={(newValue) => onRowChange({ ...row, [column.key]: newValue }, true)}
                             close={() => onRowChange({ ...row }, true)}
    />;
}


export function JsonFormatter({ row, column, onRowChange }) {
    let titles = {"object": "JSON Object Editor"}
    return <EditorBaseDialog title={titles[column.format]}
                             column={column}
                             value={row[column.key]}
                             save={(newValue) => onRowChange({ ...row, [column.key]: newValue }, true)}
                             close={() => onRowChange({ ...row }, true)}
    />;
}

export function DecimalFormatter({ row, column, onRowChange }) {
    return <TextField
        {...defaultprops}
        style={{ width: '100%', marginTop: "-5px", borderRadius: 0, padding: 0}}
        className="baseEditInputAdd"
        type="number"
        inputProps={{ pattern: "[0-9\.]", className: "baseEditInput" }}
        onInput={(event) => {event.target.value.replace(/\D\./,'')}}
        onChange={(event) => {
            let newDec = parseFloat(event.target.value);
            newDec = (isNaN(newDec)) ? "" : newDec;
            onRowChange({ ...row, [column.key]: newDec }, false)
        }}
        value={row[column.key]}
    />;
}

export function IntegerFormatter({ row, column, onRowChange }) {
    return <TextField
        {...defaultprops}
        className="baseEditInputAdd"
        type="number"
        style={{ width: '100%', marginTop: "-5px", borderRadius: 0, padding: 0}}
        inputProps={{ pattern: "[0-9]", className: "baseEditInput" }}
        onInput={(event) => {event.target.value.replace(/\D/,'')}}
        onChange={(event) => {
            let newInt = parseInt(event.target.value);
            newInt = (isNaN(newInt)) ? "" : newInt;
            onRowChange({ ...row, [column.key]: newInt }, false);
        }}
        value={row[column.key]}
    />;
}

export function DateTimeFormatter({ row, column, onRowChange }) {
    let dateToday = new Date();
    return <TextField
        inputProps={{ className: "baseEditInput" }}
        {...defaultprops}
        style={{ width: '100%', marginTop: "-5px", borderRadius: 0, padding: 0}}
        className="baseEditInputAdd"
        type="datetime-local"
        format="yyyy-MM-dd HH:mm:ss"
        defaultValue={ row.value || dateToday.toISOString()}
        onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value }, false)}
        value={row[column.key]}
    />;
}

export function DateFormatter({ row, column, onRowChange }) {
    let dateToday = new Date();
    return <TextField
        {...defaultprops}
        style={{ width: '100%', marginTop: "-5px", borderRadius: 0, padding: 0}}
        inputProps={{ className: "baseEditInput" }}
        className="baseEditInputAdd"
        type="date"
        format="yyyy-MM-dd"
        defaultValue={ row.value || dateToday.toISOString()}
        onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value }, false)}
        value={row[column.key]}
    />;
}

export function TimeFormatter({ row, column, onRowChange }) {
    let dateToday = new Date();
    return <TextField
        {...defaultprops}
        style={{ width: '100%', marginTop: "-5px", borderRadius: 0, padding: 0}}
        inputProps={{ className: "baseEditInput" }}
        className="baseEditInputAdd"
        type="time"
        format="HH:mm:ss"
        defaultValue={ row.value || dateToday.toISOString()}
        onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value }, false)}
        value={row[column.key]}
    />;
}

export function dropDownEditor({ row, column, onRowChange }) {
    return (
        <Select
            {...defaultprops}
            style={{ width: '100%', marginTop: "-5px", borderRadius: 0, padding: 0}}
            menuportaltarget={document.body}
            className="baseEditInputAdd"
            value={row.enum || ""}
            onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value }, true)}
            open={true}
        >
            <MenuItem
                value={""}
                key={"select_value"}
            >Select a Value</MenuItem>
            {column.enum.map((option) => (
                <MenuItem
                    value={option.value}
                    key={option.value}
                >{option.label}</MenuItem>
            ))}
        </Select>
    );
}

export function getColumnImage(type, format, dateformat){
    if (type === "string" && format === "date")
        type = "date";

    const types = {
        string: {image: iconText, alt: "text/string formatted column"},
        decimal: {image: iconDecimal, alt: "decimal formatted column"},
        int: {image: iconInteger, alt: "integer formatted column"},
        boolean: {image: iconBoolean, alt: "boolean formatted column"},
        date: {image: iconDate, alt: "date formatted column"},
        dateformat: {image: iconDate, alt: "date formatted column"},
        datetime: {image: iconDatetime, alt: "datetime formatted column"},
        time: {image: iconTime, alt: "time formatted column"},
        options: {image: iconList, alt: "option list column"},
        enum: {image: iconList, alt: "option list column"},
        object: {image: iconObject, alt: "JSON object column"},
    }

    return (<img src={types[type].image} alt={types[type].alt} style={{height: "12px", marginBottom: "5px"}} title={types[type].alt}/>)
}