import React, {Component} from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

export default class BackdropAppear extends Component {
    render() {
        return(
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.props.loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}
