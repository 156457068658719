import MDEditor, { } from '@uiw/react-md-editor';
import React from "react";

export default class BigTextMarkdown extends React.Component {

    checkForReturn = (e) => {
        if (e.key === "Enter" || e.which === 13) {
            e.stopPropagation();
        }
    }

    render () {
        return (<MDEditor

                onKeyDown={this.checkForReturn}
                value={this.props.value}
                onChange={(e) => {
                    this.props.onChange({target:{value: e}});
                }}
                preview="edit"
                components={{
                    toolbar: (command, disabled, executeCommand) => {
                        if (command.keyCommand === 'code') {
                            return (
                                <button
                                    aria-label="Insert code"
                                    disabled={disabled}
                                    onClick={(evn) => {
                                        evn.stopPropagation();
                                        executeCommand(command, command.groupName)
                                    }}
                                >Code</button>
                            )
                        }
                    }
                }}
            />
        )
    }
}

