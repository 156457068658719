import React, {Component} from 'react';
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TokenIcon from '@mui/icons-material/Token';
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SchemaIcon from '@mui/icons-material/Schema';
import { styled } from '@mui/material/styles';
import MuiDrawer from "@mui/material/Drawer";
import MenuLeftNavListItem from "./menuLeftNavListItem";
import MenuAddItem from "./menuAddItem";
import {req_project_menu_hierarchy_get} from "../../helpers/requests";
import "./portalLeftNav.css";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import BackdropAppear from "../staticDisplays/backdropAppear";
import {ListItem, ListItemText} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import {Link} from "react-router-dom";
import logo from "../../assets/SimpleDatasetBeta.png";
import eventBus from "../../helpers/eventBus";

const drawerWidth = 250;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default class PortalLeftNav extends Component {

    state = {
        projectLinks: [],
        projectMenu: [],
        dashboardLinks: [],
        loading: false,
        alert: { open: false, message: "", type: "" },
        allowPropsRefresh: false
    }

    loadProjectMenu() {
        let self = this;
        self.setState({loading: false});
        req_project_menu_hierarchy_get().then(function (result) {
            if (result.status === 200) {
                localStorage.setItem("ds_menu", JSON.stringify(result));
                let navRecs = [];
                result.data.projects.forEach(function (record) {
                    let navRec = { "name": record.name, datasets: record.datasets,
                        "icon": AccountTreeIcon,
                        "showExpand": true,
                        role: record.role,
                        multiuser: record.multiuser,
                        accessPermissions: record.accessPermissions,
                        type: "project",
                        id: record.id };
                    navRecs.push(navRec);
                });
                self.setState({projectMenu: [...navRecs], dataLoaded: true, loading: false, allowPropsRefresh: true});
            } else {
                self.setState({loading: false});
            }
        })
    }

    componentDidMount() {
        this.loadProjectMenu();
        eventBus.on("updateAssetMenu", () => {
            this.loadProjectMenu();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.rerenderState !== prevProps.rerenderState && this.state.allowPropsRefresh === true) {
            this.loadProjectMenu();
            this.props.refresh(false, null);
        }
    }

    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }

    render() {
        return(
            <Drawer variant="permanent" open={this.props.open}>
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                <BackdropAppear loading={this.state.loading || false} />
                <div style={{display: "flex", flexDirection: "row"}}>
                    <Link style={{ my: 2, color: '#333', display: 'inline-block', textDecoration: "none", "fontWeight":"bold", paddingTop: "7px", paddingLeft: "10px" }} component={Link} to={'/' }><img src={logo} alt="Simple:Dataset Logo" height={28} /></Link>
                </div>
                <Divider />
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "#f7f7f7"}}>
                    <MenuAddItem addItem={this.props.addItem} openItem={this.props.openItem} refresh={this.props.refresh} />
                </div>
                <Divider />

                <List style={{fontWeight: 'bold', paddingTop: "1px", backgroundColor: "#456", height:"100%"}} >
                    {
                        this.state.projectMenu.map((linkObj) => {
                            return <MenuLeftNavListItem key={linkObj.id} linkObj={linkObj} open={this.props.open} addItem={this.props.addItem} editItem={this.props.editItem} deleteItem={this.props.deleteItem} openItem={this.props.openItem} shareItem={this.props.shareItem} />
                        })
                    }
                </List>


                <div style={{paddingTop: "1px", backgroundColor: "#456", marginTop: "auto"}}>
                <List>
                    <ListItem onClick={this.props.handleLogout} sx={{display: "none", fontSize: ".9rem", paddingLeft: "10px", backgroundColor: "#445566", color: "#eee"}}>
                        <ListItemButton  onClick={this.props.handleLogout} className={"leftNavListItemButton"} >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: this.props.open ? 1 : 'auto',
                                    justifyContent: 'center',
                                    color: "#ccc",
                                }}
                            ><LogoutIcon sx={{fontSize: 16}}/>
                            </ListItemIcon>
                            <ListItemText style={{marginTop: 0, marginBottom: 0}} primaryTypographyProps={{fontSize: '.8rem', fontWeight: '500'}}>Log Out</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem sx={{display: "block", fontSize: ".9rem", paddingLeft: "10px", backgroundColor: "#445566", color: "#eee", paddingTop: "2px"}}
                              >
                        <ListItemButton className={"leftNavListItemButton"} onClick={() => {  this.props.openItem("infopage", {"name":"Templates", id: 1, type: "infopage" }) }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: this.props.open ? 1 : 'auto',
                                    justifyContent: 'center',
                                    color: "#ccc",
                                }}
                            ><SchemaIcon sx={{fontSize: 16}}/>
                            </ListItemIcon>
                            <ListItemText style={{marginTop: 0, marginBottom: 0}} primaryTypographyProps={{fontSize: '.8rem', fontWeight: '500'}}>Templates</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem sx={{display: "block", fontSize: ".9rem", paddingLeft: "10px", backgroundColor: "#445566", color: "#eee", paddingTop: "2px"}}
                              component={Link} to='/settings/tokens'>
                        <ListItemButton className={"leftNavListItemButton"} >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: this.props.open ? 1 : 'auto',
                                    justifyContent: 'center',
                                    color: "#ccc",
                                }}
                            ><TokenIcon sx={{fontSize: 16}}/>
                            </ListItemIcon>
                            <ListItemText style={{marginTop: 0, marginBottom: 0}} primaryTypographyProps={{fontSize: '.8rem', fontWeight: '500'}}>Tokens</ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem sx={{display: "none", fontSize: ".9rem", paddingLeft: "10px", backgroundColor: "#445566", color: "#eee", paddingTop: "2px"}}
                              component={Link} to='/settings'>
                        <ListItemButton className={"leftNavListItemButton"} >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: this.props.open ? 1 : 'auto',
                                    justifyContent: 'center',
                                    color: "#ccc",
                                }}
                            ><SettingsIcon sx={{fontSize: 16}}/>
                            </ListItemIcon>
                            <ListItemText style={{marginTop: 0, marginBottom: 0}} primaryTypographyProps={{fontSize: '.8rem', fontWeight: '500'}}>Settings</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem sx={{display: "none", fontSize: ".9rem", paddingLeft: "10px", backgroundColor: "#445566", color: "#eee", paddingTop: "2px"}}
                              component={Link} to='/settings/account'>
                        <ListItemButton className={"leftNavListItemButton"} >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: this.props.open ? 1 : 'auto',
                                    justifyContent: 'center',
                                    color: "#ccc",
                                }}
                            ><PersonIcon sx={{fontSize: 16}}/>
                            </ListItemIcon>
                            <ListItemText style={{marginTop: 0, marginBottom: 0}} primaryTypographyProps={{fontSize: '.8rem', fontWeight: '500'}}>Account</ListItemText>
                        </ListItemButton>
                    </ListItem>

                </List>
                    <div style={{fontSize: ".75rem", color: "#eee", padding: "5px"}}>
                        <Link style={{fontSize: ".75rem", color: "#eee", textDecoration: "none"}} component={Link} to={'/settings/privacy'}>
                            Privacy Policy
                        </Link> &nbsp;|&nbsp; <Link style={{fontSize: ".75rem", color: "#eee", textDecoration: "none"}} component={Link} to={'/settings/terms'}>
                            Terms of Service
                        </Link>
                    </div>
                    <div style={{fontSize: ".75rem", color: "#eee", padding: "5px"}}>Copyright &copy; Simple Dataset</div>
                </div>




            </Drawer>
        )
    }
}


