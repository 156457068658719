import React, {Component} from 'react';
import PrivacyPolicy from "../content/privacyPolicy";

export default class PagePrivacy extends Component {
    render() {
        return (

            <div className="privacy" style={{height: '100%', textAlign: "left"}}>
                <div style={{marginTop: "10px", display: "flex", flexDirection: "row"}}>
                    <div style={{width: "100%", paddingRight: "5px"}}>
                        <div style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px"}}>
                            <PrivacyPolicy />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}