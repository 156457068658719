import React, {Component} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import SnackbarInvoke from "../messaging/snackbarInvoke";
import {Link} from "react-router-dom";
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    RadioGroup,
    Select,
    TextField,
    Radio,
} from "@mui/material";
import {
    req_projects_get,
    req_get_membership_list,
    req_object_permissions_post,
    req_object_permissions_put,
    req_object_permissions_delete,
    req_generic_get_dataset
} from "../../helpers/requests";
import BackdropAppear from "../staticDisplays/backdropAppear";
import DialogAppBar from "../dialogs/dialogAppBar";
import {GENERIC_LOAD_ERROR} from "../../helpers/strConstants";
import {Autocomplete} from "@mui/lab";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AutocompleteSelectMulti from "../formControls/AutocompleteSelectMulti";
import eventBus from "../../helpers/eventBus";


export default class ShareDialog extends Component {

    state = {
        open: this.props.open || false,
        loading: false,
        alert: { open: false, message: "", type: "" },
        memberships: [],
        filteredMemberships: [],
        attachedMembers: [],
        listMemberships: [],
        datasetOptions: [],
        datasetSelector: "all_datasets",
        datasetsSelected: [],
        content: {accessPermissions: []}
    };

    setOpen = (visible) => { this.setState({ open: visible }); }
    purgeAlert = () => { this.setState({alert: { open: false, message: "", type: "" }}) }
    handleClickOpen = () => { this.setOpen(true); };

    handleClose = () => {
        this.setOpen(false);
        this.props.close();
    };


    selectDatasets = (e, newValue) => {
        this.setState({datasetsSelected: [...newValue]});
    }


    handleDatasetSelectorChange = (e) => {
        this.setState({datasetSelector: e.target.value})
    }

    loadRec = () => {
        const id = this.props.itemDetail.id;
        let self = this;
        self.setState({ loading: true });

        if (this.props.objectType.toLowerCase() === "project") {
            req_projects_get("project", id).then(function (result) {
                if (result.status === 200) {
                    let rowRec = result;
                    self.setState({content: rowRec.data.data});

                    req_get_membership_list().then(function (result) {
                        if (result.status === 200) {
                            self.setState({memberships: [...result.data.data]})
                            if (rowRec.data.data.hasOwnProperty("accessPermissions")) {
                                const permIds = rowRec.data.data.accessPermissions.map(a => a.personId);
                                let filteredMemberships = result.data.data.filter(a => a.personId && !permIds.includes(a.personId));
                                self.setState({filteredMemberships: filteredMemberships})
                            } else {
                                self.setState({filteredMemberships: result.data.data})
                                self.setState({content: {accessPermissions: []} })
                            }
                        } else {
                            self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
                        }
                    })
                    let datasets = JSON.parse(localStorage.getItem("ds_datasets"));
                    self.setState({datasetOptions: datasets.data.data.filter(dataset => dataset.projectId === id)})
                    self.setState({loading: false});
                } else {
                    self.setState({loading: false});
                }
            })
        }
        if (this.props.objectType.toLowerCase() === "dataset") {
            req_generic_get_dataset(id).then(function (result) {
                if (result.status === 200) {
                    let rowRec = result;
                    self.setState({content: rowRec.data.data});
                    req_get_membership_list().then(function (result) {
                        if (result.status === 200) {
                            self.setState({memberships: [...result.data.data]})
                            if (rowRec.data.data.hasOwnProperty("accessPermissions")) {
                                const permIds = rowRec.data.data.accessPermissions.map(a => a.personId);
                                let filteredMemberships = result.data.data.filter(a => !permIds.includes(a.personId));
                                self.setState({filteredMemberships: filteredMemberships})
                            } else {
                                self.setState({filteredMemberships: result.data.data})
                                self.setState({content: {accessPermissions: []} })
                            }
                        } else {
                            self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
                        }
                    })

                    self.setState({loading: false});
                } else {
                    self.setState({loading: false});
                }
            })
        }

    }

    saveProjectPermissions = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        this.setState({ loading: true });
        let self = this;

        let body = {
            type: this.props.objectType.toLowerCase(),
            users: this.state.attachedMembers,
            access: data.get("access")
        }

        if (this.props.objectType.toLowerCase() === "project"){
            body["allDatasets"] = this.state.datasetSelector === "all_datasets";
            body["datasets"] = this.state.datasetsSelected.map(a => a.id);
        }

        req_object_permissions_post(this.props.objectType.toLowerCase(), this.props.itemDetail.id, body).then(function (result) {
            if (result.status === 200) {
                eventBus.dispatch("updateAssetMenu", {  });
                self.loadRec()
                self.setState({listMemberships: [...[]]})
            } else {
                // self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })

    }

    updatePermissions = (personId, role) => {

        this.setState({ loading: true });

        let body = {
            type: this.props.objectType.toLowerCase(),
            user: personId,
            access: role
        }

        req_object_permissions_put(this.props.objectType.toLowerCase(), this.props.itemDetail.id, body).then(function (result) {
            if (result.status === 200) {
                eventBus.dispatch("updateAssetMenu", {  });
            } else {
                // self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    deletePermissions = (personId) => {
        this.setState({ loading: true });
        let self = this;
        req_object_permissions_delete(this.props.objectType.toLowerCase(), this.props.itemDetail.id, personId).then(function (result) {
            if (result.status === 200) {
                self.loadRec()
                eventBus.dispatch("updateAssetMenu", {  });
            } else {
                // self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    componentDidMount() { this.loadRec() }

    Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        return(<div style={{"display":"inline-block"}}>
            <BackdropAppear loading={this.state.loading || false} />
            <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={this.Transition}
            >
                <DialogAppBar title={"Share " + this.props.objectType} close={this.handleClose} />

                <Box sx={{padding: 2}} component="form" onSubmit={this.saveProjectPermissions}>

                    {this.state.filteredMemberships.length > 0 ?
                    <div style={{border: "1px solid #e6e8f0", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "3px"}}>

                            <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                                <Autocomplete
                                    id="member_select"
                                    options={this.state.filteredMemberships}
                                    multiple
                                    autoHighlight
                                    filterSelectedOptions
                                    value={this.state.listMemberships}
                                    onChange={(e, newValues) => {
                                        let members = [];
                                        let memberIds = [];
                                        newValues.forEach((record) => {
                                            memberIds.push(record.personId);
                                            members.push(record);
                                        })
                                        this.setState({attachedMembers: [...memberIds]})
                                        this.setState({listMemberships: [...members]})

                                    }}
                                    getOptionLabel={(option) => option.displayName}

                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.personId}>
                                                {option.displayName}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{width: "380px"}}
                                            {...params}
                                            required={this.state.listMemberships.length === 0}
                                            label="Members to Grant Access"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="field_chart_type_label">Access</InputLabel>
                                    <Select
                                        style={{marginLeft: "10px", paddingLeft: "10px"}}
                                        required
                                        defaultValue={"editor"}
                                        name={"access"}
                                        id={"access"}
                                    >
                                        <MenuItem value="viewer">Viewer</MenuItem>
                                        <MenuItem value="editor">Editor</MenuItem>
                                    </Select>
                                </FormControl>

                                <div style={{width: "200px"}}><Button variant="contained" type="submit" size={"large"}
                                                                      style={{marginLeft: "10px"}}>Add</Button></div>
                            </div>

                    {this.state.datasetOptions.length > 0 ?
                        <div style={{marginBottom: "10px"}}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue={"all_datasets"}
                                    onChange={this.handleDatasetSelectorChange}
                                >
                                    <FormControlLabel value="all_datasets" style={{fontSize: ".8rem"}} control={<Radio  size="small"/>}
                                                      label="Apply to All Datasets in Project"/>
                                    <FormControlLabel value="selected_datasets" style={{fontSize: ".8rem"}} control={<Radio  size="small"/>}
                                                      label="Apply to Selected Datasets"/>
                                </RadioGroup>
                            </FormControl>

                            {this.state.datasetSelector === "selected_datasets" ?
                                <div >
                                    <AutocompleteSelectMulti
                                        size={"small"}
                                        rows={this.state.datasetOptions}
                                        selectedRecords={this.state.datasetsSelected}
                                        selectLabel={"Datasets"}
                                        selectValue={this.selectDatasets}
                                    />
                                </div> : null
                            }

                        </div> : null
                    }
                    </div>:
                        <div style={{border: "1px solid #e6e8f0", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "3px"}}>
                            All members on this account have been shared to. Additional access can be granted on the accounts page <Link component={Link} to={'/settings/account'}>here</Link>.
                        </div>
                    }
                    {this.state && this.state.content && this.state.content.accessPermissions && this.state.content.accessPermissions.length > 0 ?
                        <div>
                        <div style={{fontWeight: "bold", fontSize: "1rem", marginTop: "10px"}}>Current Permissions</div>
                    {this.state.content.accessPermissions.map((perm) =>
                        <div  key={"perm_" + perm.personId} style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <p style={{lineHeight: "48px", fontSize: ".9rem", fontWeight: "bold"}}>{perm.personDisplayName}</p><div>
                        <FormControl fullWidth style={{display: "inline"}}>
                        <InputLabel id="field_chart_type_label">Access</InputLabel>
                        <Select
                        style={{marginLeft: "10px", paddingLeft: "10px", width: "130px"}}
                        required
                        defaultValue={perm.role}
                        name={"access"}
                        id={"access"}
                        onChange={(e) => {this.updatePermissions(perm.personId, e.target.value)}}
                        >
                        <MenuItem key={"viewer"} value="viewer">Viewer</MenuItem>
                        <MenuItem key={"editor"} value="editor">Editor</MenuItem>
                        </Select>

                        </FormControl>
                        <IconButton style={{display: "inline-block", marginLeft: "10px"}}
                        onClick={() => {this.deletePermissions(perm.personId)}}
                        >
                        <DeleteIcon />
                        </IconButton>
                        </div>
                        </div>
                        )
                    }
                        </div>: null
                    }
                </Box>
            </Dialog>
        </div>)
    }
}
