import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";

export default class TokenCreatedDialog extends React.Component {

    render () {
        return (
        <Dialog open={this.props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Token Created"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Success! Copy and save the following token for future use. It will be required in API requests.


                    <br/><br/>
                    <center>{this.props.tokenValue}
                        <IconButton style={{display: "inline-block"}}
                                    onClick={() => {navigator.clipboard.writeText(this.props.tokenValue)}}>
                            <ContentCopyIcon style={{ fontSize: "18px"}} />
                        </IconButton>
                    </center>
                    <br/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={() => { this.props.close() }}>Close</Button>
            </DialogActions>
        </Dialog>
        )
    }
}

