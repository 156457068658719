import React, {Component} from 'react';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FeedIcon from '@mui/icons-material/Feed';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ApiIcon from '@mui/icons-material/Api';
import SchemaIcon from '@mui/icons-material/Schema';
import RefreshIcon from '@mui/icons-material/Refresh';
import Menu from '@mui/material/Menu';
import {ListItemIcon, Tooltip} from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import AddBoxIcon from '@mui/icons-material/AddBox';

export default class MenuAddItem extends Component {
    state = {
        open: false,
        anchorEl: null,
        redirect: false
    };

    handleMenuClick = (event) => {
        event.preventDefault();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleLogout = () => {
        localStorage.removeItem("rtoken");
        this.handleMenuClose();
        this.setState({ redirect: true });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        if (this.state.redirect) {
            window.location = '/home';
        }

        return(
            <div style={{marginLeft: "auto", backgroundColor:"#f7f7f7"}}>
                <IconButton
                    size="small"
                    sx={{ ml: 0, color: "#FF9007", display: "none" }}
                    aria-label="Open usage instructions"
                    onClick={() => {  this.props.openItem("infopage", {"name":"instructo!", id: 0, type: "infopage" }) }}
                >
                    <Tooltip id="update_feed" title="Dataset Templates">
                        <SchemaIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    size="small"
                    sx={{ ml: 0, color: "#757b7e" }}
                    aria-label="Refresh Menu"
                    onClick={() => {
                        this.props.refresh(true, null);
                    }}
                >
                    <Tooltip id="update_menu" title="Refresh left menu">
                        <RefreshIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    size="small"
                    sx={{ ml: 0, color: "#FF9007" }}
                    aria-label="Open usage instructions"
                    onClick={() => {  this.props.openItem("infopage", {"name":"instructo!", id: 0, type: "infopage" }) }}
                >
                    <Tooltip id="update_feed" title="Updates & documentation">
                        <FeedIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    size="small"
                    sx={{ ml: 0, color: "#406cbf" }}
                    aria-controls={this.state.open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-label="Add a new item"
                    aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                    onClick={this.handleMenuClick}
                >
                    <Tooltip id="add_new_idem" title="Add new element">
                        <AddBoxIcon  />
                    </Tooltip>
                </IconButton>
                <Menu

                    anchorEl={this.state.anchorEl}
                    id="left-menu"
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                    onClick={this.handleMenuClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    {
                        (localStorage.getItem("rtoken")) ?
                            (<div>
                                <MenuItem onClick={() => {this.props.addItem("project")}} style={{fontSize: ".8rem !important"}}>
                                    <ListItemIcon>
                                        <AccountTreeIcon fontSize="small" />
                                    </ListItemIcon>
                                    New Project
                                </MenuItem>
                                <MenuItem onClick={() => {this.props.addItem("dataset")}} style={{fontSize: ".8rem !important"}}>
                                    <ListItemIcon>
                                        <TableViewIcon fontSize="small" />
                                    </ListItemIcon>
                                    New Dataset
                                </MenuItem>
                                <MenuItem onClick={() => {this.props.addItem("filter")}} style={{fontSize: ".8rem !important"}}>
                                    <ListItemIcon>
                                        <FilterAltIcon fontSize="small" />
                                    </ListItemIcon>
                                    New Filter
                                </MenuItem>
                                <MenuItem onClick={() => {this.props.addItem("apiwebhook")}} style={{fontSize: ".8rem !important"}}>
                                    <ListItemIcon>
                                        <ApiIcon fontSize="small" />
                                    </ListItemIcon>
                                    New API
                                </MenuItem>

                            </div>)
                        : (<div>
                                <MenuItem onClick={this.handleLogout}><ListItemIcon><LoginIcon /></ListItemIcon>Log In</MenuItem></div>)
                    }
                </Menu>
            </div>
        )
    }
}
