import React, {} from 'react';
import Box from "@mui/material/Box";

import {
    req_api_get,
    req_api_put,
    req_get_slug_lookup,
    req_get_token_list,
    req_token_post

} from "../../helpers/requests";
import Button from "@mui/material/Button";
import { Drawer, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {Autocomplete} from "@mui/lab";
import BackdropAppear from "../staticDisplays/backdropAppear";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import ApiTestPanel from "./apiTestPanel";
import Paper from "@mui/material/Paper";
import SettingsIcon from "@mui/icons-material/Settings";
import CreateTokenPanel from "../formControls/createTokenPanel";
import TokenCreatedDialog from "../dialogs/dialogs";
import {GENERIC_LOAD_ERROR} from "../../helpers/strConstants";
import ApiFieldPanel from "../formControls/apiFieldPanel";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div sx={{width: "auto"}}>
                    {children}
                </div>
            )}
        </div>
    );
}

export default class ApiEditPanel extends React.Component {

    state = {
        editMode: true,
        content: {},
        id: this.props.did,
        filters: [],
        tokens: [],
        currentFilterRec: null,
        currentFilter: "",
        tokenTab: 0,
        attachedTokens: [],
        currentToken: "Select a Token",
        newToken: {tokenRemoteAddresses: ["0.0.0.0"]},
        loading: false,
        alert: {open: false, message: "", type: ""},
        tokenError: false,
        tokenErrorMessage: "",
        listTokens: [],
        showToken: false,
        newTokenValue: "",
        originalSlug: null,
        slugError: false,
        openSettings: false,
        currentUserRole: (JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))) ? "owner": "viewer"
    }

    loadRec = () => {
        let self = this;
        self.setState({loading: true});
        req_api_get(this.props.did).then(function (result) {
            if (result.status === 200) {
                let rowrec = result;
                let selectedFilter = "";

                self.state.filters.forEach(function (filter) {
                    if (filter.id === rowrec.data.data.filterId) {
                        selectedFilter = filter;
                    }
                });
                self.setState({currentUserRole: rowrec.data.data.role || self.state.currentUserRole})
                self.setState({originalSlug: rowrec.data.data.slug})
                self.setState({currentFilter: rowrec.data.data.filterId, currentFilterRec: selectedFilter})
                self.setState({content: rowrec.data.data, attachedTokens: [...rowrec.data.data.tokens]});
                self.setState({dataLoaded: true});

                let ctokens = rowrec.data.data.tokens;
                req_get_token_list("token").then(function (result2) {
                    if (result2.status === 200) {
                        let rowrecs = [];
                        let tokenlist = [];

                        result2.data.data.forEach(function (record) {
                            rowrecs.push(record);
                            if (ctokens.includes(record.id)) {
                                tokenlist.push(record)
                            }
                        });

                        self.setState({tokens: [...rowrecs], listTokens: [...tokenlist], loading: false});
                    } else {
                        self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
                    }
                })

            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    loadFilters = () => {
        let self = this;
        this.setState({ loading: true });

        let filters = JSON.parse(localStorage.getItem("ds_filters"));
        let rowrecs = [];
        filters.data.data.forEach(function (record) {
            rowrecs.push(record);
        });
        if (self.props.itemDetail && self.props.itemDetail.filter) {
            rowrecs.forEach(filter => {
                if (filter.id === self.props.itemDetail.filter) {
                    self.setState({currentFilter: filter.id, "currentFilterRec": filter})
                }
            })
        }
        self.setState({filters: [...rowrecs], loading: false});
        self.loadRec();
    }

    handleCloseNewToken = () => { this.setState({showToken: false}) }

    loadTokens = () => {
        let self = this;
        self.setState({loading: true});
        req_get_token_list("token").then(function (result) {
            if (result.status === 200) {
                let rowrecs = [];
                let tokenlist = [];

                result.data.data.forEach(function (record) {
                    rowrecs.push(record);
                    if (self.state.attachedTokens.includes(record.id)) {
                        tokenlist.push(record)
                    }
                });
                self.setState({tokens: [...rowrecs], listTokens: [...tokenlist], loading: false});
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    selectFilter = (e, newValue) => {
        this.setState({showQueryEditor: false});
        let set = false;
        let content = this.state.content;
        content.currentFilter = newValue.id;
        this.state.filters.forEach(filter => {
            if (filter.id === newValue.id && !set) {
                this.setState({content: content, "currentFilter": newValue.id, "currentFilterRec": filter})
                set = true;
            }
        })
    }

    confirmAvailability(slug){

        let self = this;
        req_get_slug_lookup(slug).then(function (result) {
            if (result.status === 200) {
                if ((self.state.originalSlug !== slug) && result.data.inUse > 0){
                    self.setState({slugError: true})
                } else {
                    self.setState({slugError: false})
                }
                self.setState({loading: false});
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    handleChange = (field, e) => {
        let content = this.state.content;

        if (field === "slug") {
            let newSlug = e.target.value.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '');
            content[field] = newSlug;
            this.confirmAvailability(newSlug)

        } else {
            content[field] = e.target.value;
        }
        this.setState({ content: {...content} });
    };

    handleNewTokenChange = (field, e) => {
        let newToken = this.state.newToken;

        if (field === "tokenRemoteAddresses"){
            newToken[field] = e;
        } else {
            newToken[field] = e.target.value;
        }
        this.setState({newToken: {...newToken}});
    };

    deleteSelectedToken = (tokenId) => {
        let currentTokens = this.state.attachedTokens;
        currentTokens.splice(currentTokens.indexOf(tokenId), 1);
        this.setState({attachedTokens: currentTokens});
    }

    submitFormData = (e) => {
        e.preventDefault();
        let body = {
            "filterId": this.state.currentFilter,
            "description": this.state.content.description.trim(),
            "name": this.state.content.name.trim(),
            "slug": this.state.content.slug.trim(),
            "tokens": this.state.attachedTokens
        }
        this.setState({loading: true});
        let self = this;
        if (!self.state.slugError) {
            req_api_put("api", this.props.did, body).then(function (result) {
                if (result.status === 200) {
                    self.setState({loading: false});
                    self.toggleSettings(false)

                } else {
                    self.setState({
                        loading: false,
                        alert: {
                            ...{
                                "open": true,
                                "message": "There was a problem saving the API.",
                                "type": "error",
                                "timeout": 5000
                            }
                        }
                    });
                }
            })
        } else {
            self.setState({
                loading: false,
                alert: {
                    ...{
                        "open": true,
                        "message": "The endpoint is invalid and this API cannot be saved.",
                        "type": "error",
                        "timeout": 5000
                    }
                }
            });
        }
    }

    componentDidMount() { this.loadFilters(); }

    refreshContent(){ this.loadFilters(); }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    changeTab = (event, newValue) => {
        this.setState({tokenTab: newValue});
    }

    addToken = (e) => {
        e.preventDefault();
        let attached = this.state.attachedTokens;
        if (!attached.includes(this.state.currentToken)) {
            attached.push(this.state.currentToken);
        }
        this.setState({"currentToken": "Select a Token", attachedTokens: attached})
    }

    createToken = () => {
        
        const fields = ["tokenName", "tokenRemoteAddresses", "tokenExpirationDate", "tokenVerbs"];
        let missingRequired = false;
        let newTokenCopy = this.state.newToken;
        fields.forEach((field) => {
            if (!this.state.newToken[field]){
                newTokenCopy[field + "Error"] = true;
                missingRequired = true;
            } else {
                newTokenCopy[field + "Error"] = null;
            }
        })

        if (missingRequired) {
            this.setState({newToken: {...newTokenCopy}})
        } else {
            let body = {
                name: this.state.newToken.tokenName,
                allowed: this.state.newToken.tokenRemoteAddresses,
                verbs: this.state.newToken.tokenVerbs,
                expiration: this.state.newToken.tokenExpirationDate,
            }

            let self = this;
            self.setState({loading: true});
            req_token_post("token", body).then(function (result) {
                if (result.status === 200 && (!result.data.status || result.data.status !== "fail")) {
                    self.setState({showToken: true, newTokenValue: result.data.token});

                    let t = {"tokenRemoteAddresses": ["0.0.0.0"]};
                    self.setState({newToken: {...t}})
                    self.loadTokens();

                    let attached = self.state.attachedTokens;
                    attached.push(result.data.id);
                    self.setState({"currentToken": "Select a Token", attachedTokens: attached})
                    self.setState({loading: false});
                    self.setState({tokenTab: 0});

                    let tokenlist = [];
                    self.state.tokens.forEach(function (tokens) {
                        if (self.state.attachedTokens.includes(tokens.id)) {
                            tokenlist.push(tokens)
                        }
                    });
                    self.setState({listTokens: [...tokenlist]});
                    self.setState({loading: false});
                } else if (result && result.data && result.data.msg) {
                    self.setState({
                        loading: false,
                        alert: { ...{ "open": true, "message": result.data.msg, "type": "error", "timeout": 5000 }}
                    });
                } else {
                    self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem saving the Token.", "type": "error", "timeout": 5000 }}});
                }
            })
        }
    }

    getTokenDisplay = (selToken) => {
        let tokenDetail = {};
        this.state.tokens.every(token => {
            if (selToken === token.id) {
                tokenDetail = token;
                return false;
            }
            return true;
        })

        if (tokenDetail.id) {
            return <div>{tokenDetail.name + " - [ " + tokenDetail.verbs.toString() + " ] - Expires: " + tokenDetail.expiration}
                <IconButton onClick={() => {
                    this.deleteSelectedToken(tokenDetail.id)
                }}><DeleteIcon
                    sx={{fontSize: "18px", color: '#eee'}}/></IconButton>
            </div>;
        } else {
            return "";
        }
    }

    toggleSettings = (open) => {
        this.setState({"openSettings": open});
    };

    purgeAlert = () => { this.setState({alert: {...{ open: false, message: "", type: "" }}}) }

    render() {
        return (
            <Paper sx={{ width: '100%', marginTop: 0 }}>
                <div style={{"display": "flex", "justifyContent": "space-between", "backgroundColor": "#e5e5e5", height: "36px", borderBottom: "1px solid #ccc", paddingLeft: "10px"}}>
                <BackdropAppear loading={this.state.loading || false} />
                <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
                    {this.state && ["owner", "admin", "editor"].includes(this.state.currentUserRole) ?
                    <div style={{marginRight: "10px", paddingLeft: "5px", paddingBottom: "5px", fontSize: ".9rem"}}>
                        <b>API</b>: {this.state ? this.state.content.name : ""}
                        <Button
                                sx={{ "& .MuiButton-startIcon": { marginRight: "3px" }}}
                                onClick={() => {this.toggleSettings(!this.state.openSettings)}}
                                aria-label="settings"
                                variant={"text"}
                                style={{color: "#556677", marginLeft: "10px", fontWeight: "400"}}
                                startIcon={<SettingsIcon style={{fontSize: "16px"}}/>}>Settings</Button>
                    </div>:
                    <div style={{marginRight: "10px", paddingLeft: "5px", paddingBottom: "5px", fontSize: ".9rem"}}> <b>API</b>: {this.state ? this.state.content.name : ""}
                        <div style={{height: "36px", lineHeight: "36px", display: "inline-block", marginLeft: "10px", fontWeight: "bold"}}>[ View Only ]</div>
                    </div>}
                </div>
                <div style={{position: "relative", overflow: "hidden"}}>
                    <Drawer
                        transitionDuration={22}
                        sx={{
                            '& .MuiDrawer-root': {
                                position: 'absolute',
                                backgroundColor: "#f9f9f6",
                                boxShadow: "0 4px 2px -2px #ccddee70"
                            },
                            '& .MuiPaper-root': {
                                position: 'absolute',
                                backgroundColor: "#f4f4f4",
                                boxShadow: "0 4px 2px -2px #ccddee70",
                                borderLeft: "1px solid #bbb",
                                borderBottom: "1px solid #bbb"
                            }
                        }}

                        variant="persistent"
                        anchor="right"
                        width="380px"
                        open={this.state.openSettings}
                        onClose={() => {this.toggleSettings(false)}}
                    >

                        <Box sx={{
                            width: 'intrisic',
                            padding: "10px",
                            textAlign: "left",

                        }} component="form" onSubmit={this.submitFormData}>
                            <Typography align={"left"} style={{fontWeight: "bold", fontSize: "1rem"}}>
                                <IconButton onClick={() => this.toggleSettings(false)}><ExitToAppIcon style={{fontSize: "1.3rem"}} /></IconButton>
                                API Settings</Typography>

                            <ApiFieldPanel fieldChange={this.handleChange} currentState={this.state} selectFilter={this.selectFilter}/>

                            <div style={{minHeight: "150px"}}>
                                <div style={{
                                    padding: "10px",
                                    backgroundColor: "#dfdfdf",
                                    borderColor: "1px solid #ccc",
                                    borderRadius: "3px",
                                    marginTop: "5px",
                                    width: "auto"

                                }}>
                                    <Tabs value={this.state.tokenTab}
                                          onChange={this.changeTab}
                                          TabIndicatorProps={{
                                              style: {
                                                  backgroundColor: "#666"
                                              }
                                          }}
                                    >
                                        <Tab label="Allow existing token(s)" {...this.a11yProps(0)} style={{
                                            fontSize: "1rem",
                                            minHeight: "20px",
                                            textTransform: "none"
                                        }}/>
                                        {(JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))) ?
                                            <Tab label="Create New token(s)" {...this.a11yProps(1)} style={{
                                                fontSize: "1rem",
                                                minHeight: "20px",
                                                textTransform: "none"
                                            }}/> : null
                                        }

                                    </Tabs>

                                    <TabPanel value={this.state.tokenTab} index={0} style={{marginTop: "10px", width: "auto", paddingTop: "5px"}}>
                                        <Box>

                                            <Autocomplete
                                                style={{display: "inline-block"}}
                                                id="token_select"
                                                options={this.state.tokens}
                                                multiple
                                                autoHighlight
                                                filterSelectedOptions
                                                value={this.state.listTokens}
                                                onChange={(e, newValues) => {
                                                    let tokens = [];
                                                    let tokenIds = [];
                                                    newValues.forEach((record) => {
                                                        tokenIds.push(record.id);
                                                        tokens.push(record);
                                                    })
                                                    this.setState({attachedTokens: [...tokenIds]})
                                                    this.setState({listTokens: [...tokens]})

                                                }}
                                                getOptionLabel={(option) => option.name + " - [ " + option.verbs.toString() + " ] - Expires: " + option.expiration}

                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option.id}>
                                                            {option.name + " - [ " + option.verbs.toString() + " ] - Expires: " + option.expiration}
                                                        </li>
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        style={{width: "380px"}}
                                                        {...params}
                                                        required={this.state.listTokens.length === 0}
                                                        label="Token(s) to Grant Access"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </TabPanel>

                                    <TabPanel value={this.state.tokenTab} index={1} style={{width: "auto", paddingTop: "5px"}}>
                                        <CreateTokenPanel token={this.state.newToken} handleNewTokenChange={this.handleNewTokenChange} createToken={this.createToken} />
                                    </TabPanel>
                                </div>
                            </div>


                            {(this.state.editMode === true) ?
                                <div>
                                    <div align={"right"} style={{marginTop: "10px"}}>
                                        <Button variant="contained" size="small" type="submit"
                                        >Save</Button>

                                        <Button variant="contained" size="small" style={{marginLeft: "5px"}}
                                                onClick={() => {
                                                    this.toggleSettings(false);
                                                    this.refreshContent()
                                                }}>Cancel</Button>
                                    </div>


                                </div> : null
                            }
                        </Box>
                    </Drawer>


                    <div style={{minHeight: window.innerHeight - 126}}>
                        <div style={{
                            textAlign: "left",

                        }}>
                            <div style={{fontWeight: "bold", margin: "10px", fontSize: "1rem"
                            }}>API Endpoint: <span style={{color: "#406cbf"}}>{process.env.REACT_APP_USER_API}/{this.state.content.handle}/{this.state.content.slug}?token=&#123;your token value&#125;</span>
                                <IconButton style={{display: "inline-block"}}
                                            onClick={() => {navigator.clipboard.writeText(process.env.REACT_APP_USER_API+ "/" + this.state.content.handle +"/"+ this.state.content.slug+ "?token={your token value}")}}>
                                    <ContentCopyIcon style={{ fontSize: "18px"}} />
                                </IconButton>
                            </div>

                            <ApiTestPanel tokens={this.state.listTokens} slug={this.state.content.slug} currentFilter={this.state.currentFilterRec } handle={this.state.content.handle} />
                        </div>
                    </div>

                    <TokenCreatedDialog open={this.state.showToken} tokenValue={this.state.newTokenValue} close={this.handleCloseNewToken} />

                </div>
            </Paper>
        )
    }
}