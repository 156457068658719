import React, {Component} from 'react';
import DataConsoleData from "../dataConsoleData/dataConsoleData";
import FilterEditPanel from "../forms/filterEditPanel";
import ApiEditPanel from "../forms/apiEditPanel";
import Instructions from "../pages/instructions";
import Templates from "../pages/templates";

export default class PortalItemDisplay extends Component {

    render() {
        return (
            <div style={{height: '200%', minHeight: "100%"}}>
                {(this.props.tabData.detail.type === "dataset") ?
                    <DataConsoleData key={"dataConsoleData"} did={this.props.tabData.detail.id}
                                     openItem={this.props.openItem}
                                     addItem={this.props.addItem}
                                     name={this.props.tabData.name} refresh={this.props.refresh}/>: null
                }
                {(this.props.tabData.detail.type === "filter") ?
                    <FilterEditPanel type={"edit"} objectContent={{}}
                                     openItem={this.props.openItem}
                                     did={this.props.tabData.detail.id} refresh={this.props.refresh}/> : null
                }
                {(this.props.tabData.detail.type === "apiwebhook") ?
                    <ApiEditPanel did={this.props.tabData.detail.id} />: null
                }
                {(this.props.tabData.detail.type === "infopage" && this.props.tabData.detail.id === 0) ?
                    <Instructions did={this.props.tabData.detail.id} refresh={this.props.refresh}/>: null
                }

                {(this.props.tabData.detail.type === "infopage" && this.props.tabData.detail.id === 1) ?
                    <Templates addItem={this.props.addItem} />: null
                }

            </div>);
    }
}
