import axios from "axios";

// -------------------------------------------------------------------------------------
// -- User Login Related Requests ------------------------------------------------------
// -------------------------------------------------------------------------------------
export function req_login(email, password) {
    return req('POST', process.env.REACT_APP_API + "/login", {
        "email": email,
        "password": password
    });
}

export function req_reset_password(email) {
    return req('POST', process.env.REACT_APP_API + "/reset_password", {
        "email": email
    });
}

export function req_user_register(email, password, displayName, handle, docsChecked) {
    return req('POST', process.env.REACT_APP_API + "/register", {
        "email": email,
        "password": password,
        "displayName": displayName,
        "handle": handle,
        docsChecked: docsChecked,
        accountName: "Acct: " + displayName
    });
}

export function req_get_verify_email(pid, verify) {
    return req('GET', process.env.REACT_APP_API + "/verify_email?pid=" + pid + "&verify=" + verify);
}

export function req_user_get(object, objId) {
    return req('GET', process.env.REACT_APP_API + "/person/"+objId+"?token=" + localStorage.getItem("rtoken"));
}

export function req_user_account_get(object, objId) {
    return req('GET', process.env.REACT_APP_API + "/person/"+objId+"/account?token=" + localStorage.getItem("rtoken"));
}

export function req_update_account_name(body) {
    return req('POST', process.env.REACT_APP_API + "/person/updateAccountName?token=" + localStorage.getItem("rtoken"), body);
}

export function req_user_select_community_account() {
    return req('GET', process.env.REACT_APP_API + "/person/selectCommAccount?token=" + localStorage.getItem("rtoken"));
}

export function req_user_invite(objId, body) {
    return req('POST', process.env.REACT_APP_API + "/person/"+objId+"/invite?token=" + localStorage.getItem("rtoken"), body);
}

export function req_user_del_invite(objId, body) {
    return req('DELETE', process.env.REACT_APP_API + "/person/"+objId+"/delete_invite?token=" + localStorage.getItem("rtoken"), body);
}

export function req_validate_login() {
    return req('POST', process.env.REACT_APP_API + "/person/validate_token?token=" + localStorage.getItem("rtoken"), {
        "token": localStorage.getItem("rtoken")
    });
}

export function req_user_settings_put(objId, body) {
    return req('PUT', process.env.REACT_APP_API + "/person/"+objId+"/settings?token=" + localStorage.getItem("rtoken"), body);
}


// -- User Login Related Requests ---------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- Generic Object Requests ----------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_generic_get_list(object) {
    return req('GET', process.env.REACT_APP_API + "/dataset/"+object+"/list/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_generic_get_item(object, objId) {
    return req('GET', process.env.REACT_APP_API + "/dataset/"+objId+"?token=" + localStorage.getItem("rtoken"));
}

export function req_generic_put(oType, object, objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('PUT', process.env.REACT_APP_API + "/" + oType + "/" + object + "/" + objId + "/update?token=" + localStorage.getItem("rtoken"), body);
}

export function req_generic_post(oType, object, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/" + oType + "/" + object + "/create?token=" + localStorage.getItem("rtoken"), body);
}

export function req_generic_delete(oType, object, objId) {
    return req('DELETE', process.env.REACT_APP_API + "/" + oType + "/" + object + "/"+objId+ "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_generic_purge(object) {
    return req('DELETE', process.env.REACT_APP_API + "/dataset/purge/" + object+ "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

// -- Generic Object Requests -------------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- Dataset Object Requests ----------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_generic_get_dataset_list() {
    return req('GET', process.env.REACT_APP_API + "/dataset/list/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_generic_get_dataset(objId) {
    return req('GET', process.env.REACT_APP_API + "/dataset/"+objId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_generic_get_dataset_query(objId, query) {
    let body = {"account": JSON.parse(localStorage.getItem("selectedAccount"))};
    return req('POST', process.env.REACT_APP_API + "/dataset/"+objId+"/query?query="+query+"&token=" + localStorage.getItem("rtoken"), body);
}

export function req_generic_dataset_post(object, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/"+object+"/create?token=" + localStorage.getItem("rtoken"), body);
}

export function req_generic_dataset_put(objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('PUT', process.env.REACT_APP_API + "/dataset/" + objId + "?token=" + localStorage.getItem("rtoken"), body);
}

export function req_generic_delete_dataset(objId) {
    return req('DELETE', process.env.REACT_APP_API + "/dataset/" + objId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_delete_dataset_column(objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/dataset/"+objId+"/delete_column?token=" + localStorage.getItem("rtoken"), body);
}

export function req_clone_dataset_column(objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/dataset/"+objId+"/clone_column?token=" + localStorage.getItem("rtoken"), body);
}

export function req_add_dataset_column(objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/dataset/"+objId+"/add_column?token=" + localStorage.getItem("rtoken"), body);
}

export async function req_upload_dataset_csv(objId, formData) {
    let url = process.env.REACT_APP_API + "/upload/data/"+ objId +"?token=" + localStorage.getItem("rtoken");

    return await
        axios({
            method: "POST", url: url, data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })

        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error;
        });
}


export async function req_upload_schema_create(formData) {
    let url = process.env.REACT_APP_API + "/upload/dataschemacreate?token=" + localStorage.getItem("rtoken");

    return await
        axios({
            method: "POST", url: url, data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })

            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
}


export async function req_upload_csv_create(formData) {
    let url = process.env.REACT_APP_API + "/upload/datacsvcreate?token=" + localStorage.getItem("rtoken");

    return await
        axios({
            method: "POST", url: url, data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })

            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
}

export async function req_upload_excel_create(formData) {
    let url = process.env.REACT_APP_API + "/upload/dataexcelcreate?token=" + localStorage.getItem("rtoken");

    return await
        axios({
            method: "POST",
            url: url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })

            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
}


// -- Dataset Object Requests -------------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- Project Object Requests ----------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_projects_get(object, objId) {
    return req('GET', process.env.REACT_APP_API + "/project/" + objId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_projects_post(body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/project/create?token=" + localStorage.getItem("rtoken"), body);
}

export function req_projects_put(objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('PUT', process.env.REACT_APP_API + "/project/" + objId +"/update?token=" + localStorage.getItem("rtoken"), body);
}

export function req_projects_get_list() {
    return req('GET', process.env.REACT_APP_API + "/project/list/" + JSON.parse(localStorage.getItem("selectedAccount")) +"?token=" + localStorage.getItem("rtoken"));
}

export function req_project_hierarchy_get(object, id) {
    return req('GET', process.env.REACT_APP_API + "/project/" + id + "/hierarchy?token=" + localStorage.getItem("rtoken"));
}

/*
export function req_project_menu_hierarchy_get() {
    return req('GET', process.env.REACT_APP_API + "/project/menuhierarchy?token=" + localStorage.getItem("rtoken"));
}
*/
export function req_project_menu_hierarchy_get() {
    return req('GET', process.env.REACT_APP_API + "/project/menuhierarchy/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_project_delete(objId) {
    return req('DELETE', process.env.REACT_APP_API + "/project/" + objId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}



// -------------------------------------------------------------------------------------
// -- Template Object Requests ---------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_templates_get_list(object) {
    return req('GET', process.env.REACT_APP_API + "/"+object+"/list?token=" + localStorage.getItem("rtoken"));
}

export function req_templates_post(object, body) {
    return req('POST', process.env.REACT_APP_API + "/"+object+"/create?token=" + localStorage.getItem("rtoken"), body);
}

export function req_templates_search_post(body) {
    return req('POST', process.env.REACT_APP_API + "/template/search?token=" + localStorage.getItem("rtoken"), body);
}

export function req_template_delete(objId) {
    return req('DELETE', process.env.REACT_APP_API + "/template/" + objId+"?token=" + localStorage.getItem("rtoken"));
}

// -- Template Object Requests ------------------------------------------------------END

// -------------------------------------------------------------------------------------
// -- Filter Object Requests -----------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_filter_post(oType, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/" + oType + "/create?token=" + localStorage.getItem("rtoken"), body);
}

export function req_filter_get(filter) {
    return req('GET', process.env.REACT_APP_API + "/filter/" + filter + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_filter_put(oType, objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('PUT', process.env.REACT_APP_API + "/" + oType + "/" + objId + "/update?token=" + localStorage.getItem("rtoken"), body);
}

export function req_get_filter_list() {
    return req('GET', process.env.REACT_APP_API + "/filter/list/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_filter_delete(objId) {
    return req('DELETE', process.env.REACT_APP_API + "/filter/" + objId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}


// -- Filter Object Requests --------------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- API/Webhook Object Requests ------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_api_post(oType, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/" + oType + "/create?token=" + localStorage.getItem("rtoken"), body);
}

export function req_api_put(oType, objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('PUT', process.env.REACT_APP_API + "/" + oType + "/" + objId + "/update?token=" + localStorage.getItem("rtoken"), body);
}

export function req_api_get(objId) {
    return req('GET', process.env.REACT_APP_API + "/api/" + objId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"))
}

export function req_api_delete(objId) {
    return req('DELETE', process.env.REACT_APP_API + "/api/" + objId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_get_api_list(object) {
    return req('GET', process.env.REACT_APP_API + "/" + object + "/list/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

// -- API/Webhook Object Requests ---------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- Token Object Requests ------------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_token_post(oType, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/" + oType + "/create?token=" + localStorage.getItem("rtoken"), body);
}

export function req_token_put(oType, objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('PUT', process.env.REACT_APP_API + "/" + oType + "/" + objId + "/update?token=" + localStorage.getItem("rtoken"), body);
}

export function req_get_token_list(object) {
    return req('GET', process.env.REACT_APP_API + "/"+object+"/list/"+ JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_token_get(object, objId) {
    return req('GET', process.env.REACT_APP_API + "/" + object + "/"+objId+"?token=" + localStorage.getItem("rtoken"));
}

export function req_token_delete(objId) {
    return req('DELETE', process.env.REACT_APP_API + "/token/" + objId+"?token=" + localStorage.getItem("rtoken"));
}

export function req_api_attach_token(oType, body, api_id) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('PUT', process.env.REACT_APP_API + "/api/" + api_id + "/add/token?token=" + localStorage.getItem("rtoken"), body);
}

// -- Token Object Requests ---------------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- Lookup Object Requests -----------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_get_slug_lookup(value) {
    return req('GET', process.env.REACT_APP_API + "/api/slug_lookup/" + JSON.parse(localStorage.getItem("selectedAccount")) +"?slug=" + value + "&token=" + localStorage.getItem("rtoken"));
}

export function req_get_handle_lookup(value) {
    return req('GET', process.env.REACT_APP_API + "/handle_lookup?handle=" + value);
}

// -- Lookup Object Requests --------------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- Notification Object Requests -----------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_get_notifications_list() {
    return req('GET', process.env.REACT_APP_API + "/notification/list?token=" + localStorage.getItem("rtoken"));
}

export function req_post_notifications_response(notificationId, body) {
    return req('POST', process.env.REACT_APP_API + "/notification/" + notificationId + "/respond?token=" + localStorage.getItem("rtoken"), body);
}

// -- Notification Object Requests --------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- Membership Object Requests -------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_get_membership_list() {
    return req('GET', process.env.REACT_APP_API + "/membership/list?token=" + localStorage.getItem("rtoken"));
}

export function req_object_permissions_post(objType, objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('POST', process.env.REACT_APP_API + "/membership/obj_perm/" + objType + "/" + objId + "?token=" + localStorage.getItem("rtoken"), body);
}

export function req_object_permissions_put(objType, objId, body) {
    body["account"] = JSON.parse(localStorage.getItem("selectedAccount"));
    return req('PUT', process.env.REACT_APP_API + "/membership/obj_perm/" + objType + "/" + objId + "?token=" + localStorage.getItem("rtoken"), body);
}

export function req_object_permissions_delete(objType, objId, personId) {
    return req('DELETE', process.env.REACT_APP_API + "/membership/obj_perm_delete/" + objType + "/" + objId+ "/" + personId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_member_delete(personId) {
    return req('DELETE', process.env.REACT_APP_API + "/membership/member_delete/" + personId + "/" + JSON.parse(localStorage.getItem("selectedAccount")) + "?token=" + localStorage.getItem("rtoken"));
}

export function req_object_permissions_get(objType, objId) {
    return req('GET', process.env.REACT_APP_API + "/membership/obj_perm/" + objType + "/" + objId + "?token=" + localStorage.getItem("rtoken"));
}

export function req_user_account_members_get(objId) {
    return req('GET', process.env.REACT_APP_API + "/membership/"+objId+"/members?token=" + localStorage.getItem("rtoken"));
}

export function req_membership_update_post(body) {
    body["account"] = JSON.parse(localStorage.getItem("ownedAccount"));
    return req('POST', process.env.REACT_APP_API + "/membership/update?token=" + localStorage.getItem("rtoken"), body);
}


// -- Membership Object Requests ----------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- Misc Requests --------------------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_send_contact_email(body) {
    return req('POST', process.env.REACT_APP_API + "/contact_form", body);
}

// -- Misc Requests -----------------------------------------------------------------END


// -------------------------------------------------------------------------------------
// -- API Test Submission --------------------------------------------------------------
// -------------------------------------------------------------------------------------

export function req_user_test_request(verb, handle, slug, token, body, id, querystring) {

    let res = null;
    if (verb === "GET_ID"){
        res = req("GET", process.env.REACT_APP_USER_API + "/" + handle + "/" + slug + "/" + id + "?token=" + token + querystring, {});
    } else if (verb === "GET_LIST"){
        res = req("GET", process.env.REACT_APP_USER_API + "/" + handle + "/" + slug + "?token=" + token + querystring, {});
    } else if (verb === "POST"){
        res = req("POST", process.env.REACT_APP_USER_API + "/" + handle + "/" + slug + "?token=" + token + querystring, body);
    } else if (verb === "PUT"){
        res = req("PUT", process.env.REACT_APP_USER_API + "/" + handle + "/" + slug + "/" + id + "?token=" + token + querystring, body);
    } else if (verb === "DELETE"){
        res = req("DELETE", process.env.REACT_APP_USER_API + "/" + handle + "/" + slug + "/" + id + "?token=" + token + querystring, body);
    }

    return res
}

// ----------------------------------------------------------------------------------END


async function req(verb, url, body){
    if (verb === "POST") {
        return await axios.post(url, body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    } else if (verb === "GET") {
        return await axios.get(url)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    } else if (verb === "PUT") {
        return await axios.put(url, body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    }
    else if (verb === "DELETE") {
        return await axios.delete(url)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    }
}