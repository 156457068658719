import React, {Component} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from "@mui/icons-material/Delete";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import { Card, TextField } from "@mui/material";
import {
    req_api_post,
    req_get_token_list,
    req_token_post,
    req_api_attach_token, req_get_slug_lookup
} from "../../helpers/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./apiDialog.css";
import SnackbarInvoke from "../messaging/snackbarInvoke";
import BackdropAppear from "../staticDisplays/backdropAppear";
import {Autocomplete} from "@mui/lab";
import CreateTokenPanel from "../formControls/createTokenPanel";
import {openOnCreate} from "../../helpers/settings";
import TokenCreatedDialog from "../dialogs/dialogs";
import DialogAppBar from "../dialogs/dialogAppBar";
import {GENERIC_LOAD_ERROR} from "../../helpers/strConstants";
import ApiFieldPanel from "../formControls/apiFieldPanel";
import eventBus from "../../helpers/eventBus";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default class ApiDialog extends Component {

    state = {
        open: false,
        content: this.props.objectContent,
        currentFilterRec: null,
        filters: [],
        tokens: [],
        currentFilter: "",
        tokenTab: 0,
        attachedTokens: [],
        currentToken: "Select a Token",
        newToken: {tokenRemoteAddresses: ["0.0.0.0"]},
        loading: false,
        alert: { open: false, message: "", type: "" },
        tokenError: false,
        tokenErrorMessage: "",
        listTokens: [],
        showToken: false,
        newTokenValue: "",
        originalSlug: null,
        slugError: false
    };

    setOpen = (visible) => { this.setState({ open: visible }); }

    handleClickOpen = () => { this.setOpen(true); };

    handleClose = () => {
        this.setOpen(false);
        this.props.close();
    };

    handleCloseNewToken = () => { this.setState({showToken: false}) }

    handleChange = (field, e) => {

        let content = this.state.content;

        if (field === "slug") {
            let newSlug = e.target.value.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '');
            content[field] = newSlug;
            this.confirmAvailability(newSlug)

        } else {
            content[field] = e.target.value;
        }

        this.setState({
            content: {...content}
        });
    };

    handleNewTokenChange = (field, e) => {
        let newToken = this.state.newToken;
        if (field === "tokenRemoteAddresses"){
            newToken[field] = e;
        } else {
            newToken[field] = e.target.value;
        }
        this.setState({ newToken: {...newToken} });
    };

    confirmAvailability(slug){

        let self = this;
        req_get_slug_lookup(slug).then(function (result) {
            if (result.status === 200) {
                if ((self.state.originalSlug !== slug) && result.data.inUse > 0){
                    console.log("ERROR");
                    self.setState({slugError: true})
                } else {
                    self.setState({slugError: false})
                }

                self.setState({loading: false});
            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }


    loadFilters = () => {
        let self = this;
        this.setState({ loading: true });

        let filters = JSON.parse(localStorage.getItem("ds_filters"));
        let rowrecs = [];
        filters.data.data.forEach(function (record) {
            if (record.datasetId) {
                rowrecs.push(record);
            }
        });
        if (self.props.itemDetail && self.props.itemDetail.filter) {
            rowrecs.forEach(filter => {
                if (filter.id === self.props.itemDetail.filter) {
                    self.setState({currentFilter: filter.id})
                    self.setState({"currentFilterRec": filter})
                }
            })
        }
        self.setState({filters: [...rowrecs], loading: false});

    }

    loadTokens = () => {
        let self = this;
        this.setState({ loading: true });
        req_get_token_list("token").then(function (result) {
            if (result.status === 200) {
                let rowrecs = [];
                let tokenlist = [];
                result.data.data.forEach(function (record) {
                    rowrecs.push(record);
                    if (self.state.attachedTokens.includes(record.id)) {
                        tokenlist.push(record)
                    }
                });
                self.setState({listTokens: [...tokenlist]});
                self.setState({tokens: [...rowrecs], loading: false});

            } else {
                self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    createToken = () => {
        const fields = ["tokenName", "tokenRemoteAddresses", "tokenExpirationDate", "tokenVerbs"];
        let missingRequired = false;
        let newTokenCopy = this.state.newToken;
        fields.forEach((field) => {
            if (!this.state.newToken[field]){
                newTokenCopy[field + "Error"] = true;
                missingRequired = true;
            } else {
                newTokenCopy[field + "Error"] = null;
            }
        })

        if (missingRequired) {
            this.setState({newToken: {...newTokenCopy}})
        } else {
            let body = {
                name: this.state.newToken.tokenName,
                allowed: this.state.newToken.tokenRemoteAddresses,
                verbs: this.state.newToken.tokenVerbs,
                expiration: this.state.newToken.tokenExpirationDate,
            }

            let self = this;
            req_token_post("token", body).then(function (result) {
                if (result.status === 200 && (!result.data.status || result.data.status !== "fail")) {

                    self.setState({showToken: true, newTokenValue: result.data.token});

                    let t = {"tokenRemoteAddresses": ["0.0.0.0"]};
                    self.setState({newToken: {...t}})
                    self.loadTokens();

                    let attached = self.state.attachedTokens;
                    attached.push(result.data.id);
                    self.setState({"currentToken": "Select a Token", attachedTokens: [...attached]})
                    self.setState({loading: false, tokenTab: 0});
                    let tokenlist = [];

                    self.state.tokens.forEach(function (tokens) {
                        if (self.state.attachedTokens.includes(tokens.id)) {
                            tokenlist.push(tokens)
                        }
                    });
                    self.setState({listTokens: [...tokenlist], loading: false});

                } else if (result && result.data && result.data.msg) {
                    self.setState({
                        loading: false,
                        alert: { ...{ "open": true, "message": result.data.msg, "type": "error", "timeout": 5000 }}
                    });
                } else {
                    self.setState({loading: false, alert:{...{ "open": true, "message": "There was a problem saving the Token.", "type": "error", "timeout": 5000 }}});
                }
            })
        }
    }

    saveApi = (e) => {
        e.preventDefault();

        if (this.state.attachedTokens.length === 0) {
            this.setState({tokenError: true, tokenErrorMessage: "At least one token is required."})
        } else {
            this.setState({tokenError: false, tokenErrorMessage: ""})
            let body = {
                "filterId": this.state.currentFilter,
                "description": this.state.content["description"].trim(),
                "name": this.state.content["name"].trim(),
                "slug": this.state.content["slug"].trim(),
                "tokens": []
            }

            let self = this;
            this.setState({loading: true});
            if (!self.state.slugError) {
                req_api_post("api", body).then(function (result) {
                    if (result.status === 200) {
                        let attachBody = {"tokens": self.state.attachedTokens}
                        let apiId = result.data.id;
                        if (self.state.attachedTokens.length > 0) {
                            req_api_attach_token("api", attachBody, result.data.id).then(function (result) {
                                if (result.status === 200) {
                                    if (openOnCreate("apiwebhook")) {
                                        self.props.openItem("apiwebhook", {name: self.state.content.name, id: apiId})
                                    } else {
                                        eventBus.dispatch("updateAssetMenu", {  });
                                    }
                                }
                            });

                        } else {
                            if (openOnCreate("apiwebhook")) {
                                self.props.openItem("apiwebhook", {name: self.state.content.name, id: result.data.id})
                            } else {
                                eventBus.dispatch("updateAssetMenu", {  });
                            }
                        }
                        self.setState({loading: false});
                        self.handleClose()

                    } else {
                        self.setState({
                            loading: false,
                            alert: {
                                ...{
                                    "open": true,
                                    "message": "There was a problem saving the API.",
                                    "type": "error",
                                    "timeout": 5000
                                }
                            }
                        });
                    }
                })
            } else {
                self.setState({
                    loading: false,
                    alert: {
                        ...{
                            "open": true,
                            "message": "The endpoint is invalid and this API cannot be saved.",
                            "type": "error",
                            "timeout": 5000
                        }
                    }
                });
            }
        }
    }

    selectFilter = (e, newValue) => {
        this.setState({showQueryEditor: false});
        let set = false;
        let content = this.state.content;
        content.currentFilter = newValue.id;
        this.state.filters.forEach(filter => {
            if (filter.id === newValue.id && !set) {
                this.setState({content: content, "currentFilter": newValue.id, "currentFilterRec": filter})
                set = true;
            }
        })
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    deleteSelectedToken = (tokenId) => {
        let currentTokens = this.state.attachedTokens;
        currentTokens.splice(currentTokens.indexOf(tokenId), 1);
        this.setState({attachedTokens: currentTokens});
    }

    getTokenDisplay = (selToken) => {
        let tokenDetail = {};
        this.state.tokens.every(token => {
            if (selToken === token.id) {
                tokenDetail = token;
                return false;
            }
            return true;
        })
        if (tokenDetail.id){
            return <div>{tokenDetail.name + " (" + tokenDetail.userName + ") - [" + tokenDetail.verbs.toString() + "] - Expires: " + tokenDetail.expiration}
                <IconButton onClick={() => {this.deleteSelectedToken(tokenDetail.id)}}><DeleteIcon
                    sx={{fontSize: "18px", color: '#eee'}}/></IconButton>
            </div>;
        } else {
            return "";
        }
    }

    componentDidMount() {
        if (this.props.expAdd){
            this.handleClickOpen();
        }
        this.loadFilters();
        this.loadTokens();
    }

    Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    changeTab = (event, newValue) => {
        this.setState({tokenTab: newValue });
    }

    purgeAlert = () => { this.setState({alert: {...{ open: false, message: "", type: "" }}}) }

    render() {
        return(<div style={{"display":"inline-block"}}>
            <SnackbarInvoke open={this.state.alert.open} alertObj={this.state.alert} purgeAlert={this.purgeAlert}/>
            {!this.props.expAdd ?
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="add or edit record"
                    onClick={this.handleClickOpen}
                >
                    {(this.props.type === "edit") ?
                        <EditIcon sx={{fontSize: "20px", color: '#666'}}/> : <AddBoxIcon/>
                    }
                </IconButton> : null
            }

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={this.Transition}
            >
                <BackdropAppear loading={this.state.loading || false} />
                <DialogAppBar title="API Creation" close={this.handleClose} />
                <Box sx={{padding: 2}}  component="form"  onSubmit={this.saveApi}>
                    <ApiFieldPanel fieldChange={this.handleChange} currentState={this.state} selectFilter={this.selectFilter}/>
                    <Card  variant="outlined" style={{padding: "10px", backgroundColor: "#eeeeee", borderColor: "1px solid #ccc", borderRadius: "3px", marginTop: "10px"}}>
                        <Tabs value={this.state.tokenTab}
                              onChange={this.changeTab}
                              TabIndicatorProps={{
                                  style: { backgroundColor: "#666" }
                              }}
                        >
                            <Tab label="Allow existing token(s)" {...this.a11yProps(0)} className={"apiDialogTabHead"} />
                            {(JSON.parse(localStorage.getItem("selectedAccount")) === JSON.parse(localStorage.getItem("ownedAccount"))) ?
                                <Tab label="Create New token(s)" {...this.a11yProps(1)}
                                     className={"apiDialogTabHead"}/> : null
                            }


                        </Tabs>

                        <TabPanel value={this.state.tokenTab} index={0} style={{marginTop: "10px"}}>
                            <Box >

                                <Autocomplete
                                    id="token_select"
                                    options={this.state.tokens}
                                    multiple
                                    autoHighlight
                                    filterSelectedOptions
                                    value={this.state.listTokens}
                                    onChange={(e, newValues) => {
                                        let tokens = [];
                                        let tokenIds = [];
                                        newValues.forEach((record) =>{ tokenIds.push(record.id); tokens.push(record); })
                                        this.setState({attachedTokens: [...tokenIds]})
                                        this.setState({listTokens: [...tokens]})

                                    }}

                                    getOptionLabel={(option) => option.name + " - [" + option.verbs.toString() + "] - Expires: " + option.expiration}

                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {option.name + " - [" + option.verbs.toString() + "] - Expires: " + option.expiration}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required={this.state.listTokens.length === 0}
                                            label="Token(s) to Grant Access"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            </Box>

                        </TabPanel>
                        <TabPanel value={this.state.tokenTab} index={1}>
                            <CreateTokenPanel token={this.state.newToken} handleNewTokenChange={this.handleNewTokenChange} createToken={this.createToken} />
                        </TabPanel>
                    </Card>

                    <div align={"right"} style={{marginTop: "5px"}}>
                        <Button variant="contained" size="small" type="submit">Save</Button>
                        <Button variant="contained" style={{marginLeft: "5px"}} size="small" onClick={this.handleClose}>Cancel</Button>
                    </div>
                </Box>
            </Dialog>
            <TokenCreatedDialog open={this.state.showToken} tokenValue={this.state.newTokenValue} close={this.handleCloseNewToken} />
        </div>)
    }
}
