
import React from "react";
import MonacoEditor from '@uiw/react-monacoeditor';

export default class BigTextJSON extends React.Component {

    checkForReturn = (e) => {
        if (e.key === "Enter" || e.which === 13) {
            e.stopPropagation();
        }
    }

    onChange = (newValue, e) => {
        this.props.onChange({target: {value: newValue}})
    }

    render () {
        return (<MonacoEditor
            style={{minHeight: "250px", border: "1px solid #ddd"}}
            language="json"
            onKeyDown={this.checkForReturn}
            onChange={this.onChange}
            value={this.props.value || "{}"}
            options={{
                theme: 'vs',
            }}
        />)
    }
}

