import React, {Component} from "react";
import {Autocomplete} from "@mui/lab";
import {TextField} from "@mui/material";


export default class AutocompleteSelectMulti extends Component {

    render() {
        return (
            <Autocomplete
                options={this.props.rows}
                autoHighlight
                filterSelectedOptions
                multiple
                defaultValue={[]}
                value={this.props.selectedRecords}
                onChange={(e, newValue) => {
                    this.props.selectValue(e, newValue);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                    let names = this.props.selectedRecords.map(record => record.name);

                    if(!names.includes(option.name)) {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }

                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={this.props.selectedRecords.length === 0}
                        label={this.props.selectLabel}
                        inputProps={{
                            ...params.inputProps,
                        }}

                    />
                )}
            />
        )
    }
}

