import * as React from 'react';
import Container from '@mui/material/Container';
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function QuickAccessConsole() {
    return (
        <div className="" style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", "minHeight": "300px", width: "95%", maxWidth: "500px", margin: "0 auto"}}>
                    <Container component="main" style={{minWidth: "300px", minHeight: "200px", width: '100%', marginTop: "100px"}}>
                        <Typography variant="h5" gutterBottom>
                            You are already logged in!
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                        Go to the <Link
                            variant="body2"
                            component={Link} to={'/'}
                        >Portal</Link>
                        </Typography>
                    </Container>
        </div>
    );
}