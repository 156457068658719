
// General
export const STD_ALERT_TIMEOUT = 5000;

// Generic Messaging
export const GENERIC_LOAD_ERROR = "There was a problem loading data.";

// Dataset Responses
export const DATASET_SAVE_ERROR = "There was a problem saving the dataset.";
export const DATASET_DELETE_COLUMN_HEAD = "Column Delete Confirmation";
export const DATASET_CLONE_COLUMN_HEAD = "Clone Column";
export const DATASET_ADD_NEW_COLUMN_HEAD = "Create New Column";
export const DATASET_ADD_COLUMN_MISSING = "New column data was not provided."
export const DATASET_ADD_COLUMN_NAME_MISSING = "A name must be provided for the new column."
export const DATASET_ADD_COLUMN_SUCCESS = "The new column was successfully added."
export const DATASET_ADD_COLUMN_FAILURE = "There was a problem adding the new column."
export const DATASET_COLUMN_EXISTS = "This column name already exists."
export const DATASET_DELETE_COLUMN_SUCCESS = "The column was successfully deleted."
export const DATASET_DELETE_COLUMN_FAILURE = "There was a problem deleting the column."
export const DATASET_CLONE_COLUMN_SUCCESS = "The column was successfully cloned."
export const DATASET_CLONE_COLUMN_FAILURE = "There was a problem cloning the column."


// Filter Responses
export const FILTER_SAVE_ERROR = "There was a problem saving the filter.";

// Token Responses
export const TOKEN_SAVE_ERROR = "There was a problem saving the token.";

// Template Responses
export const TEMPLATE_SAVE_ERROR = "There was a problem saving the template.";
export const TEMPLATE_DELETE_HEAD = "Column Delete Confirmation";


// Account Responses
export const ACCOUNT_INVITE_USER_HEAD = "Invite User to Account";
export const REMOVE_INVITE_USER_HEAD = "Remove User from Account";
export const ACCOUNT_SELECT_ACCOUNT_HEAD = "Select Account";